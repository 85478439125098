import React from 'react';
import Button from '../button/button.jsx';
import Modal from '../modal/modal.jsx'; // Asegúrate de importar el componente Modal
import './confirmModal.scss';

const ConfirmModal = ({ isOpen, handleCancel, handleAccept, title, text}) => {
    return (
        <Modal isOpen={isOpen} onClose={handleCancel} overlayClick={false}>
            <div className="modal-alert">
                <div className="alert-header">
                    <h2>{title}</h2> 
                </div>
                <div className="alert-body">
                    <p>{text}</p>
                </div>
                <div className="alert-buttons">
                    <Button onClick={handleCancel} text='Cancelar'></Button>
                    <Button onClick={handleAccept}text='Continuar'></Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
