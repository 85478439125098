import React from 'react';
import './modal.scss';
import CloseSVG from '../icons/close';

const Modal = ({ isOpen, onClose, overlayClick = true, children }) => {
    if (!isOpen) return null; // No renderiza el modal si no está abierto

    const handleOverlayClick = () => {
        if (overlayClick) {
            onClose();
        }
    };

    return (
        <div className="modal-component-overlay" onClick={handleOverlayClick}>
            <div className="modal-content main-color" onClick={(e) => e.stopPropagation()}>
                {/* <button className="modal-close-button" onClick={onClose}>
                    <CloseSVG className='icon'/>
                </button> */}
                {children}
            </div>
        </div>
    );
};

export default Modal;
