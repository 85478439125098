import React, { useState, useEffect } from 'react';
import Button from '../../button/button.jsx';
import Select from '../../select/select.jsx';
import './printFormat.scss';
import adultsDiploma from './Diplomas/adultsDIplomaPDF.jsx';
import childrenDiploma from './Diplomas/childrenDiplomaPDF.jsx';
import EnrollmentProofPDF from './enrollmentProof.jsx';
import CompletionProofPDF from './completionProof.jsx';
import PrintSVG from '../../icons/print.jsx';
import Modal from '../../modal/modal.jsx';
import InputAutoComplete from '../../autocomplete/autocomplete.jsx';
import Axios from 'axios'
const PrintFormat = ({ onClose, usr, listSubsidiary }) => {
    const userInfo = usr
    //states
    const [selectedGroup, setSelectedGroup] = useState({ id: '' });
    const [selectedStudent, setSelectedStudent] = useState({ student_id: '' });
    const [selectedPrincipal, setSelectedPrincipal] = useState({ id: '' });
    const [manyPrints, setManyPrints] = useState('');
    const [selectedDocType, setSelectedDocType] = useState('');
    const [selectedDocument, setSelectedDocument] = useState('');
    const [selectedLevel, setSelectedLevel] = useState({ id: '' });
    const [arrayTeacherClasses, setArrayTeacherClasses] = useState([])
    const [arrayPrincipalList, setArrayPrincipalList] = useState([])
    const [LevelsAdults, setLevelsAdult] = useState([])
    const [LevelsChildren, setLevelsChildren] = useState([])
    const [levelOptions, setLevelOptions] = useState([])
    ////
    const [LevelAdultOG, setLevelAdultOG] = useState([]);
    const [LevelChildrenOG, setLevelChildrenOG] = useState([])
    //Booleant to check if student have two doc type options (Adult,Children)
    const [bothDocTypeOptions, setBothDocTypeOptions] = useState(true)

    const [selectedAutocompleted, setSelectedAutocompleted] = useState(null)

    const [subsidiary, setSubsidiary] = useState(userInfo ? userInfo.fk_subsidiary : '')

    //lISTS
    const [studentListState, setStudentListState] = useState([])
    const [filteredStudentList, setFilteredStudentList] = useState([])
    const [groupListState, setGroupListState] = useState([])
    const [filteredGroupList, setFilteredGroupList] = useState([])
    const [principalList, setPrincipalList] = useState([])
    const subsidiaryList = listSubsidiary

    useEffect(() => {
        const teacherClasses = GetAllTeacherClasses();
        teacherClasses.then((classT) => {
            //if token expire the page is going to reload
            if (classT.teacherClasses !== undefined) {
                setArrayTeacherClasses(classT.teacherClasses)
                let arrayTClasses = classT.teacherClasses
                const Students = arrayTClasses && arrayTClasses.flatMap(group =>
                    group.students.map(student => (
                        {
                            value: student.student_id,
                            label: `${student.student_name} ${student.student_middle_name} ${student.student_last_name}`,
                            subsidiary: {
                                id: group.subsidiary.id,
                                name: group.subsidiary.name,
                            }
                        }
                    ))
                );
                const filteredStudent = Students.filter((e) => e.subsidiary.id === parseInt(subsidiary))
                setFilteredStudentList(filteredStudent)
                ////////////////////////
                setStudentListState(Students)
                const Groups = arrayTClasses && arrayTClasses.map(group => ({
                    value: group.id,
                    label: `${group.teacher.name} ${group.class_day.name} ${group.time.start}-${group.time.end}`,
                    subsidiary: {
                        id: group.subsidiary.id,
                        name: group.subsidiary.name,
                    }
                }));
                const filteredTeacherClass = Groups.filter((e) => e.subsidiary.id === parseInt(subsidiary))
                setFilteredGroupList(filteredTeacherClass)
                setGroupListState(Groups);
            } else {
                window.location.reload();
            }
        })
        const levelClasses = GetAllLevelList()
        levelClasses.then((res) => {
            //if token expire the page is going to reload
            if (res.auth !== false) {
                setLevelOptions(res)
                let adults = res.adults
                const adultsArray = adults.map(element => ({
                    value: element.id,
                    label: element.name
                }));
                setLevelsAdult([...LevelsAdults, ...adultsArray]); // Actualiza el estado con todos los Niveles de adultos
                setLevelAdultOG([...LevelAdultOG, ...adultsArray])
                let kids = res.kids
                const kidsArray = kids.map(element => ({
                    value: element.id,
                    label: element.name
                }));
                setLevelsChildren([...LevelsChildren, ...kidsArray]); // Actualiza el estado con todos los Niveles de niños
                setLevelChildrenOG([...LevelChildrenOG, ...kidsArray])
            }
        })
        const principalList = GetPrincipalList();
        principalList.then((res) => {
            //if token expire the page is going to reload
            if (res.status !== undefined) {
                setArrayPrincipalList(res.result)
                let arrayPrincipal = res.result
                const Principals = arrayPrincipal && arrayPrincipal.map(principal => ({
                    value: principal.id,
                    label: principal.name
                }));
                setPrincipalList(Principals)
            } else {
                // window.location.reload();
            }
        })


    }, [])
    const GetAllTeacherClasses = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetPrincipalList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getPrincipalList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetAllLevelList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "LevelsList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    const GetStudentProgressByStudent = async (id) => {
        const res = await Axios.post(process.env.REACT_APP_API_URL + "studentProgressByStudent/",
            {
                student_id: id
            }
            , {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }
    const GetTeacherClassProgressByClass = async (id) => {
        const res = await Axios.post(process.env.REACT_APP_API_URL + "teacherClassProgressByClass/",
            {
                group_id: id
            }
            , {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }


    //objects to fill selects inputs
    const document = [
        { value: 'ConstanciaEstudios', label: 'Constancia de estudios' },
        { value: 'ConstanciaFin', label: 'Constancia fin de estudios' },
        { value: 'diploma', label: 'Diploma' },
    ];

    //objects to fill selects inputs
    const docType = [
        { value: 'adults', label: 'Adultos' },
        { value: 'children', label: 'Niños' },
    ];

    const prints = [
        { value: 'individual', label: 'Individual' },
        { value: 'grupal', label: 'Grupal' },
    ];








    const processLessons = (lessonsCompleted, LevelAdultOG, LevelChildrenOG) => {
        let ThereIsAdultLesson = 0;
        let ThereIsChildrenLesson = 0;
        let AdultsLesson = [];
        let ChildrenLesson = [];

        lessonsCompleted.forEach(element => {
            if (element.type_id === 1) {
                ThereIsAdultLesson++;
                AdultsLesson.push(element);
            } else if (element.type_id === 2) {
                ThereIsChildrenLesson++;
                ChildrenLesson.push(element);
            }
        });
        if (ThereIsAdultLesson !== 0 && ThereIsChildrenLesson !== 0) {
            setBothDocTypeOptions(true);
            setSelectedDocType('');
        } else {
            setBothDocTypeOptions(false);
            if (ThereIsAdultLesson !== 0) {
                setSelectedDocType('adults');
            }
            if (ThereIsChildrenLesson !== 0) {
                setSelectedDocType('children');
            }
        }

        if (ThereIsAdultLesson !== 0) {
            const uniqueLevels = Array.from(new Set(LevelAdultOG.map(level => level.value)))
                .map(value => LevelAdultOG.find(level => level.value === value));
            const completedLevels = uniqueLevels.filter(level =>
                AdultsLesson.some(lesson => lesson.lesson_id === level.value)
            ).map(level => {
                // Agregar la propiedad completion_date
                const lesson = AdultsLesson.find(lesson => lesson.lesson_id === level.value);
                return {
                    ...level,
                    completion_date: lesson ? lesson.completion_date : null
                };
            });
            setLevelsAdult(completedLevels);
        }

        if (ThereIsChildrenLesson !== 0) {
            const uniqueLevels = Array.from(new Set(LevelChildrenOG.map(level => level.value)))
                .map(value => LevelChildrenOG.find(level => level.value === value));
            const completedLevels = uniqueLevels.filter(level =>
                ChildrenLesson.some(lesson => lesson.lesson_id === level.value)
            ).map(level => {
                // Agregar la propiedad completion_date
                const lesson = ChildrenLesson.find(lesson => lesson.lesson_id === level.value);
                return {
                    ...level,
                    completion_date: lesson ? lesson.completion_date : null
                };
            });
            setLevelsChildren(completedLevels);
        }
    };

    // function to handle when the principal change
    const handlePrincipalChange = (selectedOption) => {
        const principal = arrayPrincipalList.find(principal => principal.id === parseInt(selectedOption));
        setSelectedPrincipal(principal);
    };

    // function to handle when the document change
    const handleDocumentChange = (selectedOption) => {
        let selectedOpt = selectedOption;
        if ('ConstanciaEstudios') {
            setManyPrints('individual')
        }
        setSelectedDocument(selectedOpt);
        setSelectedLevel({ id: '' })
        setSelectedDocType('')
        setSelectedStudent({ student_id: '' })
        setSelectedGroup({ id: '' })
        setSelectedAutocompleted(null)
    };
    // function to handle when the document change
    const handleDocTypeChange = (selectedOption) => {
        let selectedOpt = selectedOption;
        setSelectedDocType(selectedOpt);
        setSelectedLevel({ id: '' })

    };
    const handleLevelChange = (selectedOption) => {
        const levelId = Number(selectedOption); // Convierte selectedOption a número
        let selectedLevel;

        if (selectedDocType === 'adults') {
            selectedLevel = levelOptions.adults.find(level => level.id === levelId);
            // Buscar el completion_date correspondiente en LevelsAdults
            const matchingLevel = LevelsAdults.find(level => level.value === levelId);
            if (matchingLevel) {
                selectedLevel = {
                    ...selectedLevel,
                    completion_date: matchingLevel.completion_date // Agregar completion_date
                };
            }
        } else if (selectedDocType === 'children') {
            selectedLevel = levelOptions.kids.find(level => level.id === levelId);
            // Buscar el completion_date correspondiente en LevelsAdults
            const matchingLevel = LevelsChildren.find(level => level.value === levelId);
            if (matchingLevel) {
                selectedLevel = {
                    ...selectedLevel,
                    completion_date: matchingLevel.completion_date // Agregar completion_date
                };
            }
        }

        if (selectedLevel) {
            setSelectedLevel(selectedLevel);
        } else {
            console.warn(`Level with id ${selectedOption} not found for ${selectedDocType}`);
            setSelectedLevel(null);
        }
    };


    // function to handle when the prints number change
    const handleManyPrintsChange = (selectedOption) => {
        setSelectedGroup({ id: '' });
        setSelectedStudent({ student_id: '' });
        setManyPrints(selectedOption);
        setSelectedLevel({ id: '' })
        setSelectedDocType('')
        setSelectedAutocompleted(null)
    };

    //function to call the correct document and pass the properties
    const generatePDF = async () => {
        let pdfDoc;
        if (selectedDocument === 'diploma') {
            if (selectedDocType === 'adults' && selectedPrincipal) {
                if (selectedStudent.student_id !== '') {
                    pdfDoc = await adultsDiploma(selectedStudent, selectedPrincipal, selectedLevel);
                } else {
                    if (selectedGroup.id !== '') {
                        pdfDoc = await adultsDiploma(selectedGroup, selectedPrincipal, selectedLevel);

                    }
                }
            } else if (selectedDocType === 'children' && selectedPrincipal) {
                if (selectedStudent.student_id !== '') {
                    pdfDoc = await childrenDiploma(selectedStudent, selectedPrincipal, selectedLevel);
                } else {
                    if (selectedGroup.id !== '') {
                        pdfDoc = await childrenDiploma(selectedGroup, selectedPrincipal, selectedLevel);

                    }
                }
            }
        } else {
            if (selectedDocument === 'ConstanciaEstudios' && selectedPrincipal) {
                if (selectedStudent) {
                    pdfDoc = await EnrollmentProofPDF(selectedStudent, selectedPrincipal);
                } else if (selectedGroup) {
                    alert('La constancia de estudios solo se puede seleccionar para un estudiante');
                }
            } else if (selectedDocument === 'ConstanciaFin' && selectedPrincipal) {
                if (selectedGroup.id !== '') {
                    pdfDoc = await CompletionProofPDF(selectedGroup, selectedPrincipal, selectedLevel, selectedDocType);
                } else if (selectedStudent.student_id !== '') {
                    pdfDoc = await CompletionProofPDF(selectedStudent, selectedPrincipal, selectedLevel, selectedDocType);
                }
            }
        }
        if (pdfDoc) {
            pdfDoc.autoPrint();
            window.open(pdfDoc.output('bloburl'), '_blank');
        }
    };


    const getIsDisabled = () => {
        if (manyPrints === 'individual') {
            return selectedStudent.student_id === '' || bothDocTypeOptions === false;
        }
        return selectedGroup.id === '' || bothDocTypeOptions === false;
    };


    const isPrintDisabled = () => {
        // Condiciones para documentos específicos
        if (selectedDocument === '') return true;

        if (selectedDocument === 'ConstanciaEstudios') {
            return selectedPrincipal.id === '' || selectedStudent.student_id === '';
        }
        if (selectedDocument === 'ConstanciaFin') {
            return manyPrints === 'individual' ? selectedPrincipal.id === '' || selectedStudent.student_id === '' : selectedPrincipal.id === '' || selectedGroup.id === '';
        }

        if (selectedDocument === 'diploma') {
            return manyPrints === 'individual'
                ? selectedStudent.student_id === '' || selectedPrincipal.id === '' || selectedDocType === '' || selectedLevel.id === ""
                : selectedGroup.id === '' || selectedPrincipal.id === '' || selectedDocType === '' || selectedLevel.id === "";
        }

        // Condiciones generales para otros documentos
        if (selectedDocType === '') return true;
        if (selectedLevel.id === '') return true;

        // Para casos de impresiones individuales o grupales
        if (manyPrints === 'individual') {
            return selectedStudent.student_id === '';
        } else if (manyPrints === 'grupal') {
            return selectedGroup.id === '';
        }

        // Habilitado si todas las condiciones se cumplen
        return false;
    };
    // function to handle when the student change
    const handleStudentChange = (selectedOption) => {
        if (selectedOption !== null) {
            setSelectedAutocompleted(selectedOption);
            let selectedStudent = null;
            // itarate all the groups to find the student and restructure the object
            for (const group of arrayTeacherClasses) {
                const student = group.students.find(student => student.student_id === parseInt(selectedOption.value));
                if (student) {
                    selectedStudent = {
                        student_id: student.student_id,
                        student_name: student.student_name,
                        student_middle_name: student.student_middle_name,
                        student_last_name: student.student_last_name,
                        student_inscription_date: student.student_inscription_date,
                        date: {
                            start: group.date.start,
                            end: group.date.end
                        },
                        subsidiary: {
                            name: group.subsidiary.name,
                            address: group.subsidiary.address,
                            phone: group.subsidiary.phone_number
                        },
                        schedule: group.full_dayclass,
                        teacher: {
                            name: group.teacher.name,
                            signature: group.teacher.signature
                        },
                        level: {
                            // id: group.level.id,
                            // name: group.level.name,
                            // idUnit: group.level.idUnit,
                            // unitName: group.level.unitName
                            id: group.level.id,
                            name: group.level.name,
                            idUnit: group.level.idUnit,
                            unitName: group.level.unitName,
                            hours: group.level.hours
                        }
                    };
                    const studentProgress = GetStudentProgressByStudent(selectedStudent.student_id)
                    studentProgress.then((v) => {
                        // Filtrar los niveles por los que ya ha pasado el estudiante
                        processLessons(v.lessons, LevelAdultOG, LevelChildrenOG);
                    })
                }
            }
            setSelectedStudent(selectedStudent);
            setSelectedLevel({ id: '' })
        }
    };
    // function to handle when the gruop change
    const handleGroupChange = (selectedOption) => { // Verifica el objeto completo
        if (selectedOption !== null) {
            setSelectedAutocompleted(selectedOption);
            const groupIdValue = selectedOption.value;
            const groupId = parseInt(groupIdValue); // Convierte el valor a número
            const classProgress = GetTeacherClassProgressByClass(groupId)
            classProgress.then((v) => {
                processLessons(v.lessons, LevelAdultOG, LevelChildrenOG);
            })
            const group = arrayTeacherClasses.find(group => group.id === groupId); // Busca el grupo por ID
            setSelectedLevel({ id: '' })
            setSelectedGroup(group); // Actualiza el estado con el grupo encontrado
        }
    };
    const getFullName = (alumno) => {
        return `${alumno.label}`;
    };

    const handleSubsidiaryChange = (event) => {
        setSubsidiary(event.target.value)
        setSelectedStudent({ student_id: '' })
        setSelectedGroup({ id: '' })
        setSelectedAutocompleted(null)
        // Filtrar empleados según la subsidiaria seleccionada
        const filteredStudent = studentListState.filter((e) => e.subsidiary.id === parseInt(event.target.value))
        setFilteredStudentList(filteredStudent)
        const filteredTeacherClass = groupListState.filter((e) => e.subsidiary.id === parseInt(event.target.value))
        setFilteredGroupList(filteredTeacherClass)
    }
    return (
        <>
            <Modal isOpen={true} onClose={onClose}>
                <div className='main-color print-format'>
                    <section className='print-header'>
                        <h1>Imprimir documento</h1>
                    </section>
                    <section className='print-body'>
                        <Select
                            options={subsidiaryList}
                            placeholder="Plantel"
                            className={'main-color'}
                            value={subsidiary}
                            onChange={(e) => handleSubsidiaryChange(e)}
                        />
                        <Select
                            classN='main-color'
                            options={document}
                            placeholder={'Selecciona tipo de documento'}
                            onChange={(e) => handleDocumentChange(e.target.value)}
                            value={selectedDocument}
                        />
                        <Select
                            classN='main-color'
                            options={prints}
                            placeholder={'Selecciona número de impresiones'}
                            onChange={(e) => handleManyPrintsChange(e.target.value)}
                            value={manyPrints}
                            disabled={(selectedDocument === 'ConstanciaEstudios' || selectedDocument === '') && true}
                        />
                        <Select
                            classN='main-color'
                            options={principalList}
                            placeholder={'Selecciona un director'}
                            onChange={(e) => handlePrincipalChange(e.target.value)}
                            value={selectedPrincipal.id}
                            disabled={selectedDocument === '' && true}
                        />
                        <InputAutoComplete
                            options={manyPrints === 'individual' ? filteredStudentList : filteredGroupList}
                            className={'main-color autoCompleteSelect'}
                            onChange={manyPrints === 'individual' ? (event, newValue) => handleStudentChange(newValue) : (event, newValue) => handleGroupChange(newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value} // Comparación basada en el 'id'
                            disabled={selectedDocument === '' && true}
                            getOptionLabel={(option) => getFullName(option)} // Controla cómo se muestra el texto en las opciones
                            value={selectedAutocompleted}
                            placeholder={manyPrints === 'individual' ? "Buscar Alumno..." : 'Buscar Grupo...'}

                        />
                        {
                            selectedDocument !== '' && selectedDocument !== 'ConstanciaEstudios' &&
                            <>
                                <Select
                                    classN='main-color'
                                    options={docType}
                                    placeholder={'selecciona tipo'}
                                    onChange={(e) => handleDocTypeChange(e.target.value)}
                                    value={selectedDocType}
                                    disabled={getIsDisabled()}
                                />
                                <Select
                                    classN='main-color'
                                    options={selectedDocType === 'adults' ? LevelsAdults : LevelsChildren}
                                    placeholder={selectedDocument === 'diploma' ? 'selecciona nivel' : 'Imprimir nivel actual'}
                                    onChange={(e) => handleLevelChange(e.target.value)}
                                    value={selectedLevel.id}
                                    disabled={(selectedDocument === '' || selectedDocType === '') && true}
                                />
                            </>
                        }
                    </section>
                    <section className='print-footer'>
                        <Button onClick={() => generatePDF('print')} text="Imprimir" Icon={PrintSVG} disabled={isPrintDisabled()} />
                        <Button onClick={onClose} text='Cancelar'></Button>
                    </section>
                </div>
            </Modal>
        </>
    );
}

export default PrintFormat;
