import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ConfirmModal from "../../confirmModalV2/confirmModal";
import ChangePasswordModal from "../changePasswordModal/changePasswordModal";
import './modalEditUser.scss'
import InputAnimated from "../../inputAnimated/inputAnimated";
import Axios from "axios";
import Select from "../../select/select";
import Button from "../../button/button";
import { dataDecrypt } from "../../../utils/dataDecrypt";
const ModalEditUser = ({ modalData, setModalData, refreshTable, setRefreshTable }) => {
    const [open, setOpen] = useState(false);
    // Definir las variables de estado para cada campo
    const [id, setId] = useState(null);
    const [idPerson, setIdPerson] = useState(null);

    const [name, setName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [genre, setGenre] = useState(''); // Cambiar a cadena vacía
    const [email, setEmail] = useState(''); // Este está deshabilitado, pero igual definimos el estado
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberHome, setPhoneNumberHome] = useState('');
    const [phoneNumberOffice, setPhoneNumberOffice] = useState('');
    const [jobPosition, setJobPosition] = useState('')
    const [subsidiary, setSubsidiary] = useState('')
    const [privilege, setPrivilege] = useState(''); // Cambiar a cadena vacía
    const [status, setStatus] = useState(''); // Cambiar a cadena vacía

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [message, setMessage] = useState('');

    const [modalDataPassword, setModalDataPassword] = useState(null);
    const [modalDataDelete, setModalDataDelete] = useState(null);

    const [isMyAccount, setIsMyAccount] = useState(false);
    const [isUserDeleted, setIsUserDeleted] = useState(false)

    const [errors, setErrors] = useState({
        name: false,
        middleName: false,
        lastName: false,
        genre: false,
        email: false,
        phoneNumber: false,
        phoneNumberHome: false, // Solo valida si tiene valor
        phoneNumberOffice: false, // Solo valida si tiene valor
        jobPosition: false,
        subsidiary: false,
        privilege: false,
        status: false
    });
    const privilegeList = [
        {
            value: 1,
            label: "Alumno"
        },
        {
            value: 2,
            label: "Profesor"
        },
        {
            value: 3,
            label: "Administración"
        },
        {
            value: 4,
            label: "Dirección"
        },
        {
            value: 5,
            label: "SuperAdmin"
        },
        {
            value: 6,
            label: "Recepción"
        },
    ]
    const jobPositionList = [
        {
            value: 1,
            label: "Desarrollo de Software"
        },
        {
            value: 2,
            label: "Desarrollo de Multimedia"
        },
        {
            value: 3,
            label: "Director"
        },
        {
            value: 4,
            label: "Recepcionista"
        },
        {
            value: 5,
            label: "Recursos Humanos"
        },
        {
            value: 6,
            label: "Auxiliar RH"
        },
        {
            value: 7,
            label: "Soporte Tecnico"
        },
        {
            value: 8,
            label: "Profesor"
        },
        {
            value: 9,
            label: "Marketing"
        },
    ]
    const subsidiaryList = [
        {
            value: 1,
            label: "Plaza Estrella"
        },
        {
            value: 2,
            label: "Plaza Bugambilias"
        },
        {
            value: 3,
            label: "Plaza Aranjuez"
        },
    ]
    const genreList = [
        {
            value: 1,
            label: "Hombre"
        },
        {
            value: 2,
            label: "Mujer"
        },
    ]
    const statusList = [
        {
            value: 1,
            label: "Activo"
        },
        {
            value: 2,
            label: "Inactivo"
        },
    ]
    useEffect(() => {
        if (modalData !== null) {
            checkUserDeletable(modalData.id)
            setId(modalData.id);
            setIdPerson(modalData.fk_person);
            setName(modalData.name);
            setMiddleName(modalData.middle_name);
            setLastName(modalData.last_name);
            setGenre(modalData.k_genre || ''); // Cambiar a cadena vacía si null
            setEmail(modalData.email);
            setPhoneNumber(modalData.phone_number);
            setPhoneNumberHome(modalData.phone_number_home || '');
            setPhoneNumberOffice(modalData.phone_number_office || '');
            setJobPosition(modalData.fk_job_position || '')
            setSubsidiary(modalData.fk_subsidiary || '')
            setPrivilege(modalData.k_privilege || ''); // Cambiar a cadena vacía si null
            setStatus(modalData.k_status || ''); // Cambiar a cadena vacía si null
            setOpen(true);
        }
    }, [modalData]);
    useEffect(() => {
        if (isUserDeleted === true) {
            handleCloseEdit()
            setIsUserDeleted(false)
            setRefreshTable(!refreshTable)
        }
    }, [isUserDeleted])
    const handleCloseEdit = () => {
        setModalData(null);
        setOpen(false);
        setButtonDisabled(false);
        setMessage('')
        setErrors({
            name: false,
            middleName: false,
            lastName: false,
            genre: false,
            email: false,
            phoneNumber: false,
            phoneNumberHome: false, // Solo valida si tiene valor
            phoneNumberOffice: false, // Solo valida si tiene valor
            jobPosition: false,
            subsidiary: false,
            privilege: false,
            status: false
        })
    }
    ///edit API
    const handleEditUser = () => {
        // Verificar campos vacíos
        const newErrors = {
            name: !name,
            middleName: !middleName,
            lastName: !lastName,
            genre: !genre,
            email: !email,
            phoneNumber: !phoneNumber || phoneNumber.length !== 10,
            phoneNumberHome: phoneNumberHome && phoneNumberHome.length !== 10, // Solo valida si tiene valor
            phoneNumberOffice: phoneNumberOffice && phoneNumberOffice.length !== 10, // Solo valida si tiene valor
            jobPosition: !jobPosition,
            subsidiary: !subsidiary,
            privilege: !privilege,
            status: !status
        };
        setErrors(newErrors);

        // Verificar campos vacíos o con errores
        if (Object.values(newErrors).some((error) => error)) {
            if (!phoneNumber || phoneNumber.length !== 10) {
                setMessage("El número de teléfono debe tener exactamente 10 dígitos.");
            } else if (phoneNumberHome && phoneNumberHome.length !== 10) {
                setMessage("El número de teléfono de casa debe tener exactamente 10 dígitos.");
            } else if (phoneNumberOffice && phoneNumberOffice.length !== 10) {
                setMessage("El número de teléfono de la oficina debe tener exactamente 10 dígitos.");
            } else {
                setMessage("Todos los campos obligatorios deben ser completados.");
            }

            setTimeout(() => {
                setMessage('');
            }, 2500);
            return;
        }
        Axios.patch(
            process.env.REACT_APP_API_URL + "updateUser/" + id + '/' + idPerson,
            {
                name: name,
                middle_name: middleName,
                last_name: lastName,
                email: email,
                phone_number: phoneNumber,
                phone_number_home: phoneNumberHome,
                phone_number_office: phoneNumberOffice,
                fk_genre: genre,
                fk_status: status,
                fk_job_position: jobPosition,
                fk_subsidiary: subsidiary,
                fk_privilege: privilege,
            },
            { headers: { "x-access-token": localStorage.getItem("token") } }
        ).then((res) => {
            const result = res.data
            switch (result.status) {
                case 200:
                    setMessage(result.message)
                    setButtonDisabled(true)
                    setTimeout(() => {
                        setRefreshTable(!refreshTable);
                    }, 1000);
                    setTimeout(() => {
                        handleCloseEdit()
                    }, 2500)
                    break;
                case 201:
                    setButtonDisabled(true)
                    setMessage(result.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 2500);
                    setButtonDisabled(false)
                case 202:
                    setButtonDisabled(true)
                    setMessage(result.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 2500);
                    setButtonDisabled(false)
                    break;
                default:
                    break;
            }
        });


    };
    const handleKeyDown = (event) => {
        if (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e') {
            event.preventDefault();
        }
    };
    const handleChangePassword = () => {
        const obj = {
            id,
            email
        }
        setModalDataPassword(obj);
    }
    const handleDeleteUser = () => {
        const obj = {
            id,
            idPerson,
            email,
            action: "deleteUser",
        }
        setModalDataDelete(obj)
    }

    const checkUserDeletable = (idUser) => {
        const userStorageArray = dataDecrypt(localStorage.getItem("user"));
        if (idUser === userStorageArray.id) {
            setIsMyAccount(true)
        } else {
            setIsMyAccount(false)
        }
    }
    return (
        <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box className={'containerEditModal'}>
                <div className="editContainer">
                    <h1>PERFIL</h1>
                    <div className="formContainer">
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={errors.name ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Apellido Paterno"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                            className={errors.middleName ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Apellido Materno"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className={errors.lastName ? 'error' : ''}
                        />
                        <Select
                            value={genre}
                            onChange={(e) => setGenre(e.target.value)}
                            className={errors.genre ? 'error' : ''}
                            placeholder={"Selecciona un genero"}
                            options={genreList}
                        />
                        <InputAnimated
                            type="text"
                            placeholder="Email"
                            disabled
                            value={email}
                            className={errors.email ? 'error' : ''}
                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumber ? 'error' : ''}
                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono Casa"
                            value={phoneNumberHome}
                            onChange={(e) => setPhoneNumberHome(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumberHome ? 'error' : ''}

                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono Oficina"
                            value={phoneNumberOffice}
                            onChange={(e) => setPhoneNumberOffice(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumberOffice ? 'error' : ''}
                        />
                        <Select
                            value={jobPosition}
                            onChange={(e) => setJobPosition(e.target.value)}
                            className={errors.jobPosition ? 'error' : ''}
                            placeholder={"Selecciona una puesto de trabajo"}
                            options={jobPositionList}
                        />
                        <Select
                            value={subsidiary}
                            onChange={(e) => setSubsidiary(e.target.value)}
                            className={errors.subsidiary ? 'error' : ''}
                            placeholder={"Selecciona un plantel"}
                            options={subsidiaryList}
                        />
                        <Select
                            value={privilege}
                            onChange={(e) => setPrivilege(e.target.value)}
                            className={errors.privilege ? 'error' : ''}
                            placeholder={"Selecciona un privilegio"}
                            options={privilegeList}
                        />
                        <Select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className={errors.status ? 'error' : ''}
                            placeholder={"Selecciona un privilegio"}
                            options={statusList}
                        />
                    </div>
                    <div className="changePasswordContainerSA">
                        <button onClick={handleChangePassword}>Cambiar contraseña</button>
                    </div>
                    <div className="bottomForm">

                        <Button disabled={buttonDisabled} onClick={handleCloseEdit} text={"Cancelar"} />
                        <Button disabled={buttonDisabled} onClick={handleEditUser} text={"Guardar Cambios"} />
                        <Button disabled={buttonDisabled || isMyAccount} onClick={handleDeleteUser} text={"Eliminar"} />
                    </div>
                    <div>
                        <span>{message}</span>
                    </div>
                </div>
                {/* CHILD MODAL */}
                <ChangePasswordModal
                    modalData={modalDataPassword}
                    setModalData={setModalDataPassword}
                />
                <ConfirmModal
                    modalData={modalDataDelete}
                    setModalData={setModalDataDelete}
                    setIsUserDeleted={setIsUserDeleted}
                />
            </Box>
        </Modal>
    );
}

export default ModalEditUser;
