import "./select.scss"
import React, { useState } from 'react';

const Select = ({value, options, placeholder, onChange, className, disabled }) => {
    

    return (
        <div className="custom-select">
            <select value={value} onChange={onChange}  className={className} disabled={disabled}>
                <option value='' disabled hidden>{placeholder}</option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}> 
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;
