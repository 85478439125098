import DropDownList from '../../Dropdown/DropDownList';
import GroupSVG from '../../icons/group';
import UserSVG from '../../icons/user';
import UserAddSVG from '../../icons/userAdd';
import UserInfoSVG from '../../icons/userInfo';
import DocSVG from '../../icons/doc';
import PaySVG from '../../icons/pay';
import GroupsTabSVG from '../../icons/groupstab';
import ClasroomSVG from '../../icons/classroom';

const RecepMenu = ({ onMenuItemClick }) => {  // Recibe la función como prop

    const MenuData = [
        {
            name: "Alumnos",
            icon: UserSVG,
            link: "#",
            status: "active",
            children: [
                {
                    name: "Registrar alumno",
                    icon: UserAddSVG,
                    link: "#",
                    status: "active",
                },
                {
                    name: "Info alumno",
                    icon: UserInfoSVG,
                    link: "#",
                    status: "active",
                },
            ],
        },
        {
            name: "Grupos",
            icon: GroupSVG,
            link: "#",
            status: "active",
            children: [
                {
                    name: "Tabla de grupos",
                    icon: GroupsTabSVG,
                    link: "#",
                    status: "disabled",
                },
                {
                    name: "Listado de grupos",
                    icon: GroupSVG,
                    link: "#",
                    status: "active",
                },
            ],
        },
        {
            name: "Formatos",
            icon: DocSVG,
            link: "#",
            status: "active",
            children: [
                {
                    name: "Diplomas y Constancias",
                    icon: DocSVG,
                    link: "#",
                    status: "active",
                },
            ],
        },
    ];

    return (
        <DropDownList
            Data={MenuData}
            iconColor='red'
            onMenuItemClick={onMenuItemClick} // Pasa la función a DropDownList
        />
    );
};

export default RecepMenu;
