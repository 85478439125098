import React, { useState } from "react";
import InputAnimated from "../../inputAnimated/inputAnimated";
import './registerPassword.scss'
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const RegisterPassword = ({ verifyData }) => {
    const [passwordState, setPasswordState] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleSubmitPassword = (event) => {
        event.preventDefault()
        // Resetear errores
        setMessage('');

        // Validaciones
        let valid = true;
        let newMessage = '';

        if (!passwordState) {
            newMessage = 'La contraseña no puede estar vacío';
            valid = false;
        } else if (passwordState.length < 5) {
            newMessage = 'Su contraseña es muy corta';
            valid = false;
        }

        setMessage(newMessage);

        if (valid) {
            // Realiza la llamada a la API si las validaciones pasan
            Axios.post(process.env.REACT_APP_API_URL + "registerWithPassword/", {
                email: verifyData.result.email,
                phone: verifyData.result.phone_number,
                password: passwordState
            }).then((res) => {
                const result = res.data
                switch (result.status) {
                    case 200:
                        setMessage(result.message)
                        setButtonDisabled(true)
                        setTimeout(() => {
                            navigate('/login')
                        }, 2500);
                        break;
                    case 201:
                        setMessage(result.message)
                        break;
                    default:
                        break;
                }
            });
        }
    }
    return (
        <div className="registerPasswordContainer">
            <form className="passwordForm" onSubmit={handleSubmitPassword}>
                <div className="titleForm">
                    <h1>ingresar una contraseña, ya tienes un perfil en nuestro sistema</h1>
                </div>
                <InputAnimated
                    type="email"
                    placeholder="Email"
                    disabled={true}
                    value={verifyData.result.email}
                    maxLength={60}
                />
                <InputAnimated
                    type="number"
                    placeholder="Celular"
                    disabled={true}
                    value={verifyData.result.phone_number}
                />
                <InputAnimated
                    type="password"
                    placeholder="Password"
                    value={passwordState}
                    onChange={(e) => setPasswordState(e.target.value)}
                    maxLength={60}
                />
                {message && <p className="message">{message}</p>}

                <div className="buttonForm">
                    <button className={!buttonDisabled ? 'button' : 'button disabledButton'} type="submit" disabled={buttonDisabled}>Aceptar</button>
                </div>
                <div className="bottomForm">
                    <span>
                        ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
                    </span>
                </div>
            </form>
        </div>
    )
}
export default RegisterPassword