import React, { useState } from "react"
import InputAnimated from "../../inputAnimated/inputAnimated"
import Axios from "axios";
import Alert from "../../alert/Alert";

const ModalAddEvent = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [disableAll, setDisableAll] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([])
    const [checkPart, setCheckPart] = useState(0)


    var tStart = new Date(props.scheduler.state.start.value)
    tStart.setHours(tStart.getHours() - 7)
    var tEnd = new Date(props.scheduler.state.end.value)
    tEnd.setHours(tEnd.getHours() - 7)
    const [state, setState] = useState({
        start: tStart.toISOString().replace(/(?:\.\d{1,3})?Z$/, '') || "",
        end: tEnd.toISOString().replace(/(?:\.\d{1,3})?Z$/, '') || "",
        id_teacher: props.scheduler.state.id_teacher.value || "",
        book_id: props.scheduler.state.book_id.value || 0,
        unit_id: props.scheduler.state.unit_id.value || 0,
        type_id: props.scheduler.state.type_id.value || 0,
        qtyStudents: props.scheduler.state.qtyStudents.value || 0,
        description: props.scheduler.state.description.value || ""
    });
    const [requiredState, setRequiredState] = useState({
        teacherRequired: "",
        bookRequired: "",
        unitRequired: "",
        typeRequired: "",
        attendanceRequired: "",
        commentsRequired: ""
    })

    const handleChange = (value, name) => {
        setState((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });

    };
    const handleChangeDateTime = (e) => {
        var dateS = new Date(e.target.value)
        dateS.setMinutes(0)
        dateS.setHours(dateS.getHours())
        dateS = new Date(dateS.getTime() - (dateS.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
        handleChange(dateS, "start")
        const date = new Date(e.target.value)
        const nameDay = props.getDayName(date)
        if (nameDay !== "Saturday" && nameDay !== "Sunday") {
            var tHour = date.getHours()
            tHour = tHour + 1
            var endDateTime = date
            endDateTime.setHours(tHour)
            endDateTime.setMinutes(0)
            endDateTime = new Date(endDateTime.getTime() - (endDateTime.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
            handleChange(endDateTime, "end")
        } else {
            if (nameDay === "Saturday") {
                var tHour = date.getHours()
                if (tHour >= 0 && tHour <= 11) {
                    var dateStart = date
                    dateStart.setHours(8)
                    dateStart.setMinutes(0)
                    dateStart = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    handleChange(dateStart, "start")
                    var dateEnd = date
                    dateEnd.setHours(13)
                    dateEnd.setMinutes(0)
                    dateEnd = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    handleChange(dateEnd, "end")
                } else {
                    var dateStart = date
                    dateStart.setHours(13)
                    dateStart.setMinutes(30)
                    dateStart = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    handleChange(dateStart, "start")
                    var dateEnd = date
                    dateEnd.setHours(17)
                    dateEnd.setMinutes(30)
                    dateEnd = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    handleChange(dateEnd, "end")
                }


            } else {
                var dateStart = date
                dateStart.setHours(9)
                dateStart.setMinutes(0)
                dateStart = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                handleChange(dateStart, "start")
                var dateEnd = date
                dateEnd.setHours(13)
                dateEnd.setMinutes(0)
                dateEnd = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                handleChange(dateEnd, "end")

            }
        }
    };
    const handleRequiredInputs = (value, name) => {
        setRequiredState((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });

    };
    var arrayEmptys = []
    const checkEmpty = () => {
        arrayEmptys.length = 0
        if (state.id_teacher === 0) {
            arrayEmptys.push(1)
            handleRequiredInputs("required", "teacherRequired")
        } else {
            handleRequiredInputs("", "teacherRequired")
        }
        if (state.book_id === 0) {
            arrayEmptys.push(1)
            handleRequiredInputs("required", "bookRequired")
        } else {
            handleRequiredInputs("", "bookRequired")

        }
        if (state.unit_id === 0) {
            arrayEmptys.push(1)
            handleRequiredInputs("required", "unitRequired")
        } else {
            handleRequiredInputs("", "unitRequired")

        }
        if (state.type_id === 0) {
            arrayEmptys.push(1)
            handleRequiredInputs("required", "typeRequired")
        } else {
            handleRequiredInputs("", "typeRequired")

        }
        if (state.qtyStudents === 0 || state.qtyStudents === "") {
            arrayEmptys.push(1)
            handleRequiredInputs("required", "attendanceRequired")

        } else {
            handleRequiredInputs("", "attendanceRequired")
        }


    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            checkEmpty()
            var thereIsAlert = 0
            if (arrayEmptys.length === 0) {

                /**POST event to remote DB */
                const createEvent = PostCreateEvent(state.id_teacher, state.start, state.end, state.type_id, state.book_id, state.unit_id, state.qtyStudents, state.description, checkPart)
                createEvent.then((response) => {
                    console.log(response)
                    if (response.auth === false) {
                        setIsVisible(true)
                        setDisableAll(true)
                        props.setRefresh(!props.refresh)
                        if (arrayAlerts.length <= 4) {
                            const objAlert = {
                                id: arrayAlerts.length + 1,
                                type: "danger",
                                message: response.message
                            }
                            if (arrayAlerts.length > 0) {
                                arrayAlerts.forEach((v) => {
                                    if (v.message === objAlert.message) {
                                        thereIsAlert++
                                    }
                                })
                                if (thereIsAlert === 0) {
                                    setArrayAlerts([...arrayAlerts, objAlert])
                                }
                            } else {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        }
                        setTimeout(() => {
                            window.location.reload()
                        }, [2500])
                    } else {
                        switch (response.status) {
                            case 200:
                                setIsVisible(true)
                                setDisableAll(true)

                                props.setRefresh(!props.refresh)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "success",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                setTimeout(() => {
                                    props.scheduler.close()
                                }, [2500])
                                break;
                            case 201:
                                setIsVisible(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                break;
                            case 202:
                                setIsVisible(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                break;
                            case 203:
                                setIsVisible(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                break;
                            case 204:
                                setIsVisible(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                break;
                            case 205:
                                setIsVisible(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: response.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    }
                })
            } else {
                if (arrayAlerts.length <= 4) {
                    const objAlert = {
                        id: arrayAlerts.length + 1,
                        type: "danger",
                        message: "Verifique que no haya campos vacios"
                    }
                    if (arrayAlerts.length > 0) {
                        arrayAlerts.forEach((v) => {
                            if (v.message === objAlert.message) {
                                thereIsAlert++
                            }
                        })
                        if (thereIsAlert === 0) {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    } else {
                        setArrayAlerts([...arrayAlerts, objAlert])
                    }
                }
                setIsVisible(true)
            }
        } finally {
            //   scheduler.loading(false);
        }
    };
    ///Aqui iba funcion para verificar solo numeros en el input 
    
    ////////////
    const PostCreateEvent = async (id_teacher, start, end, type_id, lesson_id, unit_id, students_assist, description, unit_part) => {
        ////Execute API to insert eventTeacher
        const res = await Axios.post(process.env.REACT_APP_API_URL + "postCreateEventTeacher/", {
            id_teacher: id_teacher,
            start: start,
            end: end,
            type_id: type_id,
            lesson_id: lesson_id,
            unit_id: unit_id,
            students_assist: students_assist,
            description: description,
            unit_part: unit_part,
        },
            {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }
    const handleCheckedPart = (part) => {
        switch (part) {
            case 1:
                if (checkPart !== 1) {
                    setCheckPart(1)

                } else {
                    setCheckPart(0)
                }
                break;
            case 2:
                if (checkPart !== 2) {
                    setCheckPart(2)
                } else {
                    setCheckPart(0)

                }

                break;
            default:
                break;
        }
    }
    return (
        <>
            <div className='modalForm addEventForm' style={{ width: "95%", transform: " translate(0%, 0%)", left: "0%" }}>
                <form className="formClass">
                    <div className="formHeader">
                        <div className="formTitle">
                            <h1>Agregar Clase del día</h1>
                        </div>
                    </div>
                    <div className="formBody">
                        <div className="inputTimeContainer">
                            <div className="timeStart">
                                <input value={state.start} type="datetime-local" onChange={(e) => handleChangeDateTime(e)} required placeholder=" "></input>
                                <label>Inicio</label>
                            </div>
                            <div className="timeEnd">
                                <input disabled value={state.end} onChange={(e) => handleChange(e.target.value, "end")} type="datetime-local" required placeholder=" "></input>
                                <label>Fin</label>
                            </div>
                        </div>
                        <select className={requiredState.teacherRequired} value={state.id_teacher} onChange={(e) => handleChange(e.target.value, "id_teacher")} >
                            <option key={0} value={0} disabled>Selecciona un maestro</option>
                            {
                                props.listInputData.teacherList.map((t, i) => {
                                    return (
                                        <option key={i + 1} value={t.id_teacher} >{`${t.name} (${t.phoneNumber})`}</option>

                                    )
                                })
                            }
                        </select>
                        <select className={requiredState.bookRequired} value={state.book_id} onChange={(e) => handleChange(e.target.value, "book_id")} >
                            <option key={0} value={0} disabled>Selecciona un libro</option>
                            {
                                props.listInputData.bookList.map((b, i) => {
                                    return (
                                        <option key={i + 1} value={b.book_id} >{b.name}</option>
                                    )
                                })
                            }
                        </select>
                        <div className="unitEditContainer">

                            <select className={requiredState.unitRequired} value={state.unit_id} onChange={(e) => handleChange(e.target.value, "unit_id")}>
                                <option key={0} value={0} disabled>Selecciona una unidad</option>
                                {
                                    props.listInputData.unitList.map((u, i) => {
                                        return (
                                            <option key={i + 1} value={u.unit_id} >{u.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <div className="checkPartContainer">
                                <div>
                                    <label>A </label>
                                    <input type="checkbox" checked={checkPart === 1} onChange={() => handleCheckedPart(1)} />

                                </div>
                                <div>
                                    <label>B </label>
                                    <input type="checkbox" checked={checkPart === 2} onChange={() => handleCheckedPart(2)} />
                                </div>

                            </div>
                        </div>
                        <select className={requiredState.typeRequired} value={state.type_id} onChange={(e) => handleChange(e.target.value, "type_id")}>
                            <option key={0} value={0} disabled>Selecciona un tipo de clase</option>
                            {
                                props.listInputData.typeList.map((tp, i) => {
                                    return (
                                        <option key={i + 1} value={tp.type_id} >{tp.name}</option>
                                    )
                                })
                            }
                        </select>
                        <InputAnimated className={requiredState.attendanceRequired} onChange={(e) => handleChange(e.target.value, "qtyStudents")} type="number" placeholder="Asistencia" />
                        <InputAnimated className={requiredState.commentsRequired} value={state.description} onChange={(e) => handleChange(e.target.value, "description")} type="text" placeholder="Observaciones" />
                    </div>
                    {/* <!-- Buttons --> */}
                    <div className="formBottom">
                        <div></div>
                        <div className="buttonsContainer">
                            <button disabled={disableAll} className={!disableAll ? "confirmButton" : "confirmButton disabled"} onClick={(e) => handleSubmit(e)}>Confirmar</button>
                            <button disabled={disableAll} className={!disableAll ? "cancelButton" : "cancelButton disabled"} onClick={props.scheduler.close}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />
            </div>
        </>


    )
}
export default ModalAddEvent