import React, { useEffect, useState } from "react";
import '../modalTeacherClass.scss'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModalAddTeacherClass from "./modalAddTeacherClass/modalAddTeacherClass";
import ModalEditTeacherClass from "./modalEditTeacherClass/modalEditTeacherClass";
import Axios from "axios";
import ConfirmModal from '../../confirmModal/confirmModal.jsx';
const ModalTeacherClass = (props) => {
    const [orderBy, setOrderBy] = useState(1)
    const [arrayTeacherClasses, setArrayTeacherClasses] = useState([])
    const [openModalAddTeacher, setOpenModalAddTeacher] = useState(false)
    const [openModalEditTeacher, setOpenModalEditTeacher] = useState(false)
    const [teacherAddedFlag, setTeacherAddedFlag] = useState(0)
    const [filterArrayTeacherClasses, setFilterArrayTeacherClasses] = useState([])
    const [selectedRow, setSelectedRow] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false)
    const [optionState, setOptionState] = useState(0)
    useEffect(() => {
        if (props.open === true) {
            const teacherClasses = GetAllTeacherClasses();
            teacherClasses.then((classT) => {
                console.log(classT)
                //if token expire the page is going to reload
                if (classT.teacherClasses !== undefined) {
                    setArrayTeacherClasses(classT.teacherClasses)
                    setFilterArrayTeacherClasses([])
                    switch (orderBy) {
                        case 1:
                            classT.teacherClasses.filter(o => o.st === 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                            break;
                        case 2:

                            classT.teacherClasses.filter(o => o.st === 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                            break;
                        case 3:

                            classT.teacherClasses.filter(o => o.day_off !== 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                            break;
                        case 4:
                            classT.teacherClasses.filter(o => o.st === 2).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))
                            break;
                        default:
                            break;
                    }
                }else{
                    window.location.reload()
                }
            })

        }
    }, [props.open, teacherAddedFlag])


    const GetAllTeacherClasses = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    const headerTemplate = (data) => {
        var header;
        var teacherData;
        switch (orderBy) {
            case 1: header = data.teacher.name; teacherData = [data.teacher.email] + " / " + [data.teacher.phoneNumber]; break;
            case 2: header = data.full_dayclass; break;
            case 3: header = data.level.name; break;
            case 4: header = data.teacher.name; teacherData = [data.teacher.email] + " / " + [data.teacher.phoneNumber]; break;

            default: break;
        }

        return (
            <React.Fragment>
                <span className="span" style={{ justifyContent: 'left' }} > {header} <p>{(teacherData)}</p></span>
            </React.Fragment>)
    }

    const handleCloseModalAddTeacherGroup = () => {
        setOpenModalAddTeacher(false)
    }
    const handleOpenModalAddTeacherGroup = () => {
        setOpenModalAddTeacher(true)
    }
    const handleCloseModalEditTeacherGroup = () => {
        setOpenModalEditTeacher(false)
    }
    const handleOpenModalEditTeacherGroup = () => {
        if (Object.keys(selectedRow).length !== 0) {
            setOpenModalEditTeacher(true)
        }

    }

    const obtenerClaseEstilo = (fila) => {
        var classStyle = "";
        var bookColor = fila.level.name;

        switch (bookColor) {
            case "Yellow": classStyle = "yellowbook"; break;
            case "Red": classStyle = "redbook"; break;
            case "Blue": classStyle = "bluebook"; break;
            case "Green": classStyle = "greenbook"; break;
            case "Purple": classStyle = "purplebook"; break;
            case "Smile 1": classStyle = "s1book"; break;
            case "Smile 2": classStyle = "s2book"; break;
            case "Smile 3": classStyle = "s3book"; break;
            case "Smile 4": classStyle = "s4book"; break;
            case "Smile 5": classStyle = "s5book"; break;
            case "Smile 6": classStyle = "s6book"; break;
            case "Mega": classStyle = "megabook"; break;
            default: break;
        }
        if (fila.day_off === 1) {
            classStyle = "dayOff"
        }
        if (fila.is_online === 1) {
            classStyle = "isOnline"
        }
        let dateC = new Date(fila.date.start)
        let dayToday = new Date()
        dayToday.setHours(0,0,0,0)
        if (dateC > dayToday) {
            classStyle = "soon"
        }
        if (fila === selectedRow) { classStyle = classStyle + " selected-row"; }

        return classStyle;
    };
    //         // Column width is being assingned by the ClassName which has percents clasifides by this way:
    //         // class tableCType: T1 => 5% / T2 => 10% / T3 => 13%  / T4 => 15% / T5 => 25% / T6 => 30%


    const handleSwitchBody = (data, column, orderBy) => {
        switch (orderBy) {
            case 1:
                switch (column) {
                    case 1:
                        if (data.day_off === 1) {
                            return (
                                <div>
                                    <span></span>
                                </div>
                            )
                        } else {
                            if (data.is_online === 1) {
                                return (<div><span>Online</span></div>)
                            } else {

                                return (<div><span>{data.level.name}</span></div>)
                            }
                        }
                    case 2:
                        if (data.day_off === 1) {
                            return (
                                <div>
                                    <span>Disponible</span>
                                </div>
                            )
                        } else {
                            if (data.is_online === 1) {
                                return (
                                    <div><span>{data.level.name} {data.level.unitName} {data.level.idUnitPart !== null && data.level.unitPart}</span></div>
                                )
                            } else {
                                return (
                                    <div><span>{data.level.unitName} {data.level.idUnitPart !== null && data.level.unitPart}</span></div>
                                )
                            }
                        }

                    default:
                        break;
                }
                break;
            case 2:
                switch (column) {
                    case 2:
                        if (data.day_off === 1) {
                            return (
                                <div>
                                    <span></span>
                                </div>
                            )
                        } else {
                            if (data.is_online === 1) {
                                return (<div><span>Online</span></div>)
                            } else {

                                return (<div><span>{data.level.name}</span></div>)
                            }
                        }
                    case 3:
                        if (data.day_off === 1) {
                            return (
                                <div>
                                    <span>Disponible</span>
                                </div>
                            )
                        } else {
                            if (data.is_online === 1) {
                                return (
                                    <div><span>{data.level.name} {data.level.unitName} {data.level.idUnitPart !== null && data.level.unitPart}</span></div>
                                )
                            } else {
                                return (
                                    <div><span>{data.level.unitName} {data.level.idUnitPart !== null && data.level.unitPart}</span></div>
                                )
                            }
                        }
                    default:
                        break;
                }
                break;
            case 3:
                switch (column) {
                    case 2:

                        if (data.is_online === 1) {
                            return (<div><span>Online</span></div>)
                        } else {

                            return (<div><span>{data.level.unitName} {data.level.idUnitPart !== null && data.level.unitPart}</span></div>)
                        }


                    default:
                        break;
                }
                break;
            default:
                break;
        }

    }

    const handleOrderBy = (e) => {
        setFilterArrayTeacherClasses([])
        var option
        if (e.target) {
            option = parseInt(e.target.value)
        } else {
            option = parseInt(e)
        }
        setOrderBy(option)
        switch (option) {
            case 1:
                arrayTeacherClasses.filter(o => o.st === 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                break;
            case 2:

                arrayTeacherClasses.filter(o => o.st === 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                break;
            case 3:

                arrayTeacherClasses.filter(o => o.day_off !== 1).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))

                break;
            case 4:

                arrayTeacherClasses.filter(o => o.st === 2).map(v => setFilterArrayTeacherClasses(arrayTeacherClasses => [...arrayTeacherClasses, v]))
                break;
            default:
                break;
        }
    }
    const handleUpdateUnitClasses = () => {
        setOpenConfirm(true)
        setOptionState(1)
    }

    const handleConfirmAction = () => {
        const optionAction = optionState
        switch (optionAction) {
            case 1:
                const updateUnit = PatchEditUnitTeacherClasses();
                updateUnit.then((res) => {
                    if (res.auth === false) {
                        window.location.reload()
                    }else{
                        if (res.status === 200) {
                            setTeacherAddedFlag(Math.random())
                        }
                    }
                })
                setOpenConfirm(false)
                break;

            default:
                break;
        }

    }

    const PatchEditUnitTeacherClasses = async () => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + "editUnitTeacherClasses/", {
        }, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const handleCancelAction = () => {
        setOpenConfirm(false)
    }
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableEnforceFocus
                disableAutoFocus
            >
                <Box className="box">
                    {/* CHILD MODAL */}
                    <div className="modalHeader">
                        <div className="reporttitile"><p className="paragraphTitle">Listado de Grupos</p></div>
                        <div className="filter">
                            <label className="filterlabel">Orden</label>
                            <select value={orderBy} className="filterselect" onChange={(e) => { handleOrderBy(e) }}>
                                <option value={1}>Maestro</option>
                                <option value={2}>Horario</option>
                                <option value={3}>Libro</option>
                                <option value={4}>Finalizado</option>
                            </select>
                        </div>
                    </div>
                    <div className='modalBody'>
                        {
                            orderBy === 1 &&
                            <DataTable
                                value={filterArrayTeacherClasses}
                                rowGroupMode="subheader"
                                groupRowsBy="teacher.name"
                                sortMode="single"
                                sortField="teacher.name"
                                rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1}

                                rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                onDoubleClick={handleOpenModalEditTeacherGroup}
                            >
                                <Column field="level.name" header="Libros" body={(data) => handleSwitchBody(data, 1, 1)} className="tableCtype4 td"></Column>
                                <Column field="level.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 2, 1)} className="tableCtype4 td"></Column>
                                <Column field="full_dayclass" header="Horario" className="tableCtype5 td"></Column>
                                <Column field="subsidiary.name" header="Plantel" className="tableCtype4 td"></Column>
                                <Column field="students_qty" header="Estudiantes" className="tableCtype4 td"></Column>
                            </DataTable>
                        }
                        {
                            orderBy === 2 &&
                            <DataTable value={filterArrayTeacherClasses} rowGroupMode="subheader" groupRowsBy="full_dayclass" sortMode="single" sortField="full_dayclass" rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1} rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                onDoubleClick={handleOpenModalEditTeacherGroup}
                            >
                                <Column field="teacher.name" header="Maestro" className="tableCtype5 td"></Column>
                                <Column field="level.name" header="Libros" body={(data) => handleSwitchBody(data, 2, 2)} className="tableCtype3 td"></Column>
                                <Column field="level.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 3, 2)} className="tableCtype3 td"></Column>
                                <Column field="subsidiary.name" header="Plantel" className="tableCtype5 td"></Column>
                                <Column field="students_qty" header="Estudiantes" className="tableCtype4 td"></Column>
                            </DataTable>
                        }
                        {
                            orderBy === 3 &&
                            <DataTable value={filterArrayTeacherClasses} rowGroupMode="subheader" groupRowsBy="level.name" sortMode="single" sortField="level.name" rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1} rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                onDoubleClick={handleOpenModalEditTeacherGroup}
                            >
                                <Column field="teacher.name" header="Maestro" className="tableCtype5 td"></Column>
                                <Column field="level.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 2, 3)} className="tableCtype3 td"></Column>
                                <Column field="full_dayclass" header="Libros" className="tableCtype5 td"></Column>
                                <Column field="subsidiary.name" header="Plantel" className="tableCtype5 td"></Column>
                                <Column field="students_qty" header="Estudiantes" className="tableCtype3 td"></Column>
                            </DataTable>
                        }
                        {
                            orderBy === 4 &&
                            <DataTable
                                value={filterArrayTeacherClasses}
                                rowGroupMode="subheader"
                                groupRowsBy="teacher.name"
                                sortMode="single"
                                sortField="teacher.name"
                                rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1}

                                rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                onDoubleClick={handleOpenModalEditTeacherGroup}
                            >
                                <Column field="level.name" header="Libros" body={(data) => handleSwitchBody(data, 1, 1)} className="tableCtype4 td"></Column>
                                <Column field="level.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 2, 1)} className="tableCtype4 td"></Column>
                                <Column field="full_dayclass" header="Horario" className="tableCtype5 td"></Column>
                                <Column field="subsidiary.name" header="Plantel" className="tableCtype4 td"></Column>
                                <Column field="students_qty" header="Estudiantes" className="tableCtype4 td"></Column>
                            </DataTable>
                        }


                    </div>
                    <div className="modalFooter">
                        <div className="buttonContainer">
                            <button onClick={handleOpenModalAddTeacherGroup}><i className="material-icons">add</i></button>
                            <button onClick={handleUpdateUnitClasses}><i className="material-icons">update</i></button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <ModalAddTeacherClass
                open={openModalAddTeacher}
                onCloseButton={handleCloseModalAddTeacherGroup}
                setTeacherAddedFlag={setTeacherAddedFlag}
            />
            <ModalEditTeacherClass
                open={openModalEditTeacher}
                onCloseButton={handleCloseModalEditTeacherGroup}
                editData={selectedRow}
                setTeacherAddedFlag={setTeacherAddedFlag}

            />
            <ConfirmModal
                open={openConfirm}
                handleConfirm={handleConfirmAction}
                onClickCancel={handleCancelAction}
            />

        </>

    )
}
export default ModalTeacherClass