import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './SignatureCanva.scss';
import Button from '../button/button';
const DrawingSVG = forwardRef(({ isOpen, onClose, setSignature, signatureUrl }, ref) => {
    const [newSignRequired, setNewSignRequired] = useState(false);
    const [isSigned, setIsSigned] = useState(false)
    const [paths, setPaths] = useState([]);
    const [currentPath, setCurrentPath] = useState([]);
    const [savedImage, setSavedImage] = useState(signatureUrl || '');
    const svgRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            setNewSignRequired(false)
            clearDrawing()
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);


    const getSvgCoords = (clientX, clientY) => {
        const svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        return { x, y };
    };

    const startDrawing = (e) => {
        e.preventDefault();
        setIsSigned(true)
        const { clientX, clientY } = e.touches ? e.touches[0] : e;
        const { x, y } = getSvgCoords(clientX, clientY);
        setCurrentPath([{ x, y }]);
    };

    const stopDrawing = () => {
        if (currentPath.length > 0) {
            setPaths([...paths, currentPath]);
            setCurrentPath([]);
        }
    };

    const draw = (e) => {
        if (currentPath.length === 0) return;

        e.preventDefault();
        const { clientX, clientY } = e.touches ? e.touches[0] : e;
        const { x, y } = getSvgCoords(clientX, clientY);
        setCurrentPath([...currentPath, { x, y }]);
    };

    const smoothPoints = (points, smoothingFactor = .4) => {
        if (points.length < 3) return points;

        const smoothedPoints = [];
        smoothedPoints.push(points[0]); // El primer punto no cambia

        for (let i = 1; i < points.length - 1; i++) {
            const prevPoint = points[i - 1];
            const currentPoint = points[i];
            const nextPoint = points[i + 1];

            // Aplicar un promedio ponderado entre los puntos anterior, actual y siguiente
            const smoothedX = prevPoint.x * (1 - smoothingFactor) + currentPoint.x * smoothingFactor;
            const smoothedY = prevPoint.y * (1 - smoothingFactor) + currentPoint.y * smoothingFactor;

            smoothedPoints.push({ x: smoothedX, y: smoothedY });
        }

        smoothedPoints.push(points[points.length - 1]); // El último punto no cambia
        return smoothedPoints;
    };

    // Función para generar el path SVG utilizando curvas Bezier para suavizar
    const generatePathData = (points) => {
        const smoothedPoints = smoothPoints(points); // Suaviza los puntos antes de generar el path

        if (smoothedPoints.length < 2) return '';

        let pathData = `M${smoothedPoints[0].x},${smoothedPoints[0].y}`;

        for (let i = 1; i < smoothedPoints.length - 1; i++) {
            const midPoint = {
                x: (smoothedPoints[i].x + smoothedPoints[i + 1].x) / 2,
                y: (smoothedPoints[i].y + smoothedPoints[i + 1].y) / 2
            };
            pathData += ` Q${smoothedPoints[i].x},${smoothedPoints[i].y} ${midPoint.x},${midPoint.y}`;
        }

        // Agregar el último segmento de línea
        pathData += ` T${smoothedPoints[smoothedPoints.length - 1].x},${smoothedPoints[smoothedPoints.length - 1].y}`;

        return pathData;
    };

    const clearDrawing = () => {
        setPaths([]);
        setCurrentPath([]);
        setSavedImage(''); // Limpiar la imagen guardada

        setIsSigned(false)
    };
    useImperativeHandle(ref, () => ({
        clearDrawing
    }))
    const handleClaerOldSignature = () => {
        setNewSignRequired(true)
    }
    const saveDrawing = () => {
        const svg = svgRef.current;
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Definir dimensiones del canvas
        const width = svg.clientWidth;
        const height = svg.clientHeight;
        canvas.width = width;
        canvas.height = height;

        // Crear una imagen a partir del SVG
        const img = new Image();
        const dataURL = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            // Obtener el PNG en Base64
            const pngDataURL = canvas.toDataURL('image/png');
            setSavedImage(pngDataURL);
            setSignature(pngDataURL)

        };

        img.src = dataURL;
    };
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content main-color">
                <button onClick={onClose} className="close-button main-color">X</button>
                <h2>Firma Digital</h2>
                <div className="svg-container sub-color">
                    <svg
                        ref={svgRef}
                        id="drawingSVG"
                        xmlns="http://www.w3.org/2000/svg"
                        width="400px"
                        height="150px"
                        viewBox="0 0 100% 100%"
                        onMouseDown={newSignRequired || !signatureUrl ? startDrawing : undefined}
                        onMouseUp={newSignRequired || !signatureUrl ? stopDrawing : undefined}
                        onMouseMove={newSignRequired || !signatureUrl ? draw : undefined}
                        onTouchStart={newSignRequired || !signatureUrl ? startDrawing : undefined}
                        onTouchEnd={newSignRequired || !signatureUrl ? stopDrawing : undefined}
                        onTouchMove={newSignRequired || !signatureUrl ? draw : undefined}
                    >
                        {/* Agrega la imagen de la firma desde la URL como fondo */}
                        {signatureUrl && !newSignRequired && (
                            <image href={signatureUrl} x="0" y="0" width="100%" height="100%" />
                        )}
                        {/* <rect width="100%" height="100%"  /> */}
                        {paths.map((path, index) => (
                            <path key={index} d={generatePathData(path)} stroke="black" strokeWidth="2" fill="none" />
                        ))}
                        {currentPath.length > 0 && (
                            <path d={generatePathData(currentPath)} stroke="black" strokeWidth="2" fill="none" />
                        )}
                    </svg>
                </div>
                <div className='buttons-container'>
                    <Button onClick={clearDrawing} text="Limpiar">Limpiar</Button>
                    {signatureUrl && <Button onClick={handleClaerOldSignature} disabled={newSignRequired ? true : false} text="Nueva Firma">Nueva Firma</Button>}
                    <Button onClick={saveDrawing} disabled={!isSigned ? true : false} text="Guardar como Imagen">Guardar como Imagen</Button>
                </div>
                <div className='message_container'>
                    {
                        savedImage &&
                        <span>Firma Guardada</span>
                    }
                </div>
            </div>
        </div>
    );
}
);

export default DrawingSVG;
