import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import './confirmModal.scss'
import Button from "../button/button";
import Axios from "axios";
const ConfirmModal = ({ modalData, setModalData, setIsUserDeleted}) => {
  const [open, setOpen] = useState(false)
  const [id, setId] = useState(null);
  const [idPerson, setIdPerson] = useState(null);
  const [email, setEmail] = useState(''); // Este está deshabilitado, pero igual definimos el estado
  const [action, setAction] = useState('');
  const [message, setMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (modalData !== null) {
      setOpen(true);
      setId(modalData.id)
      setIdPerson(modalData.idPerson)
      setEmail(modalData.email)
      setAction(modalData.action)
    }
  }, [modalData]);

  const handleAction = () => {
    switch (action) {
      case "deleteUser":
        handleDeleteUser();
        break;
      case "deletePerson":
        handleDeletePerson();
        break;

      default:
        break;
    }
  }
  const handleDeleteUser = () => {
    // delete API
    Axios.delete(process.env.REACT_APP_API_URL + `deleteUser/${id}/${idPerson}`, {
      headers: { "x-access-token": localStorage.getItem("token") }
    }).then((res) => {
      handleResponse(res.data)
    }).catch((error) => {
      console.error("Error deleting user:", error);
    });
  };
  const handleResponse = (response) => {
    const message = response.message
    const status = response.status
    switch (status) {
      case 200:
        setMessage(message)
        setButtonDisabled(true)
        setTimeout(() => {
          setIsUserDeleted(true)
          handleCloseModalConfirm();
        }, 2000);
        break;
      case 201:
        setMessage(message)
        break;
      default:
        break;
    }
  };
  const handleDeletePerson = () =>{
    // delete API
    Axios.delete(process.env.REACT_APP_API_URL + `deletePersonNoUser/${id}/${email}`, {
      headers: { "x-access-token": localStorage.getItem("token") }
    }).then((res) => {
      handleResponse(res.data)
    }).catch((error) => {
      console.error("Error deleting user:", error);
    });
  }
  const handleCloseModalConfirm = () => {
    setMessage('')
    setModalData(null);
    setOpen(false);
    setButtonDisabled(false)
  }
  return (
    <Modal
      open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className={"mainModalConfirm"}>
        <div className="containerModalConfirm">
          <h4>¿Estás seguro de que quieres realizar esta acción?</h4>

          <div className="childButtons">
            <Button disabled={buttonDisabled} text={"Cancelar"} onClick={handleCloseModalConfirm} />
            <Button disabled={buttonDisabled} text={"Aceptar"} onClick={handleAction} />

          </div>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
export default ConfirmModal