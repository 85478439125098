import React, { useState, useEffect } from "react";
import Axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputPassword from "../../inputPassword/inputPassword";
import './changePasswordModal.scss'
import Button from "../../button/button";
const ChangePasswordModal = ({ modalData, setModalData }) => {
  const [open, setOpen] = useState(false)
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [errors, setErrors] = useState({
    newPassword: false,
    confirmPassword: false
  });

  useEffect(() => {
    if (modalData !== null) {
      setOpen(true)
    }
  }, [modalData])

  ////////API CHANGE PASSWORD/////////////////////////////
  const handleChangePassword = () => {
    // Verificar campos vacíos
    const newErrors = {
      newPassword: !newPassword,
      confirmPassword: !confirmPassword

    }
    setErrors(newErrors);
    // Verificar campos vacíos
    if (Object.values(newErrors).some((error) => error)) {
      setMessage("Todos los campos son obligatorios.");
      setTimeout(() => {
        setMessage('');
      }, 2500);
      return;
    }
    Axios.patch(
      process.env.REACT_APP_API_URL + "changeUserPasswordSuperAdmin/" + modalData.id,
      {
        email: modalData.email,
        password: newPassword,
        confirmPassword: confirmPassword
      },
      { headers: { "x-access-token": localStorage.getItem("token") } }
    ).then((res) => {
      const status = res.data.status
      switch (status) {
        case 200:
          setButtonDisabled(true)
          setMessage(res.data.message)
          setTimeout(() => {
            handleCloseEdit()
          }, 2000);
          break;
        case 201:
          setMessage(res.data.message)
          setTimeout(() => {
            setMessage("")
          }, 1500);
          break;
        case 202:
          setMessage(res.data.message)
          setTimeout(() => {
            setMessage("")
          }, 1500);
          break;
        default:
          break;
      }
    });

  };


  const handleCloseEdit = () => {
    setModalData(null);
    setOpen(false);
    setButtonDisabled(false)
    setMessage("")
    setNewPassword("")
    setConfirmPassword("")
  }

  return (
    <Modal
      open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className={'containerPasswordEditModal'}>
        <div className="modalChangePasswordContainer">
          <h1>Cambiar Contraseña</h1>
          <InputPassword
            value={newPassword}
            placeholder="Nueva Contraseña"
            onChange={(e) => setNewPassword(e.target.value)}
            className={errors.newPassword ? 'error' : ''}

          />
          <InputPassword
            placeholder="Confirmar Contraseña"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={errors.confirmPassword ? 'error' : ''}

          />
          <div className="childButtons">
            <Button
              onClick={handleCloseEdit}
              text={"Cancelar"}
              disabled={buttonDisabled}
            />
            <Button
              text={"Aceptar"}
              onClick={handleChangePassword}
              disabled={buttonDisabled}
            />
          </div>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
export default ChangePasswordModal