import React, { useEffect, useState } from 'react';
import Button from '../../../button/button';
import PrintSVG from '../../../icons/print';
import MailSVG from '../../../icons/mail';
import CloseSVG from '../../../icons/close';
import OnlineRegister from '../OnlineRegister'; // Importa tu componente PDFGenerator
import InPersonRegisterPDF from '../InPersonRegisterDoc'; // Importa tu componente PDFGenerator
import './SuccessRegister.scss';

const SuccessRegister = ({ onClose, formData }) => {
    const [dataToPDF] =useState({
        registerAdviser: formData.advisorRegistered || '',
        studentName: formData.studentName|| '',
        studentMiddleName: formData.studentMiddleName|| '',
        studentLastName: formData.studentLastName|| '',
        studentBirthDate: formData.studentDateBirth || '',
        studentStreet: formData.studentStreet|| '',
        studentExtNum: formData.studentExtNumber|| '',
        studentIntNum: formData.studentIntNumber|| '',
        studentNeiborhood: formData.studentColony|| '',
        studentCity: formData.studentCity|| '',
        studentState: formData.studentState|| '',
        studentCP: formData.studentZipCode|| '',
        studentPhone1: formData.studentPhoneNumberHome || '',
        studentPhone2: formData.studentPhoneNumber || '',
        studentPhone3: formData.studentPhoneNumberOffice || '',
        studyLevel: formData.studentSchooling || '',
        guardianName: formData.guardian.guardianName|| '',
        guardianPhone: formData.guardian.guardianPhoneNumber || '',
        schedule: formData.schedule || '',
        teacher: formData.teacherName || '',
        today: formData.today || '',
        subsidiary: formData.subsidiary || '',
        signature: formData.signature || '',
        ticketFolio: formData.inscriptionFolio || '',
        isOnline: formData.isOnline || false,
    })
    const generatePDF = async (action) => {
        const { isOnline } = dataToPDF;

        let pdfDoc;

        // Decide el documento correcto basado en isOnline
        if (isOnline) {
            pdfDoc = await OnlineRegister(dataToPDF);
        } else {
            pdfDoc = await InPersonRegisterPDF(dataToPDF);
        }

        if (action === 'print') {
            // Imprimir el PDF
            pdfDoc.autoPrint();
            window.open(pdfDoc.output('bloburl'), '_blank');
        } else if (action === 'email') {
            // Enviar el PDF por correo
            const pdfBlob = pdfDoc.output('blob');
            sendEmailWithPDF(pdfBlob);  // Implementar función de enviar por correo
        }
    };

    const sendEmailWithPDF = (pdfBlob) => {
        // Implementar lógica para enviar el PDF por correo
        console.log('Enviar PDF por correo electrónico...');
    };

    return (
        <div className="success-register">
            <div className="success-register-content main-color">
                <div className="success-header">
                    <h2>Registro exitoso</h2>
                    <button onClick={onClose} className="close-button"><CloseSVG className="main-color" /></button>
                </div>
                <div className="success-body">
                    <p>El registro del alumno ha sido completado con éxito.</p>
                </div>
                <div className="success-buttons">
                    {/* Usa el componente PDFGenerator y pasa formData como prop */}
                    {/* <OnlineRegister formData={formData} /> */}
                    {/* <InPersonRegisterPDF formData={formData} /> */}
                    {/* <Button text="Enviar inscripción" Icon={MailSVG} /> */}
                    <Button onClick={() => generatePDF('print')} text="Imprimir Inscripción" Icon={PrintSVG}></Button>
                    <Button onClick={() => generatePDF('email')} text="Enviar por Correo" Icon={MailSVG}></Button>
                </div>
            </div>
        </div>
    );
};

export default SuccessRegister;