import * as React from "react"
const GroupTabSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{"GroupTab"}</title>
    <path
      fillRule="evenodd"
      d="M19 63h472v385H19zm16 95.5c217.4 1.2 316.6 1.5 360.8 1.5H476V79H35zm0 69.5h171v-51H35zm187 0h118v-51H222zm134 0h120v-51H356zM35 296h171v-52H35zm187 0h118v-52H222zm134 0h120v-52H356zM35 364h171v-52H35zm187 0h118v-52H222zm134 0h121v-52H356zM35 431h171v-51H35zm187 1h118v-52H222zm134-1h121v-51H356zM52 121h131v15H52zm308 1h105v15H360zm-132 1h105v15H228zm0 60h107v39H228zM67 194c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm46 0c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm46 0c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm204 123h107v39H363zM74 328c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm46 0c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm46 0c2.9 0 4.7.7 6.5 2.5 1.8 1.8 2.5 3.6 2.5 6.5q0 4-2 6t-6 2c-2.9 0-4.7-.7-6.5-2.5-1.8-1.8-2.5-3.6-2.5-6.5q0-4 2-6t6-2zm59 60h107v39H225z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)
export default GroupTabSVG