// config.js

export const fontSize = {
    small: 10.5,
    paragraph: 11.5,
    caption: 13,
    title: 22,
    title2: 20
};

export const colors = {
    lightblue: '#0070C0',
    blue: '#0423BC',
    green: '#00B050',
    red: '#C00000',
    yellow: '#FFC000',
    lightgray: '#E7E6E6',
    cian: '#00F7FF',
    purple: '#6E48AA',
    orange: '#D75E26',
    white: '#FFF',
    gold: '#AC7A31',
    gray: ' #595959',
    darkgray: '#404040',
};
