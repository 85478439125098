import React, { useState } from 'react';
import './Tabs.scss';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index, isDisabled) => {
    if (!isDisabled) {
      setActiveTab(index);
    }
  };

  return (
    <div className="tabs-container ">
      <div className="tabs-header">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`main-color tab ${activeTab === index ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
            onClick={() => handleTabClick(index, tab.disabled)}
            disabled={tab.disabled}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tabs-content main-color">
        {tabs.map((tab, index) => (
          activeTab === index && !tab.disabled && (
            <div key={index} className="tab-content">
              {tab.content}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Tabs;
