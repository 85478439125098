import React from "react";
import "./inputAnimated.scss"
const InputAnimated = (props) => {
    return (
        <div className="input-container">
            <input name={props.name} maxLength={props.maxLength} onInput={props.onInput} disabled={props.disabled} className={props.className} type={props.type} value={props.value} onChange={props.onChange} onBlur={props.onBlur}  placeholder=" " required={props.required} onKeyDown={props.onKeyDown}></input>
            <label className="input-label">{props.placeholder}</label>
        </div>
    )
}
export default InputAnimated