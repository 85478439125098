const convertImageToBase64 = (imageInput) => {
  return new Promise((resolve, reject) => {
    if (typeof imageInput === 'string') {
      // Manejo de rutas o URLs
      const image = new Image();
      image.crossOrigin = 'Anonymous'; // Evitar problemas de CORS con URLs
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        resolve(canvas.toDataURL('image/png')); // Convertir a base64
      };
      image.onerror = () => reject(new Error(`No se pudo cargar la imagen desde: ${imageInput}`));
      image.src = imageInput;
    } else if (imageInput instanceof File) {
      // Manejo de archivos locales usando FileReader
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = () => reject(new Error(`No se pudo leer el archivo: ${imageInput.name}`));
      reader.readAsDataURL(imageInput);
    } else {
      reject(new Error('El formato de entrada no es válido. Usa una URL, ruta o un archivo.'));
    }
  });
};

export default convertImageToBase64;
