import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "./register.scss";
import VerifyExisting from "../../components/register/verifyExisting/verifyExisting";
import RegisterPassword from "../../components/register/registerPassword/registerPassword";
import RegisterForm from "../../components/register/registerForm/registerForm";
const Register = () => {
  const navigate = useNavigate();
  const [stateComponentSelected, setStateComponentSelected] = useState(0)
  const [verifyData, setVerifyData] = useState(null); // Estado para guardar la información de VerifyExisting
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        navigate(-1);
      }
    });
  }, []);

  // Función de callback que actualizará el estado en Register
  const handleVerifyData = (data) => {
    const st = data.status;
    setVerifyData(data)
    switch (st) {
      case 200:
        setStateComponentSelected(1)
        break;
      case 201:
        setStateComponentSelected(2)

        break;
      case 202:
        setStateComponentSelected(0)
        break;
      default:
        break;
    }
  };

  const ComponentSelected = (component) => {
    switch (component) {
      case 0:
        return (
          <VerifyExisting onVerify={handleVerifyData} />
        )
        case 1:
        return (
          <RegisterForm verifyData={verifyData}/>
        )
        case 2:
        return (
          <RegisterPassword verifyData={verifyData}/>
        )

      default:
        break;
    }
  }


  return (
    <div className="registerContainer">
      {ComponentSelected(stateComponentSelected)}
    </div>
  );
};
export default Register;
