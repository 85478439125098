import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./recoverAccountCode.scss";
import InputAnimated from "../../../components/inputAnimated/inputAnimated";
import Button from "../../../components/button/button";

const RecoverAccountCode = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state === null) {
      navigate(-1);
    } else {
      setEmail(location.state.email);
    }
  }, []);
  const handleRecover = (event) => {
    event.preventDefault();
    Axios.post(process.env.REACT_APP_API_URL + "changePasswordCodeProcess/", {
      code: code,
      email: email,
    }).then((response) => {
      if (response.data.status === 201) {
        setMessage(response.data.message);
      } else if (response.data.status === 200) {
        navigate('/changePassword', { state: response.data.data })
      }
    });
  };
  return (
    <>
      <div className="recoverCodeContainer">
        <form
          className="recoverAccountCodeForm"
          id="recoverAccountCodeForm"
          action="#"
          onSubmit={handleRecover}
        >
          <h1>Código De Recuperación</h1>
          <h4>
            Escribe el código de seguridad que se te mando a tu correo
            electrónico: {email}
          </h4>
          <div className="containerCodeInputAndMessage">
            <InputAnimated
              className="codeInput"
              type={"number"}
              placeholder="Código"
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <p>{message}</p>
          </div>
          <Button type="submit" text={"Enviar"} />
        </form>
      </div>
    </>
  );
};

export default RecoverAccountCode;
