import React, { useState } from "react";
import InputAnimated from "../../inputAnimated/inputAnimated";
import './verifyExisting.scss';
import Axios from "axios";

const VerifyExisting = ({ onVerify }) => {
    const [emailState, setEmailState] = useState('');
    const [errors, setErrors] = useState({ email: '' });

    const handleSubmitVerify = (event) => {
        event.preventDefault();

        // Resetear errores
        setErrors({ email: '' });

        // Validaciones
        let valid = true;
        let newErrors = { email: '' };

        if (!emailState) {
            newErrors.email = 'El correo electrónico no puede estar vacío';
            valid = false;
        }
        setErrors(newErrors);

        if (valid) {
            // Realiza la llamada a la API si las validaciones pasan
            Axios.post(process.env.REACT_APP_API_URL + "verifyExistingRegister/", {
                email: emailState,
            }).then((res) => {
                const result = res.data
                if (result.status === 202) {
                    newErrors.email = result.message;
                    setErrors(newErrors)

                }
                onVerify(result)
            });
        }
    };

    return (
        <div className="verifyExistingContainer">
            <form className="verifyForm" onSubmit={handleSubmitVerify}>
                <div className="titleForm">
                    <h1>Registrar</h1>
                </div>
                <InputAnimated
                    type="email"
                    placeholder="Email"
                    value={emailState}
                    onChange={(e) => setEmailState(e.target.value)}
                    maxLength={60}
                />
                {errors.email && <p className="error">{errors.email}</p>}
                <div className="buttonForm">
                    <button className="button" type="submit">Aceptar</button>
                </div>
                <div className="bottomForm">
                    <span>
                        ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
                    </span>
                </div>
            </form>
        </div>
    );
};

export default VerifyExisting;
