const ExpandSVG = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="#FFF"
      viewBox="0 -960 960 960"
      {...props}
    >
      <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  )
  export default ExpandSVG
  