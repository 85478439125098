import RecepMenu from "../../components/Reception/RecepMenu/RecepMenu";
import "./reception.scss";
import StudentInfo from '../../components/Reception/Student/StudentInfo/StudentInfo';
import StudentRegister from '../../components/Reception/Student/StudentRegister/StudentRegister';
import React, { useState, useEffect, useRef } from "react";
// import SuccessRegister from "./Student/StudentRegister/SuccessRegister";
import ClassroomsGroups from "../../components/Reception/Groups/GroupsList";
//Axios
import Axios from 'axios';
//navigate
import { useNavigate } from "react-router-dom";
//User session
import { UserContext } from "../../context/userProvider";
import { dataDecrypt } from "../../utils/dataDecrypt";
//Formats
import PrintFormat from "../../components/Reception/Formats/printFormat"



const Reception = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const menuRef = useRef(null);
    const [user, setUser] = React.useContext(UserContext);
    var sessionStorageArray = [];
    //select list variables
    const [dataLists, setDataLists] = useState({
        schoolingList: [],
        genreList: [
            { value: 1, label: 'Hombre' },
            { value: 2, label: 'Mujer' }
        ],
        relatedList: [],
        allTeacherClassesList: [],
        surveyOptionsList1: [],
        surveyOptionsList2: [],
        receptionPeopleList: [],
        subsidiaryList:[]
    });

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMenuItemClick = (component) => {
        setSelectedComponent(component);
    };

    const handleCloseComponent = () => {
        setSelectedComponent(null); // Regresa al estado inicial sin componentes visibles
    };
    /////////////LIST//////////////////
    useEffect(() => {
        const fetchData = async () => {

            try {
                // Ejecutar todas las promesas en paralelo
                const [
                    schoolingResponse,
                    relatedResponse,
                    teacherClassesResponse,
                    survey1Response,
                    survey2Response,
                    receptionPeopleResponse,
                    subsidiaryResponse
                ] = await Promise.all([
                    GetSchoolingList(),
                    GetRelatedTypeList(),
                    GetAllTeacherClasses(),
                    GetSurveyOptions(1),
                    GetSurveyOptions(2),
                    GetReceptionPeopleList(),
                    GetSubsidiaryList()
                ]);

                if (schoolingResponse.auth === false ||
                    relatedResponse.auth === false ||
                    teacherClassesResponse.auth === false ||
                    survey1Response.auth === false ||
                    survey2Response.auth === false ||
                    receptionPeopleResponse.auth === false ||
                    subsidiaryResponse.auth === false) {
                    navigate("/login");
                    return;
                }

                // Actualizar todas las listas en un solo setDataLists
                setDataLists(prevState => ({
                    ...prevState,
                    schoolingList: schoolingResponse.result.map(element => ({
                        value: element.id,
                        label: element.name
                    })),
                    relatedList: relatedResponse.result.map(element => ({
                        value: element.id,
                        label: element.name
                    })),
                    allTeacherClassesList: teacherClassesResponse.teacherClasses.map(element => ({
                        value: element.id,
                        label: `${element.full_dayclass} / ${element.teacher.name} / ${element.level.name}`,
                        detail_class: {
                            subsidiary: element.subsidiary,
                            is_online: element.is_online === 0 ? false : true
                        }
                    })),
                    surveyOptionsList1: survey1Response.result.map(element => ({
                        value: element.id,
                        label: element.option
                    })),
                    surveyOptionsList2: survey2Response.result.map(element => ({
                        value: element.id,
                        label: element.option
                    })),
                    receptionPeopleList: receptionPeopleResponse.result.map(element => ({
                        value: element.employee_id,
                        label: `${element.name} ${element.middle_name} ${element.last_name}`,
                        subsidiary:{
                            id:element.subsidiary_id,
                            name: element.subsidiary_name
                        }
                    })),
                    subsidiaryList: subsidiaryResponse.subsidiary.map(element => ({
                        value: element.subsidiary_id,
                        label: `${element.name}`
                    }))
                }));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!localStorage.getItem("user")) {
            localStorage.removeItem("token");
        }
        Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        }).then((response) => {
            if (response.data.auth === false) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/login");
            } else {
                if (Object.keys(user).length === 0) {
                    var userStorage = localStorage.getItem("user");
                    var userJson = dataDecrypt(userStorage);
                    setUser(userJson);
                    var checkAuth = false;
                    sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
                    sessionStorageArray.forEach((e) => {
                        if (userJson.id === e.id) {
                            checkAuth = true;
                        }
                    });
                    if (
                        (userJson.idPriv.toString() !==
                            process.env.REACT_APP_API_RECEPCION_PRIVILEGE &&
                            userJson.idPriv.toString() !==
                            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
                        checkAuth !== true
                    ) {
                        navigate("/login");
                    }
                    fetchData();

                } else {
                    var checkAuth = false;
                    sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
                    sessionStorageArray.forEach((e) => {
                        if (user.id === e.id) {
                            checkAuth = true;
                        }
                    });
                    if (
                        (user.idPriv.toString() !==
                            process.env.REACT_APP_API_RECEPCION_PRIVILEGE &&
                            user.idPriv.toString() !==
                            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
                        checkAuth !== true
                    ) {
                        navigate("/login");
                    }
                    fetchData();

                }
            }
        });
    }, []);

    const GetSchoolingList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSchoolingList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        });
        return res.data;
    };

    const GetRelatedTypeList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getRelatedTypeList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        });
        return res.data;
    };

    const GetAllTeacherClasses = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        });
        return res.data;
    };

    const GetReceptionPeopleList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getReceptionPeopleList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        });
        return res.data;
    };

    const GetSurveyOptions = async (question) => {
        const res = await Axios.post(process.env.REACT_APP_API_URL + "getSurveyOptions/",
            {
                survey_question_id: question
            },
            {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            });
        return res.data;
    };
    const GetSubsidiaryList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSubsidiaryList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        });
        return res.data;
    };

    const renderSelectedComponent = () => {
        if (selectedComponent) {
            switch (selectedComponent) {
                case 'Registrar alumno':
                    return <StudentRegister onClose={handleCloseComponent} list={dataLists} usr={user} />;
                case 'Info alumno':
                    return <StudentInfo onClose={handleCloseComponent} list={dataLists} />;
                case 'Listado de grupos':
                    return <ClassroomsGroups onClose={handleCloseComponent} />;
                case 'Diplomas y Constancias':
                    return <PrintFormat onClose={handleCloseComponent} isOpen={true} usr={user} listSubsidiary={dataLists.subsidiaryList}/>;
                default:
                    return <div></div>;
            }
        }
    };

    return (
        <div className="recep-main">
            <section className="recep-side-menu main-color" ref={menuRef}>
                <div className={`menu ${menuOpen ? 'active' : ''}`} >
                    <RecepMenu onMenuItemClick={handleMenuItemClick} /> {/* Pasar la función a RecepMenu */}
                </div>
                <div className="side-menu-bar" onClick={handleMenuClick}> <span>{"||>"}</span></div>
            </section>
            <section className="recep-contain background-img">
                {renderSelectedComponent()} {/* Renderiza el componente seleccionado */}
            </section>
        </div>
    );
}

export default Reception;