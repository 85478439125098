import React, { useEffect, useState } from "react";
import "./phoneAuthentication.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import smsImg from "../../../assets/sms.png";
import { dataDecrypt } from "../../../utils/dataDecrypt";
import { dataEncrpt } from "../../../utils/dataEncrypt";

const PhoneAuthentication = (props) => {
  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [send, setSend] = useState(0);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [phoneAsterisk, setPhoneAsterisk] = useState("");
  var sessionStorageArray = [];
  var objSessionStorage = {};
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setId(props.phoneUser.id);
    setPhone(props.phoneUser.phone);
    setPhoneAsterisk(maskify(location.state.phone));
    console.log(props)
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var exist = 0;

    Axios.patch(
      process.env.REACT_APP_API_URL + "authenticationPhoneCodeProcess/",
      {
        phone: phone,
        idUser: id,
        code: code,
      }
    ).then((response) => {
      var status = response.data.status;
      if (status === 200) {
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        objSessionStorage = { id: id, phone: phone };
        sessionStorageArray.forEach((e) => {
          if (objSessionStorage.id === e.id) {
            exist = exist + 1;
          }
        });
        if (exist === 0) {
          sessionStorageArray.push(objSessionStorage);
          localStorage.setItem("session", dataEncrpt(sessionStorageArray));
        }
        checkRequestAuthentication();
        navigate("/login");
      } else if (status === 201) {
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    });
  };

  const checkRequestAuthentication = () => {
    Axios.post(process.env.REACT_APP_API_URL + "checkRequestAuthentication/", {
      idUser: id,
    }).then((response) => {
      deleteRequestAuthentication(
        response.data.emailAuth,
        response.data.phoneAuth
      );
    });
  };
  const deleteRequestAuthentication = (idEmail, idPhone) => {
    Axios.delete(
      process.env.REACT_APP_API_URL +
      `deleteRequestAuthentication/${idEmail}/${idPhone}`
    ).then((response) => { });
  };

  function maskify(phone) {
    let arr = phone.split("");
    for (let i = 0; i < arr.length - 4; i++) {
      arr[i] = "*";
    }
    return arr.join("");
  }

  const sendPhoneSmsAgain = () => {
    Axios.post(process.env.REACT_APP_API_URL + "authenticationRequestPhone/", {
      phone: phone,
      idUser: id,
      isFromLoginButton: true,
    }).then((response) => {
      console.log(response)
      setSend(1);
      setMessage(response.data.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
      setTimeout(() => {
        setSend(0);
      }, 20000);
    });
  };

  const handleBackOption = () => {
    props.handleBack(0);
  };
  return (
    <>
      <div className="phoneAuthenticationContainer">
        <div className="phoneAuthenticationContainerBorder">
          <h1>Autentica tu cuenta</h1>
          <img src={smsImg} alt="" />
          <p>
            Hemos enviado un SMS al número: {phoneAsterisk}, el código de
            activación de cuenta expirará en 15 minutos.
          </p>
          <p>
            Da{" "}
            {send === 0 ? (
              <a onClick={sendPhoneSmsAgain}>click aquí</a>
            ) : (
              "click aquí"
            )}{" "}
            si no recibiste el SMS
          </p>
          <form
            className="phoneAuthenticationCodeInput"
            onSubmit={handleSubmit}
          >
            <input
              className="codeInput"
              type="number"
              placeholder="Ingresa el código aquí"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <div className="buttonSubmitContainer">
              <input
                className="buttonSubmit"
                onClick={handleBackOption}
                type={"button"}
                value={"Regresar"}
              />
              <input
                className="buttonSubmit"
                type={"submit"}
                value={"Aceptar"}
              />
            </div>
          </form>
          <p className="message">{message}</p>
        </div>
      </div>
    </>
  );
};

export default PhoneAuthentication;
