import "./DropDownList.scss";
import ExpandSVG from "../icons/expand";



const DropDownList = ({ Data, iconColor = '', onMenuItemClick }) => {

    /* Function to handle checkbox click and disable checkboxes in other dropdown lists.
 -------------------------------------------------------------------------------------------
    This function is used to ensure that when a checkbox in one dropdown list is clicked,
    all checkboxes in other dropdown lists are deselected.
 */
    const booksClosing = (element) => {
        // Select all dropdown lists
        var allDropdownLists = document.querySelectorAll('.level1-li');

        // Convert the element parameter to a string and select the current dropdown list
        var current = String(element);
        var currentDropdown = document.querySelector(current);

        // Find the main checkbox of the current unit
        var bookCheckbox = currentDropdown.querySelector('input[type="checkbox"]');

        // Check if the current book has an active checkbox
        if (bookCheckbox && bookCheckbox.checked == false) {
            // If the current book is open, close all sublists within it
            var currentDropdownSubLists = currentDropdown.querySelectorAll('.level2-li');
            currentDropdownSubLists.forEach(function (dropdownSubList) {
                var subListCheckboxes = dropdownSubList.querySelectorAll('input[type="checkbox"]');
                subListCheckboxes.forEach(function (subListCheckbox) {
                    subListCheckbox.checked = false;
                });
            });

        }


    };

    const renderMenuItems = (items, level = 1, indexStart = 1) => {
        // Iterate over each item in the 'items' array
        return items.map((item, index) => {
            // Create a unique identifier for the current item by combining 'indexStart' and 'index'
            const itemIndex = indexStart + index;
    
            // Check if the current item has children
            const hasChildren = item.children && item.children.length > 0;
    
            // Return the list item (<li>) representing the current menu item
            return (
                <li key={itemIndex} className={`level${level}-li item${itemIndex}`}>
                    {/* Anchor tag for the menu item, applying appropriate classes and handling click events */}
                    <a 
                        href={item.link}  // Link associated with the item
                        className={`level${level}-anchor ${item.status === "disabled" ? "disabled" : ""}`}  // Apply 'disabled' class if the item is disabled
                        onClick={() => {
                            // If the item doesn't have children and isn't disabled, trigger the click handler
                            if (!hasChildren && item.status !== "disabled" ) {
                                onMenuItemClick(item.name);  // Handle the menu item click (passed from parent component)
                            }
                        }}
                    >
                        {/* Render the item's icon with dynamic styles */}
                        <item.icon className={`level${level}-icon ${item.status === "disabled" ? "disabled" : iconColor}`} />
                        
                        {/* Display the item's name */}
                        <span className={`level${level}-span ${item.status === "disabled" ? "disabled" : ""}`}> {item.name}</span>
                        
                        {/* Checkbox for dropdown behavior, handling the collapse/expand logic */}
                        <input 
                            type="checkbox" 
                            className={`level${level}-check`} 
                            onClick={() => booksClosing(`li.level${level}-li.item${itemIndex}`)}  // Trigger collapse behavior
                        />
    
                        {/* If the item has children, show the expand arrow icon */}
                        {item.children && item.children.length > 0 && (
                            <ExpandSVG className={`level${level}-arrow`} />
                        )}
                    </a>
    
                    {/* If the item has children, recursively render its child items */}
                    {item.children && item.children.length > 0 && (
                        <div className={`level${level + 1}`}>
                            <ul className={`level${level + 1}-list`}>
                                {/* Recursive call to render child items */}
                                {renderMenuItems(item.children, level + 1, 1)}
                            </ul>
                        </div>
                    )}
                </li>
            );
        });
    };
    
    

    return (
        <ul className="dropdown-menu">
            {renderMenuItems(Data)}
        </ul>
    );
};

export default DropDownList;

