import React, { useState, useEffect } from "react";
import './modalEditPeopleNoUser.scss'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputAnimated from "../../inputAnimated/inputAnimated";
import Axios from "axios";
import Select from "../../select/select";
import Button from "../../button/button";
import ConfirmModal from "../../confirmModalV2/confirmModal";
const ModalEditPeopleNoUser = ({ modalData, setModalData, refreshTable, setRefreshTable }) => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null)
    const [name, setName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [phoneNumberHome, setPhoneNumberHome] = useState(null)
    const [phoneNumberOffice, setPhoneNumberOffice] = useState(null)
    const [genre, setGenre] = useState(null)
    const [jobPosition, setJobPosition] = useState(null)
    const [subsidiary, setSubsidiary] = useState(null)
    const [message, setMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [modalDataDelete, setModalDataDelete] = useState(null);
    const [isUserDeleted, setIsUserDeleted] = useState(false)

    const [errors, setErrors] = useState({
        name: false,
        middleName: false,
        lastName: false,
        genre: false,
        phoneNumber: false,
        phoneNumberHome: false,
        phoneNumberOffice: false,
        jobPosition: false,
        subsidiary: false,
    });
    const genreList = [
        {
            value: 1,
            label: "Hombre"
        },
        {
            value: 2,
            label: "Mujer"
        },
    ]
    const jobPositionList = [
        {
            value: 1,
            label: "Desarrollo de Software"
        },
        {
            value: 2,
            label: "Desarrollo de Multimedia"
        },
        {
            value: 3,
            label: "Director"
        },
        {
            value: 4,
            label: "Recepcionista"
        },
        {
            value: 5,
            label: "Recursos Humanos"
        },
        {
            value: 6,
            label: "Auxiliar RH"
        },
        {
            value: 7,
            label: "Soporte Tecnico"
        },
        {
            value: 8,
            label: "Profesor"
        },
        {
            value: 9,
            label: "Marketing"
        },
    ]
    const SubsidiaryList = [
        {
            value: 1,
            label: "Plaza Estrella"
        },
        {
            value: 2,
            label: "Plaza Bugambilias"
        },
        {
            value: 3,
            label: "Plaza Aranjuez"
        },
    ]
    useEffect(() => {
        if (modalData !== null) {
            setId(modalData.id);
            setName(modalData.name);
            setMiddleName(modalData.middle_name);
            setLastName(modalData.last_name);
            setEmail(modalData.email);
            setPhoneNumber(modalData.phone_number);
            setPhoneNumberHome(modalData.phone_number_home);
            setPhoneNumberOffice(modalData.phone_number_office);
            setJobPosition(modalData.job_position_id || '');
            setSubsidiary(modalData.subsidiary_id || '');
            setGenre(modalData.genre_id || '');
            setOpen(true);
        }
    }, [modalData]);
    useEffect(() => {
        if (isUserDeleted === true) {
            handleCloseEdit()
            setIsUserDeleted(false)
            setRefreshTable(!refreshTable)
        }
    }, [isUserDeleted])
    const handleKeyDown = (event) => {
        if (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e') {
            event.preventDefault();
        }
    };
    const handleCloseEdit = () => {
        setModalData(null);
        setOpen(false);
        setButtonDisabled(false);
        setMessage('')
        setErrors({
            name: false,
            middleName: false,
            lastName: false,
            genre: false,
            phoneNumber: false,
            phoneNumberHome: false,
            phoneNumberOffice: false,
            jobPosition: false,
            subsidiary: false,
        })
    }

    ///edit API
    const handleEditPerson = () => {
        // Verificar campos vacíos
        const newErrors = {
            name: !name,
            middleName: !middleName,
            lastName: !lastName,
            genre: !genre,
            phoneNumber: !phoneNumber || phoneNumber.length !== 10, // Verificación de 10 dígitos
            phoneNumberHome: phoneNumberHome && phoneNumberHome.length !== 10, // Solo valida si tiene valor
            phoneNumberOffice: phoneNumberOffice && phoneNumberOffice.length !== 10, // Solo valida si tiene valor
            jobPosition: !jobPosition,
            subsidiary: !subsidiary,
        };
        setErrors(newErrors);

        // Verificar si hay algún campo vacío o con error
        if (Object.values(newErrors).some((error) => error)) {
            if (!phoneNumber || phoneNumber.length !== 10) {
                setMessage("El número de teléfono debe tener exactamente 10 dígitos.");
            } else if (phoneNumberHome && phoneNumberHome.length !== 10) {
                setMessage("El número de teléfono de casa debe tener exactamente 10 dígitos.");
            } else if (phoneNumberOffice && phoneNumberOffice.length !== 10) {
                setMessage("El número de teléfono de la oficina debe tener exactamente 10 dígitos.");
            } else {
                setMessage("Todos los campos obligatorios deben ser completados.");
            }

            setTimeout(() => {
                setMessage('');
            }, 2500);
            return;
        }

        Axios.patch(
            process.env.REACT_APP_API_URL + "updatePersonNoUser/" + id,
            {
                name: name,
                middle_name: middleName,
                last_name: lastName,
                phone_number: phoneNumber,
                phone_number_home: phoneNumberHome,
                phone_number_office: phoneNumberOffice,
                fk_genre: genre,
                fk_job_position: jobPosition,
                fk_subsidiary: subsidiary
            },
            { headers: { "x-access-token": localStorage.getItem("token") } }
        ).then((res) => {
            const result = res.data;
            switch (result.status) {
                case 200:
                    setMessage(result.message);
                    setButtonDisabled(true);
                    setTimeout(() => {
                        setRefreshTable(!refreshTable);
                    }, 1000);
                    setTimeout(() => {
                        handleCloseEdit();
                    }, 2500);
                    break;
                case 201:
                    setButtonDisabled(true);
                    setMessage(result.message);
                    setTimeout(() => {
                        setMessage('');
                    }, 2500);
                    setButtonDisabled(false);
                    break;
                case 202:
                    setButtonDisabled(true);
                    setMessage(result.message);
                    setTimeout(() => {
                        setMessage('');
                    }, 2500);
                    setButtonDisabled(false);
                    break;
                case 203:
                    setButtonDisabled(true);
                    setMessage(result.message);
                    setTimeout(() => {
                        setMessage('');
                        window.location.reload();
                    }, 2500);
                    setButtonDisabled(false);
                    break;
                default:
                    break;
            }
        });
    };

    const handleDeletePerson = () => {
        const obj = {
            id,
            email,
            action: "deletePerson",
        }
        setModalDataDelete(obj)
    }
    return (
        <Modal
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box className={'containerEditModal'}>
                <div className="editContainer">
                    <h1>PERFIL</h1>
                    <div className="formContainer">
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={errors.name ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Apellido Paterno"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                            className={errors.middleName ? 'error' : ''}

                        />
                        <InputAnimated
                            maxLength={"25"}
                            type="text"
                            placeholder="Apellido Materno"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className={errors.lastName ? 'error' : ''}

                        />
                        <Select
                            placeholder={"Selecciona un genero"}
                            options={genreList}
                            value={genre}
                            onChange={(e) => setGenre(e.target.value)}
                        />
                        <InputAnimated
                            type="text"
                            placeholder="Email"
                            value={email}
                            disabled
                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumber ? 'error' : ''}
                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono Casa"
                            value={phoneNumberHome}
                            onChange={(e) => setPhoneNumberHome(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumberHome ? 'error' : ''}
                        />
                        <InputAnimated
                            type="number"
                            placeholder="Teléfono Oficina"
                            value={phoneNumberOffice}
                            onChange={(e) => setPhoneNumberOffice(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            className={errors.phoneNumberOffice ? 'error' : ''}
                        />
                        <Select
                            placeholder={"Selecciona un puesto de trabajo"}
                            options={jobPositionList}
                            value={jobPosition}
                            onChange={(e) => setJobPosition(e.target.value)}
                            className={errors.jobPosition ? 'error' : ''}

                        />
                        <Select
                            placeholder={"Selecciona una sucursal"}
                            options={SubsidiaryList}
                            value={subsidiary}
                            onChange={(e) => setSubsidiary(e.target.value)}
                            className={errors.subsidiary ? 'error' : ''}

                        />
                    </div>

                    <div className="bottomForm">

                        <Button disabled={buttonDisabled} text={"Cancelar"} onClick={handleCloseEdit} />
                        <Button disabled={buttonDisabled} text={"Guardar Cambios"} onClick={handleEditPerson} />
                        <Button disabled={buttonDisabled} text={"Eliminar"} onClick={handleDeletePerson} />
                    </div>
                    <div>
                        <span>{message}</span>
                    </div>
                </div>
                <ConfirmModal
                    modalData={modalDataDelete}
                    setModalData={setModalDataDelete}
                    setIsUserDeleted={setIsUserDeleted}
                />
            </Box>
        </Modal>
    )
}
export default ModalEditPeopleNoUser