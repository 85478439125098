import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./changePassword.scss";
import InputPassword from "../../../components/inputPassword/inputPassword";
import Button from "../../../components/button/button";

const ChangePassword = () => {
  const location = useLocation();
  const [id, setId] = useState("");
  const [idUser, setIdUser] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [messageCorrect, setMessageCorrect] = useState("");
  const [status, setStatus] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state === null) {
      navigate(-1);
    } else {
      setId(location.state.id);
      setIdUser(location.state.id_user);
      setEmail(location.state.email);
      setCode(location.state.code);
    }
  }, []);

  const handleChangePassword = (event) => {
    event.preventDefault();
    Axios.patch(process.env.REACT_APP_API_URL + "changeForgottenPassword/", {
      id: id,
      idUser: idUser,
      password: password,
      passwordConfirm: passwordConfirm,
      code: code,
      email: email,
    }).then((response) => {
      if (response.data.status === 201) {
        setMessage(response.data.message);
      } else if (response.data.status === 200) {
        setStatus(response.data.status);
        setMessage("");
        setMessageCorrect(response.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 3500);
      }
    });
  };

  return (
    <>
      <div className="changePasswordContainer">
        <form
          className="changePasswordForm"
          id="changePasswordForm"
          action="#"
          onSubmit={handleChangePassword}
        >
          <h1>Cambia Contraseña</h1>

          <div className="containerChangePasswordInputAndMessage">
            <InputPassword
              className="changePasswordInput"
              type={"password"}
              placeholder="Contraseña Nueva"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <InputPassword
              className="changePasswordConfirmInput"
              type={"password"}
              placeholder="Confirma Contraseña Nueva"
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
            />
            <p>{message}</p>
            <h4>{messageCorrect}</h4>
          </div>
          <Button
            type="submit"
            text={"Enviar"}
            disabled ={status === 200 ? true : false}

          />
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
