
// FUNCTION TO LOAD AND REGISTER ALL THE FONTS
//------------------------------------------------------------------------------------
//IMPORTANT: The files of the fonts has to be already loaded in public folder in format .ttf
export async function loadAndAddFonts(pdf) {
    const calibriFontUrl = '/fonts/calibri.ttf';
    const calibrilightFontUrl = '/fonts/calibril.ttf';
    const calibriboldFontUrl = '/fonts/calibrib.ttf';
    const calibriitalicFontUrl = '/fonts/calibrili.ttf';
    const calibribolditalicFontUrl = '/fonts/calibriz.ttf';

    const centuryGothicFontUrl = '/fonts/GOTHIC.TTF';
    const centuryGothicboldFontUrl = '/fonts/GOTHICB.TTF';
    const centuryGothicitalicFontUrl = '/fonts/GOTHICI.TTF';
    const centuryGothicbolditalicFontUrl = '/fonts/GOTHICBI.TTF';

    const inkFreeFontUrl = '/fonts/Inkfree.ttf';

    const fanKidsFontUrl = '/fonts/FanKids.ttf';

    // Function to charge a font and convert it to a base64 format
    const loadFont = async (url) => {
        const response = await fetch(url);
        const fontArrayBuffer = await response.arrayBuffer();
        const fontBase64 = btoa(
            new Uint8Array(fontArrayBuffer)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return fontBase64;
    };

    //CALIBRI
    const calibriFontBase64 = await loadFont(calibriFontUrl);
    pdf.addFileToVFS('calibri.ttf', calibriFontBase64);
    pdf.addFont('calibri.ttf', 'Calibri', 'normal');

    const calibrilightFontBase64 = await loadFont(calibrilightFontUrl);
    pdf.addFileToVFS('calibril.ttf', calibrilightFontBase64);
    pdf.addFont('calibril.ttf', 'Calibri', 'light');

    const calibriboldFontBase64 = await loadFont(calibriboldFontUrl);
    pdf.addFileToVFS('calibrib.ttf', calibriboldFontBase64);
    pdf.addFont('calibrib.ttf', 'Calibri', 'bold');

    const calibriitalicFontBase64 = await loadFont(calibriitalicFontUrl);
    pdf.addFileToVFS('calibrili.ttf', calibriitalicFontBase64);
    pdf.addFont('calibrili.ttf', 'Calibri', 'italic');

    const calibribolditalicFontBase64 = await loadFont(calibribolditalicFontUrl);
    pdf.addFileToVFS('calibriz.ttf', calibribolditalicFontBase64);
    pdf.addFont('calibriz.ttf', 'Calibri', 'bolditalic');

    //CENTURY GOTHIC
    const centuryGothicFontBase64 = await loadFont(centuryGothicFontUrl);
    pdf.addFileToVFS('GOTHIC.TTF', centuryGothicFontBase64);
    pdf.addFont('GOTHIC.TTF', 'CenturyGothic', 'normal');

    const centuryGothicitalicFontBase64 = await loadFont(centuryGothicitalicFontUrl);
    pdf.addFileToVFS('GOTHICI.TTF', centuryGothicitalicFontBase64);
    pdf.addFont('GOTHICI.TTF', 'CenturyGothic', 'italic');

    const centuryGothicbolditalicFontBase64 = await loadFont(centuryGothicbolditalicFontUrl);
    pdf.addFileToVFS('GOTHICBI.TTF', centuryGothicbolditalicFontBase64);
    pdf.addFont('GOTHICBI.TTF', 'CenturyGothic', 'bolditalic');

    const centuryGothicboldFontBase64 = await loadFont(centuryGothicboldFontUrl);
    pdf.addFileToVFS('GOTHICB.TTF', centuryGothicboldFontBase64);
    pdf.addFont('GOTHICB.TTF', 'CenturyGothic', 'bold');

    //INK FREE
    const inkFreeFontBase64 = await loadFont(inkFreeFontUrl);
    pdf.addFileToVFS('Inkfree.ttf', inkFreeFontBase64);
    pdf.addFont('Inkfree.ttf', 'InkFree', 'normal');

    //FANKIDS
    const fanKidsFontBase64 = await loadFont(fanKidsFontUrl);
    pdf.addFileToVFS('FanKids.ttf', fanKidsFontBase64);
    pdf.addFont('FanKids.ttf', 'FanKids', 'normal');
}
