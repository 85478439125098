import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import InputPassword from "../../inputPassword/inputPassword";
import InputAnimated from "../../inputAnimated/inputAnimated";
import './registerForm.scss'
const RegisterForm = ({ verifyData }) => {
    const [name, setName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [genre, setGenre] = useState("");
    const [password, setPassword] = useState("");
    const [dateBirth, setDateBirth] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [streetState, setStreetState] = useState('');
    const [numberState, setNumberState] = useState('');
    const [colonyState, setColonyState] = useState('');
    const [cityState, setCityState] = useState('');
    const [stateState, setStateState] = useState('');
    const [postalCodeState, setPostalCodeState] = useState('');

    const [message, setMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // Estados de error
    const [errors, setErrors] = useState({
        name: false,
        middleName: false,
        lastName: false,
        genre: false,
        email: false,
        dateBirth: false,
        phoneNumber: false,
        password: false,
        street: false,
        number: false,
        colony: false,
        city: false,
        state: false,
        postalCode: false,
    });
    const navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault();

        // Validar campos vacíos
        const newErrors = {
            name: !name,
            middleName: !middleName,
            lastName: !lastName,
            genre: !genre,
            dateBirth: !dateBirth,
            phoneNumber: !phoneNumber,
            password: !password,
            street: !streetState,
            number: !numberState,
            colony: !colonyState,
            city: !cityState,
            state: !stateState,
            postalCode: !postalCodeState,
        };

        // Validar edad mínima de 8 años
        const isDateValid = (date) => {
            const today = new Date();
            const minAge = 8;
            const minDate = new Date(today.setFullYear(today.getFullYear() - minAge));
            return date <= minDate;
        };

        const dateValid = isDateValid(new Date(dateBirth));

        // Agregar error si la fecha de nacimiento no es válida
        if (!dateValid) {
            newErrors.dateBirth = true; // Marcar error en la fecha de nacimiento
        }

        setErrors(newErrors);

        // Si hay algún campo vacío o la fecha de nacimiento no es válida, no enviar el formulario
        if (Object.values(newErrors).some((error) => error)) {
        } else {
            // Continuar con el registro si todos los campos están completos
            const address = `${cityState}, ${stateState}, ${colonyState}, ${streetState} ${numberState} C.P${postalCodeState}`
            Axios.post(process.env.REACT_APP_API_URL + "register/", {
                email: verifyData.result.email,
                name: name,
                middle_name: middleName,
                last_name: lastName,
                phone_number: phoneNumber,
                fk_genre: genre,
                date_birth: dateBirth,
                address: address,
                password: password,
            }).then((res) => {
                const result = res.data
                switch (result.status) {
                    case 200:
                        setMessage(result.message)
                        setButtonDisabled(true)
                        setTimeout(() => {
                            navigate('/login')
                        }, 2000);
                        break;
                    case 201:
                        setButtonDisabled(true)
                        setMessage(result.message)
                        setTimeout(() => {
                            setMessage('')
                            setButtonDisabled(false)
                        }, 1500);
                        break;
                    default:
                        break;
                }
            });
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e') {
            event.preventDefault();
        }
    };

    // Función para validar la fecha
    const isDateValid = (date) => {
        const today = new Date();
        const minAge = 8;
        const minDate = new Date(today.setFullYear(today.getFullYear() - minAge));
        return date <= minDate;
    };
    const handleDateChange = (e) => {
        const inputDate = new Date(e.target.value);
        setDateBirth(e.target.value);

        // Validar que la edad sea al menos 8 años
        if (isDateValid(inputDate)) {
            setMessage('');
        } else {
            setMessage('La edad minima es de 8 años.');
        }
    };
    return (
        <div className="registerFormContainer">
            <form className="registerForm" onSubmit={handleSubmit}>
                <div className="titleForm">
                    <h1>Registrarse</h1>
                </div>
                <div className="formInput">
                    <div className="leftInput">
                        <InputAnimated
                            maxLength="100"
                            id="registerEmail"
                            type="email"
                            placeholder="Email"
                            value={verifyData.result.email}
                            disabled={true}
                        />
                        <InputAnimated
                            maxLength="25"
                            id="registerName"
                            type="text"
                            placeholder="Nombre"
                            onChange={(e) => setName(e.target.value)}
                            className={errors.name ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="25"
                            id="registerMiddleName"
                            type="text"
                            placeholder="Apellido Paterno"
                            onChange={(e) => setMiddleName(e.target.value)}
                            className={errors.middleName ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="25"
                            id="registerLastName"
                            type="text"
                            placeholder="Apellido Materno"
                            onChange={(e) => setLastName(e.target.value)}
                            className={errors.lastName ? 'error' : ''}
                        />
                        <div className={`input-container-select ${errors.genre ? 'error' : ''}`}>
                            <select
                                id="registerGenre"
                                className="styled-select"
                                value={genre}
                                onChange={(e) => setGenre(e.target.value)}
                                required
                            >
                                <option value="" disabled hidden>Género</option>
                                <option value="1">Hombre</option>
                                <option value="2">Mujer</option>
                            </select>
                            <label className="input-label">Seleccionar género</label>
                        </div>

                        <InputAnimated
                            id="registerDateBirth"
                            type="date"
                            placeholder="Fecha de nacimiento"
                            value={dateBirth}
                            onChange={handleDateChange}
                            className={errors.dateBirth ? 'error' : ''}
                        />
                        <InputAnimated
                            id="registerPhoneNumber"
                            type="number"
                            placeholder="Teléfono"
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className={errors.phoneNumber ? 'error' : ''}
                        />


                        <InputAnimated
                            id="registerPassword"
                            placeholder="Contraseña"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            className={errors.password ? 'error' : ''}
                        />
                    </div>
                    <div className="rightInput">
                        <InputAnimated
                            maxLength="100"
                            id="registerStreet"
                            type="text"
                            placeholder="Calle"
                            onChange={(e) => setStreetState(e.target.value)}
                            className={errors.street ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="10"
                            id="registerNumber"
                            type="text"
                            placeholder="Número"
                            onChange={(e) => setNumberState(e.target.value)}
                            className={errors.number ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="50"
                            id="registerColony"
                            type="text"
                            placeholder="Colonia"
                            onChange={(e) => setColonyState(e.target.value)}
                            className={errors.colony ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="50"
                            id="registerCity"
                            type="text"
                            placeholder="Ciudad"
                            onChange={(e) => setCityState(e.target.value)}
                            className={errors.city ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="50"
                            id="registerState"
                            type="text"
                            placeholder="Estado"
                            onChange={(e) => setStateState(e.target.value)}
                            className={errors.state ? 'error' : ''}
                        />
                        <InputAnimated
                            maxLength="5"
                            id="registerPostalCode"
                            type="number"
                            placeholder="Código Postal"
                            onInput={(e) => {
                                if (e.target.value.length > 5) {
                                    e.target.value = e.target.value.slice(0, 5);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setPostalCodeState(e.target.value)}
                            className={errors.postalCode ? 'error' : ''}
                        />
                    </div>
                </div>
                {message && <span className="message">{message}</span>}
                <div className="buttonForm">
                    <button className={!buttonDisabled ? 'button' : 'button disabledButton'} type="submit" disabled={buttonDisabled}>Registrar</button>
                </div>
                <div className="bottomForm">
                    <span>
                        ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
                    </span>
                </div>
            </form>
        </div>

    )
}
export default RegisterForm