import React, { useState, useEffect } from 'react';
import DwgCanvas from '../../../DrawingCanvas/SignatureCanva'
import InputAnimated from '../../../inputAnimated/inputAnimated'
import './GralData.scss'
import Button from '../../../button/button';
import Select from '../../../select/select';
import PenSVG from '../../../icons/pen';
import SaveSVG from '../../../icons/save';
import PrintSVG from '../../../icons/print';
import MailSVG from '../../../icons/mail';
import ConfirmModal from '../../../ConfirmModalV3/confirmModal';
import Alert from '../../../alert/Alert';
import Axios from 'axios';
import { getStorage, ref, uploadString, getDownloadURL, deleteObject } from "firebase/storage";
import OnlineRegister from '../OnlineRegister'; // Importa tu componente PDFGenerator
import InPersonRegisterPDF from '../InPersonRegisterDoc'; // Importa tu componente PDFGenerator
import PasswordAlert from '../../../passwordAlert/passwordAlert';
import InputAutoComplete from '../../../autocomplete/autocomplete';

const GralData = ({ list, studentSelected, navigate }) => {
    const storage = getStorage();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [guardianRequired, setGuardianRequired] = useState(false)
    const [isStudentSelected, setIsStudentSelected] = useState(false)
    // StudenInfo
    const [studentId, setStudentId] = useState("");
    const [studentPersonId, setStudentPersonId] = useState("");
    const [studentName, setStudentName] = useState("");
    const [studentMiddleName, setStudentMiddleName] = useState("");
    const [studentLastName, setStudentLastName] = useState("");
    const [studentDateBirth, setStudentDateBirth] = useState("");
    const [studentGenre, setStudentGenre] = useState("");
    const [studentSchooling, setStudentSchooling] = useState("");
    const [studentPhoneNumber, setStudentPhoneNumber] = useState("");
    const [studentPhoneNumberHome, setStudentPhoneNumberHome] = useState("");
    const [studentPhoneNumberOffice, setStudentPhoneNumberOffice] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [studentStreet, setStudentStreet] = useState("");
    const [studentExtNumber, setStudentExtNumber] = useState("");
    const [studentIntNumber, setStudentIntNumber] = useState("");
    const [studentColony, setStudentColony] = useState("");
    const [studentCity, setStudentCity] = useState("");
    const [studentState, setStudentState] = useState("");
    const [studentZipCode, setStudentZipCode] = useState("");
    //Guardian Info
    const [guardianId, setGuardianId] = useState("");
    const [guardianName, setGuardianName] = useState("");
    const [guardianMiddleName, setGuardianMiddleName] = useState("");
    const [guardianLastName, setGuardianLastName] = useState("");
    const [guardianEmail, setGuardianEmail] = useState("");
    const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
    const [guardianRelatedType, setGuardianRelatedType] = useState("");
    // Emergency Contact Info
    const [emergencyName, setEmergencyName] = useState("");
    const [emergencyMiddleName, setEmergencyMiddleName] = useState("");
    const [emergencyLastName, setEmergencyLastName] = useState("");
    const [emergencyEmail, setEmergencyEmail] = useState("");
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
    const [emergencyRelatedType, setEmergencyRelatedType] = useState("");
    // Advisor
    const [advisorRegistered, setAdvisorRegistered] = useState("");
    const [advisorEnrolled, setAdvisorEnrolled] = useState("");
    // Survey
    const [howDidYouHear, setHowDidYouHear] = useState("");
    const [reasonForStudying, setReasonForStudying] = useState("");
    // Group Selected
    const [groupSelected, setGroupSelected] = useState(null);
    // Observaciones
    const [observations, setObservations] = useState("");
    //Folio
    const [studentInscriptionFolio, setStudentInscriptionFolio] = useState("");
    //inscription date
    const [studentInscriptionDate, setStudentInscriptionDate] = useState("");
    //signature
    const [studentSignatureURL, setStudentSignatureURL] = useState("")
    const [signature, setSignature] = useState("")


    // MODAL Success Change
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    //ALERT
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([])
    //EMPTY INPUTS
    const [empty, setEmpty] = useState({
        student: {
            studentName: false,
            studentMiddleName: false,
            studentDateBirth: false,
            studentGenre: false,
            studentSchooling: false,
            studentPhoneNumber: false,
            studentEmail: false,
            studentStreet: false,
            studentExtNumber: false,
            studentColony: false,
            studentCity: false,
            studentState: false,
            studentZipCode: false
        },
        guardian: {
            guardianName: false,
            guardianMiddleName: false,
            // Al menos uno de los dos campos debe estar lleno
            guardianPhoneNumberEmail: false,
            guardianRelatedType: false,
        },
        emergency: {
            emergencyName: false,
            emergencyMiddleName: false,
            // Al menos uno de los dos campos debe estar lleno
            emergencyPhoneNumberEmail: false,
            emergencyRelatedType: false,
        },
        survey: {
            howDidYouHear: false,
            reasonForStudying: false,
        },
        advisor: {
            advisorEnrolled: false,
            advisorRegistered: false
        }
    })
    // Define el estado para los handlers del modal
    const [modalHandlers, setModalHandlers] = useState({
        handleAccept: () => { },
        handleCancel: () => { },
        handleAcceptPassword: () => { },
        handleCancelPassword: () => { },
    });
    // ModalConfirm variables
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [modalDetailAction, setModalDetailAction] = useState('')
    const [modalDetailTitle, setModalDetailTitle] = useState('')
    // PasswordModal Confirm
    const [isModalPasswordConfirmOpen, setIsModalPasswordConfirmOpen] = useState(false);
    const [modalPasswordDetailAction, setModalPasswordDetailAction] = useState('')
    const [modalPasswordDetailTitle, setModalPasswordDetailTitle] = useState('')
    //select list variables
    const schoolingList = list.schoolingList
    const genreList = list.genreList
    const relatedList = list.relatedList
    const allTeacherClassesList = list.allTeacherClassesList
    const surveyOptionsList1 = list.surveyOptionsList1
    const surveyOptionsList2 = list.surveyOptionsList2
    const receptionPeopleList = list.receptionPeopleList
    useEffect(() => {
        if (studentSelected) {
            setIsStudentSelected(true)
            // Set Student Info
            setStudentId(studentSelected.student?.id || "");
            setStudentPersonId(studentSelected.student?.personId || "");
            setStudentName(studentSelected.student?.name || "");
            setStudentMiddleName(studentSelected.student?.middle_name || "");
            setStudentLastName(studentSelected.student?.last_name || "");
            // Convert birth date to yyyy-MM-dd format
            const birthDate = studentSelected.student?.date_of_birth
                ? new Date(studentSelected.student.date_of_birth).toISOString().split('T')[0]
                : "";
            setStudentDateBirth(birthDate);
            setStudentGenre(studentSelected.student?.genre?.id || "");
            setStudentSchooling(studentSelected.student?.schooling?.id || "");
            setStudentPhoneNumber(studentSelected.student?.phone_number || "");
            setStudentPhoneNumberHome(studentSelected.student?.home_phone_number || "");
            setStudentPhoneNumberOffice(studentSelected.student?.office_phone_number || "");
            setStudentEmail(studentSelected.student?.email || "");
            setStudentInscriptionFolio(studentSelected.student?.inscription_folio || "");
            const inscDate = studentSelected.student?.inscription_date
                ? new Date(studentSelected.student.inscription_date).toISOString().split('T')[0]
                : "";
            setStudentInscriptionDate(inscDate);
            setStudentSignatureURL(studentSelected.student?.signature || "")
            // Parsing address into street, external number, colony, city, zip code if applicable
            const addressParts = studentSelected.student?.address.split(',') || [];
            setStudentCity(addressParts[0]?.trim() || "");
            setStudentState(addressParts[1]?.trim() || "");
            setStudentColony(addressParts[2]?.trim() || "");
            setStudentStreet(addressParts[3]?.trim() || "");
            setStudentExtNumber(addressParts[4]?.trim() || "");
            if (addressParts.length > 6) {
                setStudentIntNumber(addressParts[5]?.trim() || "");
                setStudentZipCode(addressParts[6]?.replace(/\D/g, '') || "");
            } else {
                setStudentZipCode(addressParts[5]?.replace(/\D/g, '') || "");
            }


            // Guardian Info (If exists)
            if (studentSelected.guardian) {
                setGuardianRequired(true)
                setGuardianId(studentSelected.guardian?.id || "");
                setGuardianName(studentSelected.guardian?.name || "");
                setGuardianMiddleName(studentSelected.guardian?.middle_name || "");
                setGuardianLastName(studentSelected.guardian?.last_name || "");
                setGuardianEmail(studentSelected.guardian?.email || "");
                setGuardianPhoneNumber(studentSelected.guardian?.phone_number || "");
                setGuardianRelatedType(studentSelected.guardian?.related_type?.id || "");
            } else {
                setGuardianRequired(false)
            }

            // Emergency Contact Info
            setEmergencyName(studentSelected.emergency_contact?.name || "");
            setEmergencyMiddleName(studentSelected.emergency_contact?.middle_name || "");
            setEmergencyLastName(studentSelected.emergency_contact?.last_name || "");
            setEmergencyEmail(studentSelected.emergency_contact?.email || "");
            setEmergencyPhoneNumber(studentSelected.emergency_contact?.phone_number || "");
            setEmergencyRelatedType(studentSelected.emergency_contact?.related_type?.id || "");

            // Advisor Info
            setAdvisorRegistered(studentSelected.employee_register?.id || "");
            setAdvisorEnrolled(studentSelected.employee_enrolled?.id || "");

            // Survey Info
            setHowDidYouHear(studentSelected.survey_answers?.question1?.answer_id || "");
            setReasonForStudying(studentSelected.survey_answers?.question2?.answer_id || "");

            // Group Info
            const classslct = allTeacherClassesList.filter((e) => parseInt(e.value) === parseInt(studentSelected.class_info?.id))
            setGroupSelected(classslct[0] || null);

            // Observations (if applicable, you may need a new field for this in your object)
            setObservations(studentSelected.student.observation || ""); // Add this field if needed
        } else {
            setIsStudentSelected(false)
            // Set Student Info
            setStudentName("");
            setStudentMiddleName("");
            setStudentLastName("");
            setStudentDateBirth("");
            setStudentGenre("");
            setStudentSchooling(""); // Suponiendo que tienes una propiedad schooling
            setStudentPhoneNumber("");
            setStudentPhoneNumberHome("");
            setStudentPhoneNumberOffice("");
            setStudentEmail("");
            setStudentInscriptionDate("")
            setStudentInscriptionFolio("")

            // Parsing address into street, external number, colony, city, zip code if applicable
            setStudentStreet("");
            setStudentColony("");
            setStudentExtNumber("")
            setStudentIntNumber("");
            setStudentState("")
            setStudentCity("");
            setStudentZipCode("");

            // Guardian Info (If exists)

            setGuardianName("");
            setGuardianMiddleName("");
            setGuardianLastName("");
            setGuardianEmail("");
            setGuardianPhoneNumber("");
            setGuardianRelatedType("");


            // Emergency Contact Info
            setEmergencyName("");
            setEmergencyMiddleName("");
            setEmergencyLastName("");
            setEmergencyEmail("");
            setEmergencyPhoneNumber("");
            setEmergencyRelatedType("");

            // Advisor Info
            setAdvisorRegistered("");
            setAdvisorEnrolled("");

            // Survey Info
            setHowDidYouHear("");
            setReasonForStudying("");

            // Group Info
            setGroupSelected(null);

            // Observations (if applicable, you may need a new field for this in your object)
            setObservations(""); // Add this field if needed
            setStudentSignatureURL("")
            setSignature("")
        }
    }, [studentSelected]);

    const [dataToPDF, setDataToPDF] = useState({
        registerAdviser: '',
        studentName: '',
        studentMiddleName: '',
        studentLastName: '',
        studentBirthDate: '',
        studentStreet: '',
        studentExtNum: '',
        studentIntNum: '',
        studentNeiborhood: '',
        studentCity: '',
        studentState: '',
        studentCP: '',
        studentPhone1: '',
        studentPhone2: '',
        studentPhone3: '',
        studyLevel: '',
        guardianName: '',
        guardianPhone: '',
        schedule: '',
        teacher: '',
        today: '',
        subsidiary: '',
        signature: '',
        ticketFolio: '',
        isOnline: false,
    });
    const SelectObjectByValue = (array, value) => {
        return array.find(item => item.value === value);
    };

    async function convertImageToBase64(imageUrl) {
        try {
            // Fetch the image from the Firebase Storage URL
            const response = await fetch(imageUrl);

            if (!response.ok) {
                throw new Error(`Failed to fetch image. Status: ${response.status}`);
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Convert the blob to Base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result); // Base64 string
                reader.onerror = () => reject('Error converting image to Base64');
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }


    useEffect(() => {
        if (studentSelected) {
            const advisorObject = SelectObjectByValue(receptionPeopleList, parseInt(advisorRegistered));
            const advisorRName = advisorObject?.label || '';
            let teacherName = ''
            let scheduleHour = ''
            let subsidiary = ''
            let is_online = false
            if (groupSelected) {
                const objectSelectedTClass = groupSelected;
                const fullgroup = objectSelectedTClass.label;
                const separateTeacherName = fullgroup.split(" / ")
                teacherName = separateTeacherName[1]
                scheduleHour = separateTeacherName[0]
                is_online = objectSelectedTClass.detail_class?.is_online
                subsidiary = objectSelectedTClass.detail_class?.subsidiary?.name;
            }
            const prepareDataToPDF = async () => {
                let signatureToPDF = '';
                if (studentSignatureURL) {
                    try {
                        const base64 = await convertImageToBase64(studentSignatureURL);
                        if (base64) {
                            signatureToPDF = base64;
                        } else {
                            console.error("Conversion failed.");
                        }
                    } catch (error) {
                        console.error("Error converting image:", error);
                    }
                } else {
                    if (signature) {
                        signatureToPDF = signature
                    }
                }
                setDataToPDF({
                    registerAdviser: advisorRName,
                    studentName: studentName || '',
                    studentMiddleName: studentMiddleName || '',
                    studentLastName: studentLastName || '',
                    studentBirthDate: studentDateBirth || '',
                    studentStreet: studentStreet || '',
                    studentExtNum: studentExtNumber || '',
                    studentIntNum: studentIntNumber || '',
                    studentNeiborhood: studentColony || '',
                    studentCity: studentCity || '',
                    studentState: studentState || '',
                    studentCP: studentZipCode || '',
                    studentPhone1: studentPhoneNumberHome || '',
                    studentPhone2: studentPhoneNumber || '',
                    studentPhone3: studentPhoneNumberOffice || '',
                    studyLevel: studentSchooling || '',
                    guardianName: `${guardianName} ${guardianMiddleName} ${guardianLastName}`.trim() || '',
                    guardianPhone: guardianPhoneNumber || '',
                    schedule: scheduleHour || '',
                    teacher: teacherName || '',
                    today: studentInscriptionDate || '',
                    subsidiary: subsidiary || '',
                    signature: signatureToPDF, // Aquí usamos el valor procesado
                    ticketFolio: studentInscriptionFolio || '',
                    isOnline: is_online,
                });
            };

            prepareDataToPDF(); // Llamamos a la función asíncrona
        }
    }, [
        advisorRegistered,
        studentName,
        studentMiddleName,
        studentLastName,
        studentDateBirth,
        studentStreet,
        studentExtNumber,
        studentIntNumber,
        studentColony,
        studentCity,
        studentState,
        studentZipCode,
        studentPhoneNumberHome,
        studentPhoneNumber,
        studentPhoneNumberOffice,
        studentSchooling,
        guardianName,
        guardianMiddleName,
        guardianLastName,
        guardianPhoneNumber,
        studentInscriptionDate,
        signature,
        studentInscriptionFolio,
    ]);
    const ConfirmAction = () => {
        return new Promise((resolve) => {
            setIsModalConfirmOpen(true); // Abre el modal

            // Pasas estas funciones al modal para controlar los botones
            const handleAccept = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(true); // Resuelve la promesa con "true" si acepta
            };

            const handleCancel = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(false); // Resuelve la promesa con "false" si cancela
            };

            // Almacenar funciones en el estado para pasar al modal
            setModalHandlers({ handleAccept, handleCancel });
        });
    };
    const ConfirmActionPassword = () => {
        return new Promise((resolve) => {
            setIsModalPasswordConfirmOpen(true); // Abre el modal

            // Pasas estas funciones al modal para controlar los botones
            const handleAcceptPassword = (formValues) => {
                if (formValues.email !== '' && formValues.password !== '') {
                    const responseToConfirmation = handleConfirmationReceptionistInCharge(formValues)
                    responseToConfirmation.then((value) => {
                        let status = value.status
                        if (value.hasOwnProperty("auth")) {
                            status = 202
                        }
                        switch (status) {
                            case 200:
                                setIsModalPasswordConfirmOpen(false); // Cierra el modal
                                resolve({ status: true, message: "Edicion Permitida" }); // Resuelve la promesa con "true" si acepta
                                break;
                            case 201:
                                let id = Math.floor(Math.random() * 100) + 1
                                const alertObj = {
                                    id,
                                    type: 'info',
                                    message: 'Credenciales rechazadas para realizar la edición.'
                                }
                                setArrayAlerts(oldArray => [alertObj, ...oldArray])
                                setIsVisibleAlert(true)

                                break;
                            case 202:
                                resolve({ status: false, message: "Credenciales expiradas" })
                                break;
                            default:
                                break;
                        }

                    })
                } else {
                    let id = Math.floor(Math.random() * 100) + 1
                    const alertObj = {
                        id,
                        type: 'danger',
                        message: 'Ingrese el correo y la contraseña para verificar sus credenciales.'
                    }
                    setArrayAlerts(oldArray => [alertObj, ...oldArray])
                    setIsVisibleAlert(true)
                }
            };

            const handleCancelPassword = () => {
                setIsModalPasswordConfirmOpen(false); // Cierra el modal
                resolve(false); // Resuelve la promesa con "false" si cancela
            };

            // Almacenar funciones en el estado para pasar al modal
            setModalHandlers({ handleAcceptPassword, handleCancelPassword });
        });
    };

    const handleConfirmationReceptionistInCharge = async (form) => {
        try {
            const res = await Axios.post(process.env.REACT_APP_API_URL + "confirmReceptionistInChargePatch/", {
                email: form.email,
                password: form.password,
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
            return res.data
        } catch (error) {
            console.log(error)
        }
    }


    const PatchStudent = async () => {
        const newEmpty = {
            student: {
                studentName: !studentName,
                studentMiddleName: !studentMiddleName,
                studentDateBirth: !studentDateBirth,
                studentGenre: !studentGenre,
                studentSchooling: !studentSchooling,
                studentPhoneNumber: !studentPhoneNumber,
                studentEmail: !studentEmail,
                studentStreet: !studentStreet,
                studentExtNumber: !studentExtNumber,
                studentColony: !studentColony,
                studentCity: !studentCity,
                studentState: !studentState,
                studentZipCode: !studentZipCode
            },
            guardian: guardianRequired && {
                guardianName: !guardianName,
                guardianMiddleName: !guardianMiddleName,
                // Al menos uno de los dos campos debe estar lleno
                guardianPhoneNumberEmail: guardianEmail || guardianPhoneNumber ? false : true,
                guardianRelatedType: !guardianRelatedType,
            },
            emergency: {
                emergencyName: !emergencyName,
                emergencyMiddleName: !emergencyMiddleName,
                // Al menos uno de los dos campos debe estar lleno
                emergencyPhoneNumberEmail: emergencyPhoneNumber || emergencyEmail ? false : true,
                emergencyRelatedType: !emergencyRelatedType,
            },
            survey: {
                howDidYouHear: !howDidYouHear,
                reasonForStudying: !reasonForStudying,
            },
            advisor: {
                advisorEnrolled: !advisorEnrolled,
                advisorRegistered: !advisorRegistered
            }
        }
        // Construimos la dirección sólo si los campos necesarios no están vacíos
        const address = [
            studentCity,
            studentState,
            studentColony,
            studentStreet,
            studentExtNumber,
            studentIntNumber,
            `C.P ${studentZipCode}`
        ].filter(Boolean).join(', ');

        setEmpty(newEmpty);
        // Si hay algún campo vacío o la fecha de nacimiento no es válida, no enviar el formulario
        if (Object.values(newEmpty.student).some(Boolean) ||
            Object.values(newEmpty.guardian).some(Boolean) ||
            Object.values(newEmpty.emergency).some(Boolean) ||
            Object.values(newEmpty.survey).some(Boolean) ||
            Object.values(newEmpty.advisor).some(Boolean)) {
            let id = Math.floor(Math.random() * 100) + 1
            const alertObj = {
                id,
                type: 'danger',
                message: 'Por favor, completa lo campos que se marcaron en rojo.'
            }
            setArrayAlerts(oldArray => [alertObj, ...oldArray])
            setIsVisibleAlert(true)
        } else {
            setModalDetailTitle("Editar Alumno")
            setModalDetailAction("Esta seguro que desea editar la informacion del alumno?")
            setModalPasswordDetailTitle("Confirmacion del encargado")
            setModalPasswordDetailAction("Es necesaria la confirmacion para poder editar")
            const confirm = await ConfirmAction()

            if (confirm) {
                const checkPasswordConfirm = await ConfirmActionPassword()
                if (checkPasswordConfirm) {
                    let groupSlct
                    if (!groupSelected) {
                        groupSlct = ""
                    } else {
                        groupSlct = groupSelected.value
                    }
                    const res = await Axios.patch(process.env.REACT_APP_API_URL + `patchStudentData/${studentId}/${studentPersonId}/${advisorRegistered}/${advisorEnrolled}`,
                        {
                            studentName,
                            studentMiddleName,
                            studentLastName,
                            studentDateBirth,
                            idStudentGenre: studentGenre,
                            studentSchooling,
                            studentPhoneNumber,
                            studentPhoneNumberHome,
                            studentPhoneNumberOffice,
                            studentEmail,
                            studentAddress: address,
                            //Guardian Info
                            guardianName,
                            guardianMiddleName,
                            guardianLastName,
                            guardianPhoneNumber,
                            guardianEmail,
                            guardianRelatedType,
                            // Emergency Contact Info
                            emergencyName,
                            emergencyMiddleName,
                            emergencyLastName,
                            emergencyPhoneNumber,
                            emergencyEmail,
                            emergencyRelatedType,
                            // Survey
                            idHowDidYouHear: howDidYouHear,
                            idReasonForStudying: reasonForStudying,
                            // Group Selected
                            idGroupSelected: groupSlct,
                            // Observaciones
                            observations,
                        },
                        {
                            headers: {
                                "x-access-token": localStorage.getItem("token"),
                            },
                        })
                    return res.data
                } 
            }
        }
    };
    const patchAddSignatureToStudentRegistered = async (id, signature, isGuardian) => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + `patchRegisterSignaturesToStudent/`,
            {
                id: id,
                signatureURL: signature,
                isGuardian: isGuardian
            },
            {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }
    const handleUpdateStudent = async () => {
        // Lógica de registro, luego abrir el modal de éxito
        const updateStudent = PatchStudent();
        updateStudent.then((v) => {
            if (v !== undefined) {
                const status = v.status
                let id = Math.floor(Math.random() * 100) + 1
                const alertObj = {
                    id,
                    message: `${v.message}`
                }
                if (v.auth === false) {
                    alertObj.type = 'info'
                    alertObj.message = 'Ha expirado sus credenciales, inicie sesion nuevamente'
                    setArrayAlerts(oldArray => [alertObj, ...oldArray])
                    setIsVisibleAlert(true)
                    setTimeout(() => {
                        navigate('/login')
                    }, 1500);
                }
                switch (status) {
                    case 200:
                        if (signature !== '') {
                            let isGuardianRequired
                            let personId
                            if (studentSelected.guardian) {
                                isGuardianRequired = true
                                personId = guardianId
                            } else {
                                isGuardianRequired = false
                                personId = studentPersonId
                            }
                            const signatureUrl = saveSignature(`${studentName.toUpperCase()}${studentMiddleName.toUpperCase()}${personId}`, signature)
                            signatureUrl.then((url) => {
                                const addURLToStudentRegistered = patchAddSignatureToStudentRegistered(personId, url, isGuardianRequired)
                            })
                        }
                        alertObj.type = 'success'
                        setIsSuccessOpen(true);
                        setArrayAlerts(oldArray => [alertObj, ...oldArray])
                        setIsVisibleAlert(true)
                        break;
                    case 201:
                        alertObj.type = 'info'
                        setArrayAlerts(oldArray => [alertObj, ...oldArray])
                        setIsVisibleAlert(true)
                        break;

                    default:
                        break;
                }

            }
        })
    }

    function getImageNameFromUrl(url) {
        const decodedUrl = decodeURIComponent(url); // Decodifica la URL
        const regex = /\/([^\/?]+)\.png/;; // Captura el nombre y la extensión .png
        const match = decodedUrl.match(regex);
        return match ? match[1] : null; // Devuelve el nombre con .png si se encuentra, de lo contrario null
    }
    const saveSignature = async (studentId, signatureDataBase64) => {
        const prefix = guardianRequired ? "GRD" : "STD";
        const folder = guardianRequired ? "GUARDIAN" : "STUDENT";
        // Construir el nuevo nombre de archivo en función de si es tutor o estudiante
        const newFileName = `${prefix}${studentId}`;
        let storageRef = ref(storage, `Signatures/STUDENTS/${folder}/${newFileName}.png`);

        try {
            // Eliminar el archivo anterior si studentSignatureURL está definido y es diferente del nuevo
            if (studentSignatureURL) {
                const urlFileName = getImageNameFromUrl(studentSignatureURL)
                if (urlFileName !== newFileName) {
                    const oldStorageRef = ref(storage, `Signatures/STUDENTS/${folder}/${urlFileName}.png`);
                    await deleteObject(oldStorageRef);
                }
            }
            // // Subir la nueva firma en formato base64
            await uploadString(storageRef, signatureDataBase64, 'data_url');
            // // Obtener la URL de descarga para almacenarla en la base de datos
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (error) {
            console.error('Error al actualizar la firma:', error.message);
            return null;
        }
    };

    const generatePDF = async (action) => {
        const { isOnline } = dataToPDF;

        let pdfDoc;

        // Decide el documento correcto basado en isOnline
        if (isOnline) {
            pdfDoc = await OnlineRegister(dataToPDF);
        } else {
            pdfDoc = await InPersonRegisterPDF(dataToPDF);
        }

        if (action === 'print') {
            // Imprimir el PDF
            pdfDoc.autoPrint();
            window.open(pdfDoc.output('bloburl'), '_blank');
        } else if (action === 'email') {
            // Enviar el PDF por correo
            const pdfBlob = pdfDoc.output('blob');
            sendEmailWithPDF(pdfBlob);  // Implementar función de enviar por correo
        }
    };

    const sendEmailWithPDF = (pdfBlob) => {
        // Implementar lógica para enviar el PDF por correo
        console.log('Enviar PDF por correo electrónico...');
    };

    const isDateValid = (date) => {
        const today = new Date();
        const minAge = 18;

        // Restamos 18 años a la fecha actual
        const minDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());

        // Comparamos si la fecha proporcionada es igual o menor a la fecha mínima calculada
        return date <= minDate;
    };
    const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const dateValid = isDateValid(date);

        if (!dateValid) {
            setGuardianRequired(true);
        } else {
            // Limpiamos los datos del guardián si no es necesario
            setGuardianName('');
            setGuardianMiddleName('');
            setGuardianLastName('');
            setGuardianPhoneNumber('');
            setGuardianRelatedType('');
            setGuardianRequired(false);
        }

        setStudentDateBirth(e.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e') {
            event.preventDefault();
        }
    };
    const handleGuardianRequired = () => {
        setGuardianRequired(!guardianRequired)
        if (!guardianRequired) {
            if (studentSelected.guardian) {
                setGuardianId(studentSelected.guardian?.id || "");
                setGuardianName(studentSelected.guardian?.name || "");
                setGuardianMiddleName(studentSelected.guardian?.middle_name || "");
                setGuardianLastName(studentSelected.guardian?.last_name || "");
                setGuardianEmail(studentSelected.guardian?.email || "");
                setGuardianPhoneNumber(studentSelected.guardian?.phone_number || "");
                setGuardianRelatedType(studentSelected.guardian?.related_type?.id || "");
            }
        } else {
            setGuardianId("");
            setGuardianName("");
            setGuardianMiddleName("");
            setGuardianLastName("");
            setGuardianEmail("");
            setGuardianPhoneNumber("");
            setGuardianRelatedType("");
        }
    }
    // Función para construir el nombre completo
    const getFullName = (group) => {
        return `${group.label}`;
    };
    return (
        <>
            <div className="gral-data">
                <section className='gral-data-contain'>
                    {/* Datos personales */}
                    <div className='data-personal section'>
                        <h1 className='title'>Personal</h1>
                        <div className='personal-contain'>
                            <div className='picture-name'>
                                <div className="picture">
                                    <InputAnimated type="image" placeholder="" />
                                </div>
                                <div className='data-name'>
                                    <InputAnimated
                                        type="txt"
                                        placeholder="Nombre"
                                        value={studentName}
                                        onChange={(e) => setStudentName(e.target.value)}
                                        className={empty.student.studentName ? 'empty' : ''}
                                        disabled={!isStudentSelected ? true : false}
                                    />
                                    <InputAnimated
                                        type="txt"
                                        placeholder="Primer Apellido"
                                        value={studentMiddleName}
                                        onChange={(e) => setStudentMiddleName(e.target.value)}
                                        className={empty.student.studentMiddleName ? 'empty' : ''}
                                        disabled={!isStudentSelected ? true : false}

                                    />
                                    <InputAnimated
                                        type="txt"
                                        placeholder="Segundo Apellido"
                                        value={studentLastName}
                                        onChange={(e) => setStudentLastName(e.target.value)}
                                        className={empty.student.studentLastName ? 'empty' : ''}
                                        disabled={!isStudentSelected ? true : false}

                                    />
                                </div>
                            </div>
                            <div className='personal-other'>
                                <InputAnimated
                                    type="date"
                                    placeholder="Fecha de nacimiento"
                                    value={studentDateBirth}
                                    onChange={e => handleDateChange(e)}
                                    className={empty.student.studentDateBirth ? 'empty' : ''}
                                    disabled={!isStudentSelected ? true : false}

                                />
                                <Select
                                    options={genreList}
                                    placeholder="Genero"
                                    value={studentGenre}
                                    onChange={(e) => setStudentGenre(e.target.value)}
                                    className={empty.student.studentGenre ? 'empty main-color' : 'main-color'}
                                    disabled={!isStudentSelected ? true : false}

                                />
                                <Select
                                    options={schoolingList}
                                    placeholder="Escolaridad"
                                    value={studentSchooling}
                                    onChange={(e) => setStudentSchooling(e.target.value)}
                                    className={empty.student.studentSchooling ? 'empty main-color' : 'main-color'}
                                    disabled={!isStudentSelected ? true : false}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Datos de contacto */}
                    <div className='data-contact section'>
                        <h1 className='title'>Contácto</h1>
                        <div className='contact-phone'>
                            <InputAnimated
                                type="number"
                                placeholder="Telefono Movil"
                                value={studentPhoneNumber}
                                onChange={(e) => setStudentPhoneNumber(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                className={empty.student.studentPhoneNumber ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="number"
                                placeholder="Telefono Fijo"
                                value={studentPhoneNumberHome}
                                onChange={(e) => setStudentPhoneNumberHome(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                disabled={!isStudentSelected ? true : false}

                            />
                        </div>
                        <div className='contact-phone'>
                            <InputAnimated
                                type="number"
                                placeholder="Telefono Trabajo"
                                value={studentPhoneNumberOffice}
                                onChange={(e) => setStudentPhoneNumberOffice(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="txt"
                                placeholder="Correo electrónico"
                                value={studentEmail}
                                onChange={(e) => setStudentEmail(e.target.value)}
                                className={empty.student.studentEmail ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                        </div>
                        <div className='street-num'>
                            <InputAnimated
                                type="txt"
                                placeholder="Calle"
                                value={studentStreet}
                                onChange={(e) => setStudentStreet(e.target.value.replace(/[.,]/g, ""))}
                                className={empty.student.studentStreet ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="txt"
                                placeholder="Num ext"
                                value={studentExtNumber}
                                onChange={(e) => setStudentExtNumber(e.target.value.replace(/[.,]/g, ""))}
                                className={empty.student.studentExtNumber ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                className={empty.student.studentIntNumber ? 'empty' : ''}
                                maxLenght={5} value={studentIntNumber}
                                type="txt"
                                placeholder="Num int"
                                onChange={(e) => setStudentIntNumber(e.target.value.replace(/[.,]/g, ""))}
                                disabled={!isStudentSelected ? true : false}

                            />

                        </div>
                        <InputAnimated
                            type="txt"
                            placeholder="Colonia"
                            value={studentColony}
                            onChange={(e) => setStudentColony(e.target.value.replace(/[.,]/g, ""))}
                            className={empty.student.studentColony ? 'empty' : ''}
                            disabled={!isStudentSelected ? true : false}

                        />
                        <InputAnimated
                            type="txt"
                            placeholder="Estado"
                            value={studentState}
                            onChange={(e) => setStudentState(e.target.value.replace(/[.,]/g, ""))}
                            className={empty.student.studentState ? 'empty' : ''}
                            disabled={!isStudentSelected ? true : false}

                        />
                        <div className='cp-city'>
                            <InputAnimated
                                type="txt"
                                placeholder="Ciudad"
                                value={studentCity}
                                onChange={(e) => setStudentCity(e.target.value.replace(/[.,]/g, ""))}
                                className={empty.student.studentCity ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="number"
                                placeholder="C.P."
                                value={studentZipCode}
                                onChange={(e) => setStudentZipCode(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 5) {
                                        e.target.value = e.target.value.slice(0, 5);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                className={empty.student.studentZipCode ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                        </div>
                        <div className='guardianRequired'>
                            <input type="checkbox" checked={guardianRequired} onChange={handleGuardianRequired} />
                            <p>Se requiere tutor</p>
                        </div>
                    </div>
                    {/* Datos del Tutor */}
                    {
                        guardianRequired === true &&
                        <div className='data-guardian-contact section'>
                            <h1 className='title'>Datos del Tutor</h1>
                            <InputAnimated
                                type="txt"
                                placeholder="Nombre"
                                value={guardianName}
                                onChange={(e) => setGuardianName(e.target.value)}
                                className={empty.guardian.guardianName ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="txt"
                                placeholder="Primer apellido"
                                value={guardianMiddleName}
                                onChange={(e) => setGuardianMiddleName(e.target.value)}
                                className={empty.guardian.guardianMiddleName ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                type="txt"
                                placeholder="Segundo apellido"
                                value={guardianLastName}
                                onChange={(e) => setGuardianLastName(e.target.value)}
                                className={empty.guardian.guardianLastName ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}

                            />
                            <InputAnimated
                                className={empty.guardian.guardianPhoneNumberEmail ? 'empty' : ''}
                                maxLenght={45}
                                value={guardianEmail}
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setGuardianEmail(e.target.value)}
                                disabled={!isStudentSelected ? true : false}
                            />
                            <InputAnimated
                                type="number"
                                placeholder="Telefono"
                                value={guardianPhoneNumber}
                                onChange={(e) => setGuardianPhoneNumber(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                className={empty.guardian.guardianPhoneNumberEmail ? 'empty' : ''}
                                disabled={!isStudentSelected ? true : false}
                            />
                            <Select
                                options={relatedList}
                                placeholder="Parentesco"
                                classN='main-color'
                                value={guardianRelatedType}
                                onChange={(e) => setGuardianRelatedType(e.target.value)}
                                className={empty.guardian.guardianRelatedType ? 'empty main-color' : 'main-color'}
                                disabled={!isStudentSelected ? true : false}
                            />
                        </div>
                    }
                    {/* Contacto de Emergencia */}
                    <div className='data-emergency-contact section'>
                        <h1 className='title'>Contácto de emergencia</h1>
                        <InputAnimated
                            type="txt"
                            placeholder="Nombre"
                            value={emergencyName}
                            onChange={(e) => setEmergencyName(e.target.value)}
                            className={empty.emergency.emergencyName ? 'empty' : ''}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <InputAnimated
                            type="txt"
                            placeholder="Primer apellido"
                            value={emergencyMiddleName}
                            onChange={(e) => setEmergencyMiddleName(e.target.value)}
                            className={empty.emergency.emergencyMiddleName ? 'empty' : ''}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <InputAnimated
                            type="txt"
                            placeholder="Segundo apellido"
                            value={emergencyLastName}
                            onChange={(e) => setEmergencyLastName(e.target.value)}
                            className={empty.emergency.emergencyLastName ? 'empty' : ''}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <InputAnimated
                            className={empty.emergency.emergencyPhoneNumberEmail ? 'empty' : ''}
                            maxLenght={60}
                            value={emergencyEmail}
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmergencyEmail(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <InputAnimated
                            className={empty.emergency.emergencyPhoneNumberEmail ? 'empty' : ''}
                            type="number"
                            placeholder="Telefono"
                            value={emergencyPhoneNumber}
                            onChange={(e) => setEmergencyPhoneNumber(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <Select
                            options={relatedList}
                            placeholder="Parentesco"
                            className={empty.emergency.emergencyRelatedType ? 'empty main-color' : 'main-color'}
                            value={emergencyRelatedType}
                            onChange={(e) => setEmergencyRelatedType(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                    </div>

                    {/* Información adicional */}
                    <div className='data-school'>
                        <h1 className='title'>Información adicional</h1>
                        <Select
                            options={receptionPeopleList}
                            placeholder="Asesor que registró"
                            className={empty.advisor.advisorRegistered ? 'empty main-color' : 'main-color'}
                            value={advisorRegistered}
                            onChange={(e) => setAdvisorRegistered(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <Select
                            options={receptionPeopleList}
                            placeholder="Asesor que inscribió"
                            className={empty.advisor.advisorEnrolled ? 'empty main-color' : 'main-color'}
                            value={advisorEnrolled}
                            onChange={(e) => setAdvisorEnrolled(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <Select
                            options={surveyOptionsList1}
                            placeholder="¿Cómo se entero de nosotros?"
                            className={empty.survey.howDidYouHear ? 'empty main-color' : 'main-color'}
                            value={howDidYouHear}
                            onChange={(e) => setHowDidYouHear(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                        <Select
                            options={surveyOptionsList2}
                            placeholder="Motivo para estudiar inglés"
                            className={empty.survey.reasonForStudying ? 'empty main-color' : 'main-color'}
                            value={reasonForStudying}
                            onChange={(e) => setReasonForStudying(e.target.value)}
                            disabled={!isStudentSelected ? true : false}
                        />
                    </div>

                    {/* Observaciones */}
                    <div className='data-notes section'>
                        <h1 className='title'>Inscribir a grupo</h1>
                        <InputAutoComplete
                            options={allTeacherClassesList} // Pasa los objetos completos como opciones 
                            getOptionLabel={(option) => getFullName(option)} // Controla cómo se muestra el texto en las opciones
                            value={groupSelected}
                            onChange={(event, newValue) => setGroupSelected(newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value} // Comparación basada en el 'id'
                            className={'search-box sub-color autoCompleteSelect'}
                            placeholder={"Buscar Grupo..."}
                            disabled={!isStudentSelected ? true : false}

                        />
                        <InputAnimated
                            type="text"
                            placeholder="Folio"
                            value={studentInscriptionFolio}
                            disabled={true}
                        />
                        <InputAnimated
                            type="date"
                            placeholder="Fecha de inscripcion"
                            value={studentInscriptionDate}
                            disabled={true}
                        />
                        <div className='notes'>
                            <textarea
                                type="txt"
                                placeholder="Observaciones"
                                value={observations}
                                onChange={(e) => setObservations(e.target.value)}
                                disabled={!isStudentSelected ? true : false}
                            />
                            <div className='sign'>
                                <Button onClick={() => setIsModalOpen(true)} text="Firma Digital" Icon={PenSVG} disabled={!isStudentSelected ? true : false} />
                                <DwgCanvas isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} setSignature={setSignature} signatureUrl={studentSignatureURL} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='gral-data-footer'>
                    <div className='buttons'>
                        <Button text="Enviar inscripción" Icon={MailSVG} disabled={!isStudentSelected ? true : false} />
                        <Button text="Imprimir inscripción" onClick={() => generatePDF('print')} Icon={PrintSVG} disabled={!isStudentSelected ? true : false} />
                        <Button text="Guardar" Icon={SaveSVG} onClick={handleUpdateStudent} disabled={!isStudentSelected ? true : false} />
                    </div>
                </section>
                <div className="divAlert">
                    <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisibleAlert} setIsVisibleModule={setIsVisibleAlert} />
                </div>
            </div>
            <ConfirmModal
                isOpen={isModalConfirmOpen}
                handleAccept={modalHandlers.handleAccept}
                handleCancel={modalHandlers.handleCancel}
                text={modalDetailAction}
                title={modalDetailTitle}
            />
            <PasswordAlert
                isOpen={isModalPasswordConfirmOpen}
                handleAccept={modalHandlers.handleAcceptPassword}
                handleCancel={modalHandlers.handleCancelPassword}
                text={modalPasswordDetailAction}
                title={modalPasswordDetailTitle}
            />
        </>

    )
}
export default GralData