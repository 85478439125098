import React from "react";
import "./TableCstm.scss";

const TableCtm = ({ columns, data, getRowClass }) => {
    return (
        <div className="table-container">
            <table className="custom-table">
                <thead>
                    <tr className="border">
                        {columns.map((col, index) => (
                            <th key={index} className="caption">{col.header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={`border ${getRowClass ? getRowClass(row) : 'sub-color'}`}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex} className={"border " + col.className}>{row[col.accessor]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableCtm;
