import React, { useState } from 'react';
import Button from '../button/button.jsx';
import Modal from '../modal/modal.jsx';
import './passwordAlert.scss';
import InputAnimated from '../inputAnimated/inputAnimated.jsx';

const PasswordAlert = ({ isOpen, handleCancel, handleAccept,title, text, value}) => {
    const [formValues, setFormValues] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleResetFormValues = () =>{
        setFormValues({ email: '', password: '' });
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCancel} overlayClick={false}>
            <div className="password-alert">
                <div className="password-alert-header">
                    <h2>{title}</h2> 
                </div>
                <div className="password-alert-body">
                    <p>{text}</p>
                </div>
                <div className="password-alert-inputs">
                    <InputAnimated name="email" value={formValues.email} placeholder='Email' onChange={handleInputChange}/>
                    <InputAnimated name="password" value={formValues.password} type='Password' placeholder='Contraseña' onChange={handleInputChange}/>
                </div>
                <div className="password-alert-buttons">
                    <Button onClick={() =>{handleCancel(); handleResetFormValues()}} text='Cancelar'></Button>
                    <Button onClick={() => {handleAccept(formValues); handleResetFormValues()}}text='Continuar'></Button>
                </div>
            </div>
        </Modal>
    );
};

export default PasswordAlert;
