import React, { useEffect, useState } from 'react';
import Tabs from '../../../Tabs/Tabs';
import './StudentInfo.scss';
import GralData from './GralData';
import Academic from './Academic';
// import InputAnimated from '../../../inputAnimated';
import CloseSVG from '../../../icons/close';
import InputAutoComplete from '../../../autocomplete/autocomplete';
import Axios from 'axios'
//navigate
import { useNavigate } from "react-router-dom";
const StudentInfo = ({ onClose, list }) => {
  // Estado para almacenar los alumnos obtenidos desde la API
  const [alumnos, setAlumnos] = useState([]);
  const [selectedAlumno, setSelectedAlumno] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  
  // useEffect para cargar los datos al montar el componente
  useEffect(() => {
    /////////////LIST//////////////////
    const fetchData = async () => {
      setLoading(true); // Comienza la carga
      setError(null); // Resetea los errores al iniciar una nueva solicitud
      try {
        // Ejecutar todas las promesas en paralelo
        const [studentListResponse] = await Promise.all([GetStudentList()]);
  
        if (studentListResponse.auth === false) {
          navigate("/login");
  
          return;
        }
  
        // Asigna los datos de los alumnos a tu estado
        setAlumnos(studentListResponse.result); // Ajusta 'result' según tu respuesta real
      } catch (err) {
        // Si ocurre un error, lo guardamos en el estado
        setError('Error al cargar los alumnos');
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);

  const GetStudentList = async () => {
    const res = await Axios.get(process.env.REACT_APP_API_URL + "getStudentList/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return res.data;
  };

  // Función para construir el nombre completo
  const getFullName = (alumno) => {
    return `${alumno.student.name} ${alumno.student.middle_name} ${alumno.student.last_name}`;
  };
  const tabData = [
    {
      label: 'Datos Generales',
      content: <GralData list={list} studentSelected={selectedAlumno} navigate={navigate}/>,
      disabled: false,
    },
    {
      label: 'Académico',
      content: <Academic />,
      disabled: false,

    },
    {
      label: 'Pagos',
      content: <div>Contenido prueba  de la pestaña 3</div>,
      disabled: true,

    },
  ];

  return (
    <div className='student-info'>
      <section className='info-header'>
        <h1>Informacion del alumno</h1>
        {/* Botón de cerrado */}
        <button onClick={onClose} className="close-button main-color"><CloseSVG className="main-color" /></button>
      </section>
      <section className='info-searching'>
        <input placeholder="Nombre" className='name-box' disabled={true} />
        <InputAutoComplete
          options={alumnos} // Pasa los objetos completos como opciones 
          getOptionLabel={(option) => getFullName(option)} // Controla cómo se muestra el texto en las opciones
          value={selectedAlumno}
          onChange={(event, newValue) => setSelectedAlumno(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id} // Comparación basada en el 'id'
          className={'search-box sub-color'} 
          placeholder={"Buscar Alumno..." }
          />
      </section>
      <section className='info-contain'>
        <Tabs tabs={tabData} />
      </section>

    </div>
  );
};

export default StudentInfo;
