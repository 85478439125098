import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import './autocomplete.scss'

const InputAutoComplete = (props) => {
    return (
        <Autocomplete
            className={props.className}
            options={props.options} // Pasa los objetos completos como opciones
            getOptionLabel={props.getOptionLabel} // Controla cómo se muestra el texto en las opciones
            value={props.value}
            onChange={props.onChange}
            isOptionEqualToValue={props.isOptionEqualToValue} // Comparación basada en el 'id'
            renderInput={(params) => (
                <TextField {...params} label={props.placeholder} variant="outlined" />
            )}
            sx={{ width: 300 }}
            disabled={props.disabled}
        />
    );

}
export default InputAutoComplete;