// textUtils.js
import { colors, fontSize } from './configPDF.js';

// FUNCTION TO GET ALL STYLE FONT COLOR SIZE AND FONTSTYLE
export function getStyle(pdf, style, fontColor = '#000', fontSize = 12, font = 'Calibri') {
    // Aplicar estilo de fuente según el caso
    switch (style) {
        case 'normal':
            pdf.setFont(font, 'light');
            break;
        case 'bold':
            pdf.setFont(font, 'bold');
            break;
        case 'italic':
            pdf.setFont(font, 'italic');
            break;
        case 'boldItalic':
            pdf.setFont(font, 'bolditalic');
            break;
        case 'lightBold':
            pdf.setFont(font, 'normal');
            break;
        default:
            console.warn(`Estilo desconocido: ${style}`);
            pdf.setFont(font, 'normal'); // Fallback en caso de estilo desconocido
            break;
    }

    // Cambiar color del texto
    pdf.setTextColor(fontColor);

    // Cambiar tamaño de la fuente si es válido
    if (fontSize) {
        pdf.setFontSize(fontSize);
    } else {
        pdf.setFontSize(fontSize['paragraph']);
        ;
    }
}

// FUNCTION FOR THE TEXT TO UNDERLINE
export function underlineText(pdf, wordWidth, fontColor, x, y) {
    const color = colors[fontColor] || '#000';
    pdf.setDrawColor(color);
    pdf.line(x, y + 0.5, x + wordWidth, y + 0.5);
}

// FUNCTION FOR THE TEXT TO CENTER
export function centeredText (pdf, maxWidth, text) {
    const textWidth = pdf.getTextWidth(text); 
    const centeredPosition = (maxWidth - textWidth) / 2;  
    return (centeredPosition);
}

// FUNCTION OUTLINED TEXT
export function drawTextWithOutline(pdf, text, x, y, textSize, textColor, outlineColor) {
    // Establecer el tamaño de la fuente
   if(textSize) {pdf.setFontSize(textSize);}
  
    // Establecer el color del contorno y dibujar el texto alrededor del texto principal
    pdf.setTextColor(outlineColor? outlineColor:'#000');
    pdf.text(text, x - 0.5, y);     // Izquierda
    pdf.text(text, x + 0.5, y);     // Derecha
    pdf.text(text, x, y - 0.5);     // Arriba
    pdf.text(text, x, y + 0.5);     // Abajo
    pdf.text(text, x - 0.5, y - 0.5); // Esquina superior izquierda
    pdf.text(text, x + 0.5, y - 0.5); // Esquina superior derecha
    pdf.text(text, x - 0.5, y + 0.5); // Esquina inferior izquierda
    pdf.text(text, x + 0.5, y + 0.5); // Esquina inferior derecha
  
    // Establecer el color del texto principal y dibujar encima del contorno
    if(textColor){
        pdf.setTextColor(textColor);
    }else{
        pdf.setTextColor('#FFF');

    }
    pdf.text(text, x, y);
}

//FUNCTION TO JUSTIFY PARAGRAPHS
export function generateStyledParagraph(pdf, x, y, texts, paragraphWidth, lineBreak, newParagraph, alignment = 'justify', indent = 0) {
    let lines = [];
    let currentLine = [];
    let currentLineWidth = 0;
    let wordWidth = 0;
    let totalWordWidth = 0;
    let wordSpacing = 0;
    let spaceWidth = 0;
    texts.forEach(textObject => {
        // Dividir en palabras el texto 
        const words = textObject.text.split(' ');
        words.forEach(word => {
            wordWidth = 0;
            // Obtener estilo  
            getStyle(pdf, textObject.style, '', fontSize[textObject.fontSize],textObject.fontFamily);
            spaceWidth = pdf.getTextWidth(' ');


            // Calcular el ancho de la palabra con el estilo actual
            wordWidth = pdf.getTextWidth(word);
            totalWordWidth = wordWidth + spaceWidth;

            // Manejar el espaciado y verificación del ancho
            const result = handleWordSpacing(
                lines,
                currentLine,
                wordSpacing,
                word,
                textObject,
                totalWordWidth,
                currentLineWidth,
                paragraphWidth - (lines.length === 0 ? indent : 0) // Restar la sangría solo para la primera línea
            );
            currentLine = result.currentLine;
            currentLineWidth = result.currentLineWidth;
        });
    });

    // Agregar cualquier línea restante al final
    if (currentLine.length > 0) {
        lines.push({ line: currentLine, wordSpacing: alignment === 'justify' ? wordSpacing : 0 });
    }

    // Imprimir todas las líneas
    lines.forEach((line, lineIndex) => {
        let currentX = x;

        // Aplicar sangría solo a la primera línea
        if (lineIndex === 0 && indent > 0) {
            currentX += indent;
        }

        // Ajustar alineación según el tipo
        if (alignment === 'right') {
            currentX = x + (paragraphWidth - line.line.reduce((acc, word) => acc + pdf.getTextWidth(word.text) + pdf.getTextWidth(' '), -pdf.getTextWidth(' '))) - (lineIndex === 0 ? indent : 0);
        } else if (alignment === 'center') {
            const lineWidth = line.line.reduce((acc, word) => acc + pdf.getTextWidth(word.text) + pdf.getTextWidth(' '), -pdf.getTextWidth(' '));
            currentX = x + (paragraphWidth - lineWidth) / 2;
        }

        line.line.forEach((wordObject, index) => {
            // Aplicar el estilo correspondiente
            getStyle(pdf, wordObject.style, wordObject.fontColor, fontSize[wordObject.fontSize], wordObject.fontFamily? wordObject.fontFamily:'Calibri');

            // Calcular el ancho de la palabra para actualizar la posición X
            wordWidth = pdf.getTextWidth(wordObject.text);
            spaceWidth = pdf.getTextWidth(' ') + (alignment === 'justify' ? line.wordSpacing : 0);

            // Dibujar la palabra en la posición actual
            pdf.text(wordObject.text, currentX, y);

            const nextWordObject = line.line[index + 1] ? line.line[index + 1] : ''; // Obtenemos el siguiente objeto palabra

            // Subrayar si está habilitado
            if (wordObject.underline === true) {
                if (nextWordObject.underline === true) {
                    underlineText(pdf, wordWidth + spaceWidth, wordObject.fontColor, currentX, y);
                } else {
                    underlineText(pdf, wordWidth, wordObject.fontColor, currentX, y);
                }
            }

            // Actualizar posición X
            currentX += wordWidth;

            // Añadir el espacio si no es la última palabra
            if (index < line.line.length - 1) {
                currentX += spaceWidth;
            }
        });

        // Mover la posición Y hacia abajo para la siguiente línea
        y += lineBreak;
    });

    y -= lineBreak;
    y += newParagraph;
    return y;
}

//FUNCTION TO GET THE SPACE BETWEEN WORDS
const getWordSpacing = (currentLine, currentLineWidth, paragraphWidth) => {
    //Obtener espacio restante al final de la linea
    let remainingSpace = paragraphWidth - currentLineWidth;

    //Dividir espacio restante entre numero de espacios entre palabras
    let wSpacing = remainingSpace / (currentLine.length - 1);

    return wSpacing; // Espaciado entre letras en puntos
};

//FUNCTION TO ADD WORDS TO LINES ARRAY
function handleWordSpacing(lines, currentLine, wordSpacing, word, textObject, totalWordWidth, currentLineWidth, paragraphWidth) {

    // Verifica si la línea se desborda con la nueva palabra
    if (currentLineWidth + totalWordWidth > paragraphWidth) {
        // Calcula el letter spacing
        wordSpacing = getWordSpacing(currentLine, currentLineWidth, paragraphWidth);

        // Añade la linea actual al arreglo de lineas
        lines.push({ line: currentLine, wordSpacing });

        // Reiniciar la línea con la nueva palabra que no cabe
        currentLine = [{ text: word, style: textObject.style, fontColor: textObject.fontColor, fontSize: textObject.fontSize, underline: textObject.underlined, fontFamily: textObject.fontFamily  }];
        currentLineWidth = totalWordWidth;
    } else {
        // Agregar la palabra a la línea actual

        currentLine.push({ text: word, style: textObject.style, fontColor: textObject.fontColor, fontSize: textObject.fontSize, underline: textObject.underlined, fontFamily: textObject.fontFamily });
        currentLineWidth += totalWordWidth;
    }

    return { currentLine, currentLineWidth };
}

//STYLED LISTS
export function addStyledList(
    pdf,
    items,
    x,
    y,
    maxWidth,
    type = 'bullet', // 'bullet' para viñetas o 'number' para numeración
    bullet = '•', // Símbolo para viñetas
    bulletIndent = 5, // Espaciado entre viñeta/número y texto
    lineSpacing = 5, // Espaciado entre líneas
    newParagraph = 10,
    alignment = 'left', // Alineación: 'left', 'center', 'right', 'justify'
    fontSize = 'paragraph', // Tamaño de la fuente
    fontColor = '#000', // Color de la fuente
    font = 'Calibri', // Fuente
    style = 'normal' // Estilo de fuente: 'normal', 'bold', etc.
) {

    items.forEach((item, index) => {
        // Determinar prefijo (viñeta o número)
        const prefix = type === 'number' ? `${index + 1}.` : bullet;

        // Posición inicial para la viñeta/número
        let currentX = x;

        // Dibujar la viñeta o número
        getStyle(pdf, style, fontColor, fontSize, font);
        pdf.text(prefix, currentX, y);

        // Ajustar posición X para el texto del ítem
        currentX += bulletIndent;

        // Dibujar el texto alineado y dentro de los márgenes
        y = generateStyledParagraph(
            pdf,
            currentX,
            y,
            [{ text: item, style, fontColor, fontSize, fontFamily: font }],
            maxWidth - bulletIndent*2,
            lineSpacing,
            lineSpacing, // Sin espacio adicional entre párrafos
            alignment
        );
    });
    y -= lineSpacing;
    y += newParagraph
    return y; // Retorna la posición Y final
}
