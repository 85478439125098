import Button from '../../../button/button';
import Select from '../../../select/select'
import InputAnimated from '../../../inputAnimated/inputAnimated'
import UserAddSVG from '../../../icons/userAdd'
import './Academic.scss'
import TableCstm from '../../..//TableCstm/TableCstm';
const Academic = () => {
    const options = [
        { value: 'option1', label: 'Opción 1' },
        { value: 'option2', label: 'Opción 2' },
        { value: 'option3', label: 'Opción 3' }
    ];
    const columns = [
        { header: "Fecha", accessor: "date" },
        { header: "Si/No", accessor: "attandance" },
        { header: "Observaciones", accessor: "notes" },
    ];
    
    const data = [
        { date: "20/3/2024", attandance: <InputAnimated type="checkbox"/>, notes: "xxxxxx" },
        { date: "21/3/2024", attandance: <InputAnimated type="checkbox"/>, notes: "xxxxxxxxxx" },
        { date: "22/3/2024", attandance: <InputAnimated type="checkbox"/>, notes: "xx" },
    ];
    // const handleSelectChange = (value) => {
    //     console.log('Selected:', value);
    // };
    return (
        <>
            <div className="academic-data">
                <div className='data-groups section'>
                    <h1 className='title'>Grupos que pertenece</h1>
                    <Select
                        options={options}
                        placeholder="Selecciona una opción"
                        // onChange={handleSelectChange}
                        classN='main-color'
                    />
                    <Button
                        text="Inscribir a grupo"
                        backgroundColor='blue'
                        // disabled="disabled"
                        Icon={UserAddSVG}
                    />
                </div>
                <div className='academic-notes section'>
                    <h1 className='title'>Calificaciones</h1>

                </div>

                <div className='data-attendance section'>
                    <h1 className='title'>Asistencia</h1>
                    <TableCstm columns={columns} data={data} />
                </div>

            </div>
        </>
    )
}
export default Academic