import React, { useState, useEffect, useRef } from 'react';
import DwgCanvas from '../../../DrawingCanvas/SignatureCanva';
import InputAnimated from '../../../inputAnimated/inputAnimated';
import Button from '../../../button/button';
import SuccessRegister from './SuccessRegister';
import PenSVG from '../../../icons/pen';
import SaveSVG from '../../../icons/save';
import CleanSVG from '../../../icons/clean'
import Select from '../../../select/select';
import CloseSVG from '../../../icons/close';
import Axios from 'axios';
import './StudentRegister.scss';
import Alert from '../../../alert/Alert';
import ConfirmModal from '../../../ConfirmModalV3/confirmModal';
//firabase to save signature
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import InputAutoComplete from '../../../autocomplete/autocomplete';

const StudentRegister = ({ onClose, list, usr }) => {
    const storage = getStorage();
    const drawingRef = useRef();
    const userInfo = usr
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [guardianRequired, setGuardianRequired] = useState(false)
    const [formDataSuccess, setFormDataSuccess] = useState({});
    // StudenInfo
    const [studentName, setStudentName] = useState("");
    const [studentMiddleName, setStudentMiddleName] = useState("");
    const [studentLastName, setStudentLastName] = useState("");
    const [studentDateBirth, setStudentDateBirth] = useState("");
    const [studentGenre, setStudentGenre] = useState("");
    const [studentSchooling, setStudentSchooling] = useState("");
    const [studentPhoneNumber, setStudentPhoneNumber] = useState("");
    const [studentPhoneNumberHome, setStudentPhoneNumberHome] = useState("");
    const [studentPhoneNumberOffice, setStudentPhoneNumberOffice] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [studentStreet, setStudentStreet] = useState("");
    const [studentExtNumber, setStudentExtNumber] = useState("");
    const [studentIntNumber, setStudentIntNumber] = useState("");
    const [studentColony, setStudentColony] = useState("");
    const [studentCity, setStudentCity] = useState("");
    const [studentState, setStudentState] = useState("");
    const [studentZipCode, setStudentZipCode] = useState("");
    //Guardian Info
    const [guardianName, setGuardianName] = useState("");
    const [guardianMiddleName, setGuardianMiddleName] = useState("");
    const [guardianLastName, setGuardianLastName] = useState("");
    const [guardianEmail, setGuardianEmail] = useState("");
    const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
    const [guardianRelatedType, setGuardianRelatedType] = useState("");
    // Emergency Contact Info
    const [emergencyName, setEmergencyName] = useState("");
    const [emergencyMiddleName, setEmergencyMiddleName] = useState("");
    const [emergencyLastName, setEmergencyLastName] = useState("");
    const [emergencyEmail, setEmergencyEmail] = useState("");
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
    const [emergencyRelatedType, setEmergencyRelatedType] = useState("");
    // Advisor
    const [advisorRegistered, setAdvisorRegistered] = useState("");
    const [advisorEnrolled, setAdvisorEnrolled] = useState("");
    // Survey
    const [howDidYouHear, setHowDidYouHear] = useState("");
    const [reasonForStudying, setReasonForStudying] = useState("");
    // Group Selected
    const [groupSelected, setGroupSelected] = useState(null);
    // Observaciones
    const [observations, setObservations] = useState("");
    //folio
    const [inscriptionFolio, setInscriptionFolio] = useState("");
    //signature
    const [signature, setSignature] = useState("")
    //Subsidiary
    const [subsidiary, setSubsidiary] = useState(userInfo && userInfo.fk_subsidiary)
    // ModalConfirm variables
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    // Define el estado para los handlers del modal
    const [modalHandlers, setModalHandlers] = useState({
        handleAccept: () => { },
        handleCancel: () => { },
    });
    const [modalDetailAction, setModalDetailAction] = useState('')
    const [modalTitleAction, setModalTitleAction] = useState('')
    const [disableButton, setDisabledButton] = useState(false)

    //select list variables
    const schoolingList = list.schoolingList
    const genreList = list.genreList
    const relatedList = list.relatedList
    const allTeacherClassesList = list.allTeacherClassesList
    const surveyOptionsList1 = list.surveyOptionsList1
    const surveyOptionsList2 = list.surveyOptionsList2
    const receptionPeopleList = list.receptionPeopleList
    const subsidiaryList = list.subsidiaryList
    const [filteredReceptionList, setFilteredReceptionList] = useState([])
    const [filteredTeacherClassesList, setFilteredTeacherClassesList] = useState([])
    ////////EMpty inputs
    const [empty, setEmpty] = useState({
        student: {
            studentName: false,
            studentMiddleName: false,
            studentDateBirth: false,
            studentGenre: false,
            studentSchooling: false,
            studentPhoneNumber: false,
            studentEmail: false,
            studentStreet: false,
            studentExtNumber: false,
            studentColony: false,
            studentCity: false,
            studentState: false,
            studentZipCode: false
        },
        guardian: {
            guardianName: false,
            guardianMiddleName: false,
            // Al menos uno de los dos campos debe estar lleno
            guardianPhoneNumberEmail: false,
            guardianRelatedType: false,
        },
        emergency: {
            emergencyName: false,
            emergencyMiddleName: false,
            // Al menos uno de los dos campos debe estar lleno
            emergencyPhoneNumberEmail: false,
            emergencyRelatedType: false,
        },
        survey: {
            howDidYouHear: false,
            reasonForStudying: false,
        },
        advisor: {
            advisorEnrolled: false,
            advisorRegistered: false
        },
        otherInfo: {
            subsidiary: false,
            inscriptionFolio: false,
            signature: false
        }
    })
    ////Alert
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([])

    const SelectObjectByValue = (array, value) => {
        return array.find(item => item.value === value);
    };

    const ConfirmAction = () => {
        return new Promise((resolve) => {
            setIsModalConfirmOpen(true); // Abre el modal

            // Pasas estas funciones al modal para controlar los botones
            const handleAccept = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(true); // Resuelve la promesa con "true" si acepta
            };

            const handleCancel = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(false); // Resuelve la promesa con "false" si cancela
            };

            // Almacenar funciones en el estado para pasar al modal
            setModalHandlers({ handleAccept, handleCancel });
        });
    };
    const PostRegisterStudent = async () => {
        // Validación de longitud para números de teléfono
        const isValidPhoneNumber = phoneNumber => /^[0-9]{10}$/.test(phoneNumber);
        // Validación de longitud para código postal
        const isValidZipCode = zipCode => /^[0-9]{5}$/.test(zipCode);
        const newEmpty = {
            student: {
                studentName: !studentName,
                studentMiddleName: !studentMiddleName,
                studentDateBirth: !studentDateBirth,
                studentGenre: !studentGenre,
                studentSchooling: !studentSchooling,
                studentPhoneNumber: !studentPhoneNumber || !isValidPhoneNumber(studentPhoneNumber),
                studentEmail: !studentEmail,
                studentStreet: !studentStreet,
                studentExtNumber: !studentExtNumber,
                studentColony: !studentColony,
                studentCity: !studentCity,
                studentState: !studentState,
                studentZipCode: !studentZipCode || !isValidZipCode(studentZipCode)
            },
            guardian: guardianRequired && {
                guardianName: !guardianName,
                guardianMiddleName: !guardianMiddleName,
                // Al menos uno de los dos campos debe estar lleno
                guardianPhoneNumberEmail: guardianEmail || isValidPhoneNumber(guardianPhoneNumber) ? false : true,
                guardianRelatedType: !guardianRelatedType,
            },
            emergency: {
                emergencyName: !emergencyName,
                emergencyMiddleName: !emergencyMiddleName,
                // Al menos uno de los dos campos debe estar lleno
                emergencyPhoneNumberEmail: isValidPhoneNumber(emergencyPhoneNumber) || emergencyEmail ? false : true,
                emergencyRelatedType: !emergencyRelatedType,
            },
            survey: {
                howDidYouHear: !howDidYouHear,
                reasonForStudying: !reasonForStudying,
            },
            advisor: {
                advisorEnrolled: !advisorEnrolled,
                advisorRegistered: !advisorRegistered
            },
            otherInfo: {
                inscriptionFolio: !inscriptionFolio,
                signature: !signature
            }
        }
        // Construimos la dirección sólo si los campos necesarios no están vacíos
        const address = [
            studentCity, studentState,
            studentColony,
            studentStreet,
            studentExtNumber,
            studentIntNumber,
            `C.P ${studentZipCode}`
        ].filter(Boolean).join(', ');

        setEmpty(newEmpty);
        // Si hay algún campo vacío o la fecha de nacimiento no es válida, no enviar el formulario
        if (Object.values(newEmpty.student).some(Boolean) ||
            Object.values(newEmpty.guardian).some(Boolean) ||
            Object.values(newEmpty.emergency).some(Boolean) ||
            Object.values(newEmpty.survey).some(Boolean) ||
            Object.values(newEmpty.advisor).some(Boolean) ||
            Object.values(newEmpty.otherInfo).some(Boolean)
        ) {

            let obj = {
                status: 202,
                message: 'Por favor, completa y/o verifica lo campos que se marcaron en rojo.'
            }
            return obj
        } else {
            setModalTitleAction("Registrar Alumno")
            if (!groupSelected) {
                setModalDetailAction('(*Nota: No se selecciono un grupo para el alumno)')
            } else { setModalDetailAction('') }
            const confirm = await ConfirmAction()

            if (confirm) {
                const inscriptionDate = getTodayDateToInsert()
                let groupSlct
                if (!groupSelected) {
                    groupSlct = ""
                } else {
                    groupSlct = groupSelected.value
                }
                const res = await Axios.post(process.env.REACT_APP_API_URL + `postRegisterStudent/${advisorRegistered}/${advisorEnrolled}`,
                    {
                        studentName,
                        studentMiddleName,
                        studentLastName,
                        studentDateBirth,
                        idStudentGenre: studentGenre,
                        studentSchooling,
                        studentPhoneNumber,
                        studentPhoneNumberHome,
                        studentPhoneNumberOffice,
                        studentEmail,
                        studentAddress: address,
                        //Guardian Info
                        guardianName,
                        guardianMiddleName,
                        guardianLastName,
                        guardianPhoneNumber,
                        guardianEmail,
                        guardianRelatedType,
                        // Emergency Contact Info
                        emergencyName,
                        emergencyMiddleName,
                        emergencyLastName,
                        emergencyPhoneNumber,
                        emergencyEmail,
                        emergencyRelatedType,
                        // Survey
                        idHowDidYouHear: howDidYouHear,
                        idReasonForStudying: reasonForStudying,
                        // Group Selected
                        idGroupSelected: groupSlct,
                        // Observaciones
                        observations,
                        // Se necesita agregar estos dos variables al post
                        inscriptionDate,
                        inscriptionFolio
                    },
                    {
                        headers: {
                            "x-access-token": localStorage.getItem("token"),
                        },
                    })
                return res.data
            } else {
                console.log("cancela")
            }
        }
    };
    const patchAddSignatureToStudentRegistered = async (id, signature) => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + `patchRegisterSignaturesToStudent/`,
            {
                id: id,
                signatureURL: signature,
                isGuardian: guardianRequired
            },
            {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }
    const handleRegisterSuccess = async () => {
        // Lógica de registro, luego abrir el modal de éxito
        const registerStudents = PostRegisterStudent();
        registerStudents.then((v) => {
            console.log(v)
            if (v !== undefined) {
                let status = v.status
                if (v.hasOwnProperty("auth")) {
                    console.log('entra')
                    status = 203
                }
                let id = Math.floor(Math.random() * 100) + 1
                let alertObjResponse = {
                    id,
                    message: `${v.message}`
                }
                switch (status) {
                    case 200:
                        const idPerson = v.result.idToSignature
                        const signatureUrl = saveSignature(`${studentName.toUpperCase()}${studentMiddleName.toUpperCase()}${idPerson}`, signature)
                        signatureUrl.then((url) => {
                            const addURLToStudentRegistered = patchAddSignatureToStudentRegistered(idPerson, url)
                        })
                        alertObjResponse.type = 'success'
                        setIsSuccessOpen(true);
                        setArrayAlerts(oldArray => [alertObjResponse, ...oldArray])
                        setIsVisibleAlert(true)
                        SuccessDataProcess()


                        break;
                    case 201:
                        alertObjResponse.type = 'info'
                        setArrayAlerts(oldArray => [alertObjResponse, ...oldArray])
                        setIsVisibleAlert(true)
                        break;
                    case 202:
                        alertObjResponse.type = 'danger'
                        setArrayAlerts(oldArray => [alertObjResponse, ...oldArray])
                        setIsVisibleAlert(true)
                        break;
                    case 203:
                        let id = Math.floor(Math.random() * 100) + 1
                        const alertObj = {
                            id,
                            type: 'info',
                            message: 'Expiro la sesion, es necesario que vuelva a iniciar sesion.'
                        }
                        setArrayAlerts(oldArray => [alertObj, ...oldArray])
                        setIsVisibleAlert(true)
                        setDisabledButton(true)
                        setTimeout(() => {
                            window.location.reload()
                        }, 1300);
                        break;
                    default:
                        break;
                }

            }
        })
    }
    const getTodayDateToInsert = () => {
        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0');
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
        const año = hoy.getFullYear();
        return `${año}-${mes}-${dia}`;
    };
    const getTodayDate = () => {
        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0');
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
        const año = hoy.getFullYear();
        return `${dia}/${mes}/${año}`;
    };
    const SuccessDataProcess = () => {
        // Ejemplo: Seleccionar el objeto de acuerdo al grupo seleccionado
        const schoolObject = SelectObjectByValue(schoolingList, parseInt(studentSchooling))
        const schoolingLevel = schoolObject.label

        const advisorObject = SelectObjectByValue(receptionPeopleList, parseInt(advisorRegistered))
        const advisorRName = advisorObject.label
        const advisorObjectEnrolled = SelectObjectByValue(receptionPeopleList, parseInt(advisorEnrolled));
        const advisorEName = advisorObjectEnrolled.label

        let teacherName = ''
        let schedule = ''
        let subsidiary = ''
        let isOnline = null
        if (groupSelected) {
            const objectSelectedTClass = SelectObjectByValue(allTeacherClassesList, parseInt(groupSelected.value));
            const fullgroup = objectSelectedTClass.label;
            const separateTeacherName = fullgroup.split(" / ")
            teacherName = separateTeacherName[1]
            schedule = separateTeacherName[0]
            subsidiary = objectSelectedTClass.detail_class.subsidiary.name
            isOnline = objectSelectedTClass.detail_class.is_online
        }
        setFormDataSuccess({
            advisorRegistered: advisorRName.toUpperCase(),
            advisorEnrolled: advisorEName.toUpperCase(),
            studentName: studentName.toUpperCase(),
            studentMiddleName: studentMiddleName.toUpperCase(),
            studentLastName: studentLastName.toUpperCase(),
            studentDateBirth,
            idStudentGenre: studentGenre,
            studentSchooling: schoolingLevel,
            studentPhoneNumber,
            studentPhoneNumberHome,
            studentPhoneNumberOffice,
            studentEmail,
            studentCity: studentCity.toUpperCase(),
            studentState: studentState.toUpperCase(),
            studentColony: studentColony.toUpperCase(),
            studentStreet: studentStreet.toUpperCase(),
            studentExtNumber: studentExtNumber.toUpperCase(),
            studentIntNumber,
            studentZipCode,
            //Guardian Info
            guardian: guardianRequired && {
                guardianName: `${guardianName.toUpperCase()} ${guardianMiddleName.toUpperCase()} ${guardianLastName.toUpperCase()}`,
                guardianPhoneNumber,
                guardianEmail,
            },
            // Emergency Contact Info
            emergency: {
                emergencyName: `${emergencyName.toUpperCase()} ${emergencyMiddleName.toUpperCase()} ${emergencyLastName.toUpperCase()}`,
                emergencyPhoneNumber,
                emergencyEmail,
            },
            // Group Selected
            schedule: schedule,
            teacherName: teacherName.toUpperCase(),
            today: getTodayDate(),
            subsidiary,
            isOnline,
            inscriptionFolio: inscriptionFolio.toUpperCase(),
            signature
        }
        )
    }

    const isDateValid = (date) => {
        const today = new Date();
        const minAge = 18;

        // Restamos 18 años a la fecha actual
        const minDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());

        // Comparamos si la fecha proporcionada es igual o menor a la fecha mínima calculada
        return date <= minDate;
    };
    const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const dateValid = isDateValid(date);

        if (!dateValid) {
            setGuardianRequired(true);
        } else {
            // Limpiamos los datos del guardián si no es necesario
            setGuardianName('');
            setGuardianMiddleName('');
            setGuardianLastName('');
            setGuardianPhoneNumber('');
            setGuardianRelatedType('');
            setGuardianRequired(false);
        }

        setStudentDateBirth(e.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e') {
            event.preventDefault();
        }
    };

    const saveSignature = async (studentId, signatureDataUrl) => {
        // Crear una referencia a la firma en la carpeta "signatures"
        let storageRef;
        if (guardianRequired) {
            storageRef = ref(storage, `Signatures/STUDENTS/GUARDIAN/GRD${studentId}.png`);

        } else {
            storageRef = ref(storage, `Signatures/STUDENTS/STUDENT/STD${studentId}.png`);
        }

        // Subir la firma en formato base64
        await uploadString(storageRef, signatureDataUrl, 'data_url');

        // Obtener la URL de descarga para almacenarla en la base de datos
        const url = await getDownloadURL(storageRef);
        return url
    };

    const handleCleanFields = () => {
        //Set signature se vacia ejecutando una funcion que se encuentra en el componente hijo SignatureCanva(DwgCanvas)
        if (drawingRef.current) {
            drawingRef.current.clearDrawing(); // Llama a la función del hijo
        }
        // Set Student Info
        setStudentName("");
        setStudentMiddleName("");
        setStudentLastName("");
        setStudentDateBirth("");
        setStudentGenre("");
        setStudentSchooling(""); // Suponiendo que tienes una propiedad schooling
        setStudentPhoneNumber("");
        setStudentPhoneNumberHome("");
        setStudentPhoneNumberOffice("");
        setStudentEmail("");
        setInscriptionFolio("")

        // Parsing address into street, external number, colony, city, zip code if applicable
        setStudentStreet("");
        setStudentColony("");
        setStudentExtNumber("")
        setStudentIntNumber("");
        setStudentState("")
        setStudentCity("");
        setStudentZipCode("");

        // Guardian Info (If exists)

        setGuardianName("");
        setGuardianMiddleName("");
        setGuardianLastName("");
        setGuardianEmail("");
        setGuardianPhoneNumber("");
        setGuardianRelatedType("");


        // Emergency Contact Info
        setEmergencyName("");
        setEmergencyMiddleName("");
        setEmergencyLastName("");
        setEmergencyEmail("");
        setEmergencyPhoneNumber("");
        setEmergencyRelatedType("");

        // Advisor Info
        setAdvisorRegistered("");
        setAdvisorEnrolled("");

        // Survey Info
        setHowDidYouHear("");
        setReasonForStudying("");
        setSubsidiary("")
        // Group Info
        setGroupSelected(null);

        // Observations (if applicable, you may need a new field for this in your object)
        setObservations(""); // Add this field if needed
    }
    const handleGuardianRequired = () => {
        setGuardianRequired(!guardianRequired)
        setGuardianName("");
        setGuardianMiddleName("");
        setGuardianLastName("");
        setGuardianEmail("");
        setGuardianPhoneNumber("");
        setGuardianRelatedType("");

    }
    const handleSubsidiaryChange = (event) => {
        setSubsidiary(event.target.value)
        setAdvisorEnrolled("")
        setAdvisorRegistered("")
        setGroupSelected(null)
        // Filtrar empleados según la subsidiaria seleccionada
        const filteredEmployees = receptionPeopleList.filter((e) => e.subsidiary.id === parseInt(event.target.value) || userInfo.employee_id === e.value)
        setFilteredReceptionList(filteredEmployees)
        const filteredTeacherClass = allTeacherClassesList.filter((e) => e.detail_class.subsidiary.id === parseInt(event.target.value))
        setFilteredTeacherClassesList(filteredTeacherClass)


    }
    useEffect(() => {
        if (userInfo) {
            const filteredEmployees = receptionPeopleList && receptionPeopleList.filter((e) => e.subsidiary.id === parseInt(subsidiary) || userInfo.employee_id === e.value)
            setFilteredReceptionList(filteredEmployees)
            const filteredTeacherClass = allTeacherClassesList.filter((e) => e.detail_class.subsidiary.id === parseInt(subsidiary))
            setFilteredTeacherClassesList(filteredTeacherClass)
        }
    }, [])
    // Función para construir el nombre completo
    const getFullName = (group) => {
        return `${group.label}`;
    };

    return (
        <>
            <div className="student-register main-color">
                <section className='register-header'>
                    <h1>Registrar alumno</h1>
                    {/* Botón de cerrado */}
                    <button onClick={onClose} className="close-button main-color"><CloseSVG className="main-color" /></button>
                </section>
                <section className='register-contain'>
                    <div className='data-personal section'>
                        <h1 className='title'>Personal</h1>
                        <div className='personal-contain'>
                            <div className='picture-name'>
                                <div className="picture">
                                    <InputAnimated type="image" placeholder="" />
                                    {/* <img src={signature} alt="" /> */}
                                </div>
                                <div className='data-name'>
                                    <InputAnimated className={empty.student.studentName ? 'empty' : ''} maxLenght={45} value={studentName} type="txt" placeholder="Nombre" onChange={(e) => setStudentName(e.target.value)} />
                                    <InputAnimated className={empty.student.studentMiddleName ? 'empty' : ''} maxLenght={45} value={studentMiddleName} type="txt" placeholder="Primer Apellido" onChange={(e) => setStudentMiddleName(e.target.value)} />
                                    <InputAnimated className={empty.student.studentLastName ? 'empty' : ''} maxLenght={45} value={studentLastName} type="txt" placeholder="Segundo Apellido" onChange={(e) => setStudentLastName(e.target.value)} />
                                </div>
                            </div>
                            <div className='personal-other'>
                                <InputAnimated className={empty.student.studentDateBirth ? 'empty' : ''} value={studentDateBirth} type="date" placeholder="Fecha de nacimiento" onChange={e => handleDateChange(e)} />
                                <Select options={genreList} className={empty.student.studentGenre ? 'empty main-color' : 'main-color'} placeholder="Genero" value={studentGenre} onChange={(e) => setStudentGenre(e.target.value)} />
                                <Select
                                    options={schoolingList}
                                    placeholder="Escolaridad"
                                    className={empty.student.studentSchooling ? 'empty main-color' : 'main-color'}
                                    value={studentSchooling}
                                    onChange={(e) => setStudentSchooling(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='data-contact section'>
                        <h1 className='title'>Contácto</h1>
                        <div className='contact-phone'>
                            <InputAnimated
                                value={studentPhoneNumber}
                                type="number"
                                placeholder="Telefono Movil"
                                onChange={(e) => setStudentPhoneNumber(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                className={empty.student.studentPhoneNumber ? 'empty' : ''}
                            />
                            <InputAnimated
                                value={studentPhoneNumberHome}
                                type="number"
                                placeholder="Telefono Fijo"
                                onChange={(e) => setStudentPhoneNumberHome(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className='contact-phone'>
                            <InputAnimated
                                value={studentPhoneNumberOffice}
                                type="number"
                                placeholder="Telefono Trabajo"
                                onChange={(e) => setStudentPhoneNumberOffice(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            <InputAnimated className={empty.student.studentEmail ? 'empty' : ''} maxLenght={60} value={studentEmail} type="email" placeholder="Correo electrónico" onChange={(e) => setStudentEmail(e.target.value)} />
                        </div>
                        <div className='street-num'>
                            <InputAnimated className={empty.student.studentStreet ? 'empty' : ''} maxLenght={20} value={studentStreet} type="txt" placeholder="Calle" onChange={(e) => setStudentStreet(e.target.value.replace(/[.,]/g, ""))} />
                            <InputAnimated className={empty.student.studentExtNumber ? 'empty' : ''} maxLenght={5} value={studentExtNumber} type="txt" placeholder="Num ext" onChange={(e) => setStudentExtNumber(e.target.value.replace(/[.,]/g, ""))} />
                            <InputAnimated className={empty.student.studentIntNumber ? 'empty' : ''} maxLenght={5} value={studentIntNumber} type="txt" placeholder="Num int" onChange={(e) => setStudentIntNumber(e.target.value.replace(/[.,]/g, ""))} />
                        </div>
                        <InputAnimated className={empty.student.studentColony ? 'empty' : ''} maxLenght={20} value={studentColony} type="txt" placeholder="Colonia" onChange={(e) => setStudentColony(e.target.value.replace(/[.,]/g, ""))} />
                        <InputAnimated className={empty.student.studentState ? 'empty' : ''} maxLenght={20} value={studentState} type="txt" placeholder="Estado" onChange={(e) => setStudentState(e.target.value.replace(/[.,]/g, ""))} />
                        <div className='cp-city'>
                            <InputAnimated className={empty.student.studentCity ? 'empty' : ''} maxLenght={20} value={studentCity} type="txt" placeholder="Ciudad" onChange={(e) => setStudentCity(e.target.value.replace(/[.,]/g, ""))} />
                            <InputAnimated
                                className={empty.student.studentZipCode ? 'empty' : ''}
                                onInput={(e) => {
                                    if (e.target.value.length > 5) {
                                        e.target.value = e.target.value.slice(0, 5);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                value={studentZipCode}
                                type="number"
                                placeholder="C.P."
                                onChange={(e) => setStudentZipCode(e.target.value)} />
                        </div>
                        <div className='guardianRequired'>
                            <input type="checkbox" checked={guardianRequired} onChange={handleGuardianRequired} />
                            <p>Se requiere tutor</p>
                        </div>
                    </div>
                    {/* DATOS DEL TUTOR */}
                    {
                        guardianRequired === true &&
                        <div className='data-guardian-contact section'>
                            <h1 className='title'>Datos del Tutor</h1>
                            <InputAnimated className={empty.guardian.guardianName ? 'empty' : ''} maxLenght={45} value={guardianName} type="txt" placeholder="Nombre" onChange={(e) => setGuardianName(e.target.value)} />
                            <InputAnimated className={empty.guardian.guardianMiddleName ? 'empty' : ''} maxLenght={45} value={guardianMiddleName} type="txt" placeholder="Primer apellido" onChange={(e) => setGuardianMiddleName(e.target.value)} />
                            <InputAnimated className={empty.guardian.guardianLastName ? 'empty' : ''} maxLenght={45} value={guardianLastName} type="txt" placeholder="Segundo apellido" onChange={(e) => setGuardianLastName(e.target.value)} />
                            <InputAnimated className={empty.guardian.guardianPhoneNumberEmail ? 'empty' : ''} maxLenght={45} value={guardianEmail} type="email" placeholder="Email" onChange={(e) => setGuardianEmail(e.target.value)} />
                            <InputAnimated
                                className={empty.guardian.guardianPhoneNumberEmail ? 'empty' : ''}
                                value={guardianPhoneNumber}
                                type="number" placeholder="Telefono"
                                onChange={(e) => setGuardianPhoneNumber(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                        e.target.value = e.target.value.slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            <Select
                                className={empty.guardian.guardianRelatedType ? 'empty main-color' : 'main-color'}
                                options={relatedList}
                                placeholder="Parentesco"
                                value={guardianRelatedType}
                                onChange={(e) => setGuardianRelatedType(e.target.value)}
                            />
                        </div>
                    }
                    {/* DATOS DEL CONTACTO DE EMERGENCIA */}
                    <div className='data-emergency-contact section'>
                        <h1 className='title'>Contácto de emergencia</h1>
                        <InputAnimated className={empty.emergency.emergencyName ? 'empty' : ''} maxLenght={45} value={emergencyName} type="txt" placeholder="Nombre" onChange={(e) => setEmergencyName(e.target.value)} />
                        <InputAnimated className={empty.emergency.emergencyMiddleName ? 'empty' : ''} maxLenght={45} value={emergencyMiddleName} type="txt" placeholder="Primer apellido" onChange={(e) => setEmergencyMiddleName(e.target.value)} />
                        <InputAnimated className={empty.emergency.emergencyLastName ? 'empty' : ''} maxLenght={45} value={emergencyLastName} type="txt" placeholder="Segundo apellido" onChange={(e) => setEmergencyLastName(e.target.value)} />
                        <InputAnimated className={empty.emergency.emergencyPhoneNumberEmail ? 'empty' : ''} maxLenght={60} value={emergencyEmail} type="email" placeholder="Email" onChange={(e) => setEmergencyEmail(e.target.value)} />
                        <InputAnimated
                            className={empty.emergency.emergencyPhoneNumberEmail ? 'empty' : ''}
                            value={emergencyPhoneNumber}
                            type="number"
                            placeholder="Telefono"
                            onChange={(e) => setEmergencyPhoneNumber(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        <Select
                            options={relatedList}
                            placeholder="Parentesco"
                            className={empty.emergency.emergencyRelatedType ? 'empty main-color' : 'main-color'}
                            value={emergencyRelatedType}
                            onChange={(e) => setEmergencyRelatedType(e.target.value)}
                        />
                    </div>
                    <div className='data-school'>
                        <h1 className='title'>Información adicional</h1>
                        <Select
                            options={subsidiaryList}
                            placeholder="Plantel"
                            className={empty.otherInfo.subsidiary ? 'empty main-color' : 'main-color'}
                            value={subsidiary}
                            onChange={(e) => handleSubsidiaryChange(e)}
                        />
                        <Select
                            options={filteredReceptionList}
                            placeholder="Asesor que registró"
                            className={empty.advisor.advisorRegistered ? 'empty main-color' : 'main-color'}
                            value={advisorRegistered}
                            onChange={(e) => setAdvisorRegistered(e.target.value)}
                        />
                        <Select
                            options={filteredReceptionList}
                            placeholder="Asesor que inscribió"
                            className={empty.advisor.advisorEnrolled ? 'empty main-color' : 'main-color'}

                            value={advisorEnrolled}
                            onChange={(e) => setAdvisorEnrolled(e.target.value)}
                        />
                        <Select
                            className={empty.survey.howDidYouHear ? 'empty main-color' : 'main-color'}
                            options={surveyOptionsList1}
                            placeholder="¿Cómo se entero de nosotros?"

                            value={howDidYouHear}
                            onChange={(e) => setHowDidYouHear(e.target.value)}
                        />
                        <Select
                            className={empty.survey.reasonForStudying ? 'empty main-color' : 'main-color'}
                            options={surveyOptionsList2}
                            placeholder="Motivo para estudiar inglés"

                            value={reasonForStudying}
                            onChange={(e) => setReasonForStudying(e.target.value)}
                        />

                    </div>
                    <div className='data-enroll section'>
                        <h1 className='title'>Inscribir a grupo</h1>
                        <InputAutoComplete
                            options={filteredTeacherClassesList} // Pasa los objetos completos como opciones 
                            getOptionLabel={(option) => getFullName(option)} // Controla cómo se muestra el texto en las opciones
                            value={groupSelected}
                            onChange={(event, newValue) => setGroupSelected(newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value} // Comparación basada en el 'id'
                            className={'search-box sub-color autoCompleteSelect'}
                            placeholder={"Buscar Grupo..."}
                        />
                        <InputAnimated
                            className={empty.otherInfo.inscriptionFolio ? 'empty' : ''}
                            value={inscriptionFolio}
                            type="text" placeholder="Folio"
                            onChange={(e) => setInscriptionFolio(e.target.value)}
                            onInput={(e) => {
                                if (e.target.value.length > 20) {
                                    e.target.value = e.target.value.slice(0, 20);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        <div className='notes'>
                            <textarea value={observations} type="txt" placeholder="Observaciones" onChange={(e) => setObservations(e.target.value)}></textarea>
                            <div className='sign'>
                                <Button onClick={() => setIsModalOpen(true)} text="Firma Digital" Icon={PenSVG} backgroundColor={empty.otherInfo.signature ? 'red' : 'blue'} />
                                <DwgCanvas ref={drawingRef} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} setSignature={setSignature} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='register-footer'>
                    <div className='buttons'>
                        <Button text="Limpiar Campos" Icon={CleanSVG} onClick={handleCleanFields} />
                        <Button text="Guardar" Icon={SaveSVG} onClick={handleRegisterSuccess} disabled={disableButton} />
                    </div>
                </section>
                <div className="divAlert">
                    <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisibleAlert} setIsVisibleModule={setIsVisibleAlert} />
                </div>
            </div>
            {/* Success Modal */}
            {isSuccessOpen && <SuccessRegister
                isOpen={isSuccessOpen}
                onClose={() => setIsSuccessOpen(false)} //close modal}
                formData={formDataSuccess}
            />}
            <ConfirmModal
                isOpen={isModalConfirmOpen}
                handleAccept={modalHandlers.handleAccept}
                handleCancel={modalHandlers.handleCancel}
                text={modalDetailAction}
                title={modalTitleAction}
            />


        </>
    );
};

export default StudentRegister;
