import React, { useState, useEffect } from "react";
import "./superAdmin.scss";
import Axios from "axios";
import DataTable from "react-data-table-component";
import { UserContext } from "../../context/userProvider";
import { useNavigate } from "react-router-dom";
import { dataDecrypt } from "../../utils/dataDecrypt";
import ModalEditUser from "../../components/superAdmin/modalEditUser/modalEditUser";
import InputAnimated from "../../components/inputAnimated/inputAnimated";
import ModalEditPeopleNoUser from "../../components/superAdmin/modalEditPeopleNoUser/modalEditPeopleNoUser";

const SuperAdmin = () => {
  const [dataUsr, setDataUsr] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [dataPeopleNoUser, setDataPeopleNoUser] = useState([]);
  const [user, setUser] = React.useContext(UserContext);
  const [search, setSearch] = useState("");
  const [searchPeople, setSearchPeople] = useState("");
  const [filter, setFiltrado] = useState([]);
  const [filterPeople, setFiltradoPeople] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalDataPeopleNoUser, setModalDataPeopleNoUser] = useState(null);

  var sessionStorageArray = [];

  const navigate = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      omit: true,
    },
    {
      name: "IDPERSON",
      selector: (row) => row.fk_person,
      omit: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.name,
    },
    {
      name: "Apellido Paterno",
      selector: (row) => row.middle_name,
    },
    {
      name: "Apellido Materno",
      selector: (row) => row.last_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Telefono",
      selector: (row) => row.phone_number,
    },
    {
      name: "Puesto de Trabajo",
      selector: (row) => row.job_position_name,
    },
    {
      name: "Plantel",
      selector: (row) => row.subsidiary_name,
    },
    {
      name: "Privilegio",
      selector: (row) => row.privilege,
    },
    {
      name: "Genero",
      selector: (row) => row.genre,
    },
    {
      name: "Estatus",
      selector: (row) => row.status,
    },
    {
      name: "Fecha de Creacion",
      selector: (row) => row.creation_date,
    },
    {
      name: "Ultimo inicio de sesion",
      selector: (row) => row.last_time_loggedin,
    },
  ];

  const columnsPeopleNoUser = [
    {
      name: "ID",
      selector: (row) => row.id,
      omit: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.name,
    },
    {
      name: "Apellido Paterno",
      selector: (row) => row.middle_name,
    },
    {
      name: "Apellido Materno",
      selector: (row) => row.last_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Telefono",
      selector: (row) => row.phone_number,
    },
    {
      name: "Genero",
      selector: (row) => row.genre,
    },
    {
      name: "Puesto de Trabajo",
      selector: (row) => row.job_position_name
    },
    {
      name: "Plantel",
      selector: (row) => row.subsidiary_name
    }
  ];

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        if (Object.keys(user).length === 0) {
          var userStorage = localStorage.getItem("user");
          var userJson = dataDecrypt(userStorage);
          setUser(userJson);
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (userJson.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            userJson.idPriv.toString() !==
            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        } else {
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (user.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            user.idPriv.toString() !==
            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Hacer la primera petición (getUsers)
        const usersResponse = await Axios.get(process.env.REACT_APP_API_URL + "getUsers/", {
          headers: { "x-access-token": token },
        });

        // Si la respuesta contiene datos, los actualizamos
        if (usersResponse?.data) {

          setDataUsr(usersResponse.data.result);
          setFiltrado(usersResponse.data.result);
        }

        // Hacer la segunda petición (getPeopleWithNoUser)
        const peopleResponse = await Axios.get(process.env.REACT_APP_API_URL + "getPeopleWithNoUser/", {
          headers: { "x-access-token": token },
        });
        // Si la respuesta contiene datos, los actualizamos
        if (peopleResponse?.data) {
          setDataPeopleNoUser(peopleResponse.data.result);
          setFiltradoPeople(peopleResponse.data.result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [refresh]);


  // Barra de búsqueda
  const onChangeSearch = async (e) => {
    e.persist();
    await setSearch(e.target.value);
    if (e.target.value === "") {
      setFiltrado(dataUsr); // Restablece a la lista completa si el campo de búsqueda está vacío
    } else {
      filterElement(e.target.value); // Pasa el valor de búsqueda a la función
    }
  };

  const filterElement = (searchValue) => {
    const searchLower = searchValue.toLowerCase();
    const searchF = dataUsr.filter((item) => {
      try {
        return (
          (item.name?.toLowerCase().includes(searchLower) || false) ||
          (item.middle_name?.toLowerCase().includes(searchLower) || false) ||
          (item.last_name?.toLowerCase().includes(searchLower) || false) ||
          (item.email?.toLowerCase().includes(searchLower) || false)
        );
      } catch (error) {
        console.error("Error processing item:", item, error);
        return false; // Excluir el elemento que causa el error
      }
    });
    setFiltrado(searchF);
  };

  const handleModalEditUser = (rowData) => {
    if (rowData) {
      setModalData(rowData)
    }
  }
  // Barra de búsqueda
  const onChangeSearchPeople = async (e) => {
    e.persist();
    await setSearchPeople(e.target.value);
    if (e.target.value === "") {
      setFiltradoPeople(dataPeopleNoUser); // Restablece a la lista completa si el campo de búsqueda está vacío
    } else {
      filterElementPeople(e.target.value); // Pasa el valor de búsqueda a la función
    }
  };
  const filterElementPeople = (searchValue) => {
    const searchLower = searchValue.toLowerCase(); // Convertir a minúsculas para hacer la búsqueda insensible a mayúsculas/minúsculas
    const searchF = dataPeopleNoUser.filter((item) => {
      try {
        return (
          (item.name?.toLowerCase().includes(searchLower) || false) ||
          (item.middle_name?.toLowerCase().includes(searchLower) || false) ||
          (item.last_name?.toLowerCase().includes(searchLower) || false) ||
          (item.email?.toLowerCase().includes(searchLower) || false)
        );
      } catch (error) {
        console.error("Error processing item:", item, error);
        return false; // Excluir el elemento que causa el error
      }
    });
    setFiltradoPeople(searchF); // Actualiza el estado con los elementos filtrados
  };

  const handleModalEditPeopleNoUser = (rowData) => {
    if (rowData) {
      setModalDataPeopleNoUser(rowData)
    }
  };
  return (
    <div className="containerSuperAdmin">
      <div className="dataTableUser">
        <div className="searcherContainer">
          <InputAnimated
            type="text"
            placeholder="Buscar"
            className="search"
            value={search}
            onChange={onChangeSearch}
          />
        </div>
        <DataTable
          columns={columns}
          data={filter}
          pagination
          responsive
          highlightOnHover
          pointerOnHover
          striped
          onRowDoubleClicked={(row, event) => {
            handleModalEditUser(row)
          }}
        />
        <ModalEditUser modalData={modalData} setModalData={setModalData} refreshTable={refresh} setRefreshTable={setRefresh} />

      </div>
      <div className="dataTablePerson">
        <div className="searcherContainerPerson">
          <InputAnimated
            type="text"
            placeholder="Buscar"
            className="search"
            value={searchPeople}
            onChange={onChangeSearchPeople}
          />
        </div>
        <DataTable
          columns={columnsPeopleNoUser}
          data={filterPeople}
          pagination
          responsive
          highlightOnHover
          pointerOnHover
          striped
          onRowDoubleClicked={(row, event) => {
            handleModalEditPeopleNoUser(row)
          }}
        />
        <ModalEditPeopleNoUser modalData={modalDataPeopleNoUser} setModalData={setModalDataPeopleNoUser} refreshTable={refresh} setRefreshTable={setRefresh} />
      </div>
    </div>
  );
};

export default SuperAdmin;
